import request from '@/utils/request'

// TODO 收藏夹数量
export function numberOfMyList() {
    return request({
        url: '/app/favorList',
        method: 'get'
    })
}

// TODO 创建收藏夹
export function createList (listForm) {
    return request({
        url: '/app/favorList',
        method: 'post',
        data: listForm
    })
}

// TODO 更新收藏夹
export function editList(listForm) {
    return request({
        url: '/app/favorList',
        method: 'put',
        data: listForm
    })
}

// TODO 删除收藏夹
export function deleteList(id) {
    return request({
        url: `/app/favorList/${id}`,
        method: 'delete'
    })
}

// TODO 分页查询收藏夹
export function listList(page, pageSize, order, desc) {
    return request({
        url: `/app/favorList/${page}/${pageSize}/${order}/${desc}`,
        method: 'get'
    })
}

// TODO 获取收藏夹内容
export function listFavourites(id, page, pageSize, order, desc) {
    return request({
        url: `/app/favourite/${id}/${page}/${pageSize}/${order}/${desc}`,
        method: 'get'
    })
}

// TODO 删除收藏
export function deleteFavourite(id) {
    return request({
        url: `/app/favourite/${id}`,
        method: 'delete'
    })
}
