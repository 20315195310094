import request from '@/utils/request'


// TODO 用户信息
export function getUserInfo(userId) {
    return request({
        url: `/app/visitor/user?userId=${userId}`,
        method: 'get'
    })
}

// TODO 模型信息
export function getModel(userId, page, pageSize, orderFilter) {
    return request({
        url: `/app/visitor/model/${userId}/${page}/${pageSize}/${orderFilter}`,
        method: 'get'
    })
}

// TODO 收藏夹列表
export function getFavorList(userId, page, pageSize) {
    return request({
        url: `/app/visitor/favorList/${userId}/${page}/${pageSize}`,
        method: 'get'
    })
}


// TODO 收藏夹内容
export function getListContent(favorListId, page, pageSize) {
    return request({
        url: `/app/visitor/favourite/${favorListId}/${page}/${pageSize}`,
        method: 'get'
    })
}
