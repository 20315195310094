
const TOKEN_KEY = '3d-mall-token'
const USER_KEY = '3d-mall-userInfo'
export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY)
}

export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token)
}

export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY)
}


export const getUser = () => {
  return localStorage.getItem(USER_KEY)
}

export const setUser = (user) => {
  localStorage.setItem(USER_KEY, user)
}

export const removeUser = () => {
  localStorage.removeItem(USER_KEY)
}
