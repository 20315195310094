<template>
  <div style="display: grid">
    <div style="margin-top: 10px">
      <div>
        <el-radio-group v-model="orderFilter" @change="search">
          <el-radio-button :label="0">最新发布</el-radio-button>
          <el-radio-button :label="1">最多浏览</el-radio-button>
          <el-radio-button :label="2">最多点赞</el-radio-button>
          <el-radio-button :label="3">最多收藏</el-radio-button>
          <el-radio-button :label="4">最多下载</el-radio-button>
        </el-radio-group>
      </div>

      <!--   结果框   -->
      <div style="margin-top: 10px">
        <el-empty v-if="productList.length === 0" description="ta还没有上传过作品哦"></el-empty>
        <div v-else>
          <el-table :data="productList" style="width: 100%" max-height="600">
            <el-table-column fixed label="封面" width="320">
              <template slot-scope="scope">
                <img :src="scope.row.cover" class="image">
              </template>
            </el-table-column>

            <el-table-column label="商品详情" width="300" show-overflow-tooltip>
              <template slot-scope="scope">
                <div style="padding: 10px; width: 200px;">
                  <h3>{{ scope.row.title }}</h3>
                  <p style="font-size: 12px; color: silver">{{ scope.row.description }}</p>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="发布时间" prop="createTime" width="300" show-overflow-tooltip>
            </el-table-column>

            <el-table-column label="指标数据" width="135" show-overflow-tooltip>
              <template slot-scope="scope">
                <div style="display: grid; justify-content: flex-start; align-items: center;">
                  <div><i :class="{ 'action-icon': true }" class="el-icon-view"/> 浏览 {{ scope.row.views }}</div>
                  <div><i :class="{ 'action-icon': true }" class="el-icon-magic-stick"/> 点赞 {{ scope.row.likes }}</div>
                  <div><i :class="{ 'action-icon': true }" class="el-icon-star-off"/> 收藏 {{ scope.row.collect }}</div>
                  <div><i :class="{ 'action-icon': true }" class="el-icon-download"/> 下载 {{ scope.row.download }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="120">
              <template slot-scope="scope">
                <el-link icon="el-icon-link" :underline="false" @click.native="openDetail(scope.row.id, scope.row.title)">详情页</el-link>
              </template>
            </el-table-column>
          </el-table>

          <div style="display: flex; justify-content: right; margin: 20px">
            <el-button-group>
              <el-button type="primary" icon="el-icon-arrow-left" :disabled="page === 1" @click="prePage">上一页</el-button>
              <el-button type="primary" :disabled="nextDisable" @click="nextPage">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
            </el-button-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getModel} from "@/api/visit";

export default {
  name: 'MasterpieceSet',
  components: {
  },
  props: {
    userId: {
      required: true
    }
  },
  data () {
    return {
      productList: [],
      orderFilter: 0,
      page: 1,
      pageSize: 6,
      nextDisable: false
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    // TODO 按照当前参数搜索
    async search () {
      // 搜索
      const promise = getModel(this.userId, this.page, this.pageSize, this.orderFilter)
      await promise.then((res) => {
        if (res.code !== 200) return
        if (res.data.length !== 0) this.productList = res.data
        else {
          if (this.page === 1) this.productList = res.data
          else this.page--
        }
        this.nextDisable = res.data.length < this.pageSize
      })
    },

    // TODO 下一页
    nextPage() {
      this.page++
      this.search()
    },

    // TODO 上一页
    prePage() {
      if (this.page > 1) {
        this.page--
        this.search()
        return
      }
      this.page = 1
    },
    openDetail(id, title) {
      let routeData = this.$router.resolve({
        path: `/detail?id=${id}&title=${title}`
      })
      window.open(routeData.href, '_blank')
    }
    //
  }
}
</script>

<style scoped>

:deep(.search) {
  height: 30px;
  width: 720px;
  border-radius: 10px;
}

.image {
  height: 160px;
  width: auto;
  display: block;
}
:deep(.el-row) {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.action-icon {
  font-size: 16px;
}
</style>
