import request from '@/utils/request'


// TODO 拉取购物车列表
export function getCart() {
    return request({
        url: '/app/cart',
        method: 'get'
    })
}

// TODO 添加 至 购物车
export function append(modelId) {
    return request({
        url: `/app/cart/${modelId}`,
        method: 'post'
    })
}

// TODO 移除 from 购物车
export function remove(modelId) {
    return request({
        url: '/app/cart',
        method: 'delete',
        data: {
            ids: modelId
        }
    })
}

