<template>
  <div id="line" style="width: 600px; height: 600px"></div>
</template>

<script>
import * as echarts from 'echarts'
import purple from '@/assets/theme/purple-passion.json'
export default {
  name: 'LineAnalysis',
  data() {
    return {
      chart: null,
      option: {
        title: { text: '近7天数据分析', textStyle: { fontSize: 15 } },
        tooltip: { trigger: 'axis' },
        legend: { data: ['None'], orient: 'horizontal', left: 'center', top: '5%' },
        grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
        toolbox: { feature: { saveAsImage: {} } },
        xAxis: { type: 'category', boundaryGap: false, data: [] },
        yAxis: { type: 'value' },
        series: [{ id: null, data: [0, 1, 2, 3, 4, 5, 6], name: 'None', type: 'line' }]
      }
    }
  },
  mounted() {

  },
  methods: {
    reDraw(legend, data) {
      this.option.legend.data = legend
      this.option.series = data
      this.chart.setOption(this.option, { notMerge: true })
    },
    initLine(width, height) {
      echarts.registerTheme('purple-passion', purple)
      const chartDom = document.getElementById('line')
      chartDom.style.width = width + 'px'
      chartDom.style.height = height + 'px'
      this.chart = echarts.init(chartDom)

      let xAxis = []
      let date = new Date()
      for (let i = 0; i < 7; i++) {
        // const year = String(date.getFullYear())
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        // const items = year + "-" + month + "-" + day
        const items = month + "-" + day;
        xAxis.unshift(items)
        date = new Date(date.setDate(date.getDate() - 1))
      }
      this.option.xAxis.data = xAxis
      this.chart.setOption(this.option, { notMerge: true })
    }
  }
}
</script>

<style scoped>

</style>
