<template>
  <div>
    <!--  模型展示  -->
    <div id="webgl"></div>
    <!--  进度条  -->
    <el-dialog title="模型加载中" :visible.sync="dialogVisible" width="30%">
      <el-progress :text-inside="true" :stroke-width="26" :percentage="percent"></el-progress>
    </el-dialog>
  </div>
</template>

<script>
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

export default {
  name: 'ThreeDimensionBoardExhibit',
  props: {
    width: { default: 1080, type: Number },
    height: { default: 420, type: Number }
  },
  data() {
    return {
      percent: 0,
      dialogVisible: false,
      // 3D 场景对象Scene
      scene: null,
      // 透视投影相机
      camera: null,
      // 画布
      canvas: null,
      // 渲染器对象
      renderer: null,
      // 控制器 控制旋转放大
      controls: null,
      axesHelper: null,
      textureLoader: null,
      loader: null,
      mixer: null,
      loadingManager: null,
      modelObject: null
    }
  },
  mounted() {
  },
  methods: {
    initAll(width, height) {
      // 单独 - 不涉及其它组件
      this.initScene()
      // 单独 - 不涉及其它组件
      this.initCamera(width, height)
      // 涉及scene和camera
      this.initRenderer(width, height)
      // 涉及scene
      this.initLight()
      // 涉及camera和renderer
      this.initControl()
      // 单独 - 不涉及其它组件
      this.initLoadingManager()

      // 加入场景
      document.getElementById('webgl').appendChild(this.renderer.domElement)
    },

    // TODO 初始化 Camera
    initCamera(width, height) {
      // 透视投影相机的四个参数fov, aspect, near, far构成一个四棱台3D空间，
      // 被称为视锥体，只有视锥体之内的物体，才会渲染出来，视锥体范围之外的物体不会显示在Canvas画布上。
      this.camera = new THREE.PerspectiveCamera(10, width / height, 1, 3000)
      // 透视投影相机位置置于 200, 200, 200
      this.camera.position.set(200, 200, 200)
      // 看向坐标原点
      this.camera.lookAt(0, 0, 0)
    },

    // TODO 初始化 Renderer
    initRenderer(width, height) {
      // 初始化画布
      this.renderer = new THREE.WebGLRenderer()
      // 设置画布尺寸
      this.renderer.setSize(width, height)
      // 渲染
      this.renderer.render(this.scene, this.camera)

      // this.renderer.outputEncoding = THREE.sRGBEncoding
      this.renderer.outputColorSpace = THREE.SRGBColorSpace
    },

    // TODO 初始化 Control
    initControl() {
      // 初始化鼠标控制事件 - 滚轮控制缩放、鼠标单击拖动调整方向
      this.controls = new OrbitControls(this.camera, this.renderer.domElement)
      this.controls.enableDamping = true
      this.controls.enableZoom = true
      this.controls.screenSpacePanning = false
      this.controls.minDistance = 1
      this.controls.maxDistance = 200
      // this.controls.maxPolarAngle = Math.PI * 2
    },

    // TODO 初始化Scene
    initScene () {
      this.scene = new THREE.Scene()
      // 背景颜色
      this.scene.background = new THREE.Color(0xbfe3dd)
    },

    // TODO 初始化灯光
    initLight() {
      // 太阳光/平行光
      let sunLight = this.getSunLight(0, 500, 300)
      let sunLight1 = this.getSunLight(-500, 0, 300)
      let sunLight2 = this.getSunLight(500, 0, 300)
      let sunLight3 = this.getSunLight(0, -500, 300)
      this.scene.add(sunLight)
      this.scene.add(sunLight1)
      this.scene.add(sunLight2)
      this.scene.add(sunLight3)

      // 环境光
      this.ambientLight = new THREE.AmbientLight(0xffffff, 1)
      this.scene.add(this.ambientLight)

      // 点光源
      let pointLight = new THREE.PointLight(0xffffff, 1, 1)
      pointLight.position.set(100, 100, 100)
      this.scene.add(pointLight)

      // 区域光
      // 或者添加区域光源，用于照亮有纹理的平面等
      const areaLight = new THREE.RectAreaLight(0xffffff, 1)
      this.scene.add(areaLight)

      // 半球光
      const hemisphereLight0 = new THREE.HemisphereLight(0xffffff, 0xbfe3dd, 0.5)
      hemisphereLight0.position.set(300, 300, 300)
      this.scene.add(hemisphereLight0)
      const hemisphereLight1 = new THREE.HemisphereLight(0xffffff, 0xbfe3dd, 0.5)
      hemisphereLight1.position.set(-300, -300, 300)
      this.scene.add(hemisphereLight1)

    },

    // TODO 初始化地板
    initFloor(y) {
      const planeGeometry = new THREE.PlaneGeometry(100, 60)
      // 给地面物体上色
      const planeMaterial = new THREE.MeshStandardMaterial({color: 0xcccccc})
      // 创建地面
      const plane = new THREE.Mesh(planeGeometry, planeMaterial)
      plane.material.opacity = 0.6;
      plane.material.transparent = true
      plane.rotation.x = -0.5 * Math.PI
      plane.position.x = 0
      plane.position.y = y
      plane.position.z = 0
      plane.castShadow = true
      // 接收阴影
      plane.receiveShadow = true
      this.scene.add(plane)
    },

    // TODO 动画循环
    animate () {
      if (this.mixer) {
        this.mixer.update(0.01666666666)
      }
      requestAnimationFrame(this.animate)
      this.renderer.render(this.scene, this.camera)
      this.controls.update()
    },

    // TODO 加载模型
    loadModel(url) {
      // TODO 初始化进度条
      this.percent = 0
      this.dialogVisible = true

      // TODO 初始化加载器
      this.initLoader(url)
      // 加载模型
      this.loader.load(url, (model) => {
        let object
        if (url.endsWith(".gltf")) {
          object = model.scene
        }
        else object = model
        this.modelObject = object
        // 这个object是模型的根节点，可以是一个Group或其他的Object3D实例
        object.position.set(0, 0, 0)

        const box = new THREE.Box3().setFromObject(object);
        const x = (box.max.x - box.min.x)
        const y = (box.max.y - box.min.y)
        const z = (box.max.z - box.min.z)
        const maxDim = Math.max(x, y, z)
        const scale = 25 / maxDim
        object.scale.set(scale, scale, scale)
        // 添加地面
        // this.initFloor(box.min.z * 4)
        // this.axesHelper = new THREE.AxesHelper(scale * 5)
        // this.scene.add(this.axesHelper)
        //遍历模型字节点，获取相关参数设置
        object.traverse(function(child) {
          if (child.isMesh) {
            // //模型阴影
            child.castShadow = true
            child.material.shininess = 1
            child.receiveShadow = true
            child.frustumCulled = false
            // child.material.transparent = true // 材质允许透明 如果有玻璃材质时开启
            // child.material.opacity = 1 // 材质默认透明度

            // 模型自发光
            // child.material.emissive = child.material.color
            child.material.emissiveMap = child.material.map
          }
        })
        // 将模型添加到场景中
        this.scene.add(object)

        // this.loadAnimation()

        this.animate()
      })
    },

    // TODO 初始化模型加载器
    initLoader(url) {
      if (url === null || url === '') {
        this.$message.error('模型格式错误')
        return
      }
      // 加载器
      if (url.endsWith(".obj")) {
        this.loader = new OBJLoader(this.loadingManager)
      }
      else if (url.endsWith(".fbx")) this.loader = new FBXLoader(this.loadingManager)
      else if (url.endsWith(".gltf")) this.loader = new GLTFLoader(this.loadingManager)
      else {
        this.$message.error('模型格式不支持')
      }
    },

    // TODO 初始化加载管理器
    initLoadingManager() {
      const that = this
      // 创建一个LoadingManager实例
      this.loadingManager = new THREE.LoadingManager()
      // 当所有资源加载完成时的回调函数
      this.loadingManager.onLoad = function() {
        that.$message.success('模型加载完毕')
        that.dialogVisible = false
        that.percent = 0
        // that.loadAnimation()
      }
      // 当特定资源加载完成时的回调函数
      this.loadingManager.onProgress = function(item, loaded, total) {
        // console.log(item)
        // console.log('资源加载进度: ' + loaded + ' / ' + total)
        that.percent = ((Number) ((loaded / total).toFixed(2))) * 100
      }
      // 错误时
      this.loadingManager.onError = function (url, error) {
        that.$message.error(`文件${url}加载失败`)
      }
    },

    loadAnimation () {
      const animations = this.modelObject.animations
      console.log(this.modelObject)
      console.log(this.modelObject.animations)
      if (animations.length !== 0) {
        this.mixer = new THREE.AnimationMixer(this.modelObject.scene)
        console.log(animations[0])
        console.log(this.mixer)
        const action = this.mixer.clipAction(animations[0])
        action.play()
      }
    },

    getSunLight (x, y, z) {
      let sunLight = new THREE.DirectionalLight(0xffffff)
      sunLight.visible = true
      sunLight.castShadow = true
      sunLight.intensity = 2 //光线的密度，默认为1。 光照越强，物体表面就更明亮
      sunLight.shadow.camera.near = -1000 //产生阴影的最近距离
      sunLight.shadow.camera.far = 1000 //产生阴影的最远距离
      sunLight.shadow.camera.left = -1000 //产生阴影距离位置的最左边位置
      sunLight.shadow.camera.right = 1000 //最右边
      sunLight.shadow.camera.top = 1000 //最上边
      sunLight.shadow.camera.bottom = -1000 //最下面
      sunLight.shadow.bias = -0.01 //用于解决阴影水波纹条纹阴影的问题
      sunLight.shadow.mapSize.set(4096, 4096) //阴影清晰度
      sunLight.position.set(x, y, z)

      return sunLight
    }

  }
}
</script>

<style scoped>

</style>
