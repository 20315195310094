import request from '@/utils/request'

export function tempFile (formData) {
    return request({
        url: '/app/tempFile',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: formData
    })
}



// TODO 查看上传情况
export function checkUpload(md5) {
    return request({
        url: `/app/check-upload/?identification=${md5}`,
        method: 'get'
    })
}

// TODO 断点上传
export function breakPointUpload(md5, index, formData) {
    return request({
        url: `/app/break-point/?identification=${md5}&index=${index}`,
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: formData
    })
}


// TODO 请求合并文件
export function merge(param, isZip, isTemp, folder) {
    let url = `/app/merge?zwf=1`
    if (isZip) url += `&isZip=${isZip}`
    if (isTemp) url += `&isTemp=${isTemp}`
    if (folder !== null) url += `&folder=${folder}`
    return request({
        url: url,
        method: 'post',
        data: param
    })
}

// TODO 生成文件夹
export function generateFolder() {
    return request({
        url: '/app/generate',
        method: 'get'
    })
}
