import request from '@/utils/request'

// TODO 登录
export function login (loginForm) {
    return request({
        url: '/app/login',
        method: 'post',
        data: loginForm
    })
}

// TODO 注册 获取验证码
export function registryCode (email, verify) {
    return request({
        url: `/app/registryOrLogin?email=${email}&verify=${verify}`,
        method: 'get'
    })
}

// TODO 注册
export function registry (registryForm) {
    return request({
        url: '/app/registryOrLogin',
        method: 'post',
        data: registryForm
    })
}

// TODO 退出登录
export function logout () {
    return request({
        url: '/app/logout',
        method: 'post'
    })
}

// TODO 发送邮箱验证码
export function sendEmailFindPsw (email, verify) {
    return request({
        url: `/app/user/findPsw?email=${email}&verify=${verify}`,
        method: 'get'
    })
}

// TODO 找回密码
export function findPsw (form) {
    return request({
        url: '/app/user/findPsw',
        method: 'put',
        data: form
    })
}
