<template>
  <div v-title :data-title="this.title">
    <div class="search-box">
      <el-input class="search" placeholder="搜索你的创意" v-model="keyword">
        <el-button slot="append" icon="el-icon-search" @click="newSearch"></el-button>
      </el-input>
    </div>

    <div style="margin-top: 10px">
      <!--   筛选框   -->
      <div class="filter-box">
        <div>
          <label>类别: </label>
          <el-select v-model="categoryFilter" size="small" @change="handleFilterChange">
            <el-option v-for="item in $store.getters['staticStore/categoryList']"
                :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>

        <div class="filter-box">
          <label>排序方式: </label>
          <el-select v-model="orderFilter" size="small" @change="handleFilterChange">
            <el-option v-for="item in $store.getters['staticStore/orderList']"
                :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>

        <div class="filter-box">
          <el-radio-group v-model="descFilter" size="small" @change="handleFilterChange">
            <el-radio-button v-for="item in $store.getters['staticStore/descList']"
                             :label="item.id" :key="item.id"> {{ item.name }} </el-radio-button>
          </el-radio-group>
        </div>
      </div>

      <!--   结果框   -->
      <div style="margin-top: 10px">
        <el-empty v-if="productList.length === 0" description="暂时没有搜到您想要的结果哦"></el-empty>
        <el-row type="flex" v-else>
          <ul style="max-height: 720px; list-style: none; overflow:auto; width: 100%">
            <li v-for="item in productList" :key="item.id">
              <el-col :span="6" >
                <el-card @click.native="openDetail(item.id, item.title)" :style="{'height': '330px'}">
                  <div style="display: flex; justify-content: center; align-items: center">
                    <el-image style="max-width: 80%; max-height: 80%" :src="item.cover" fit="contain"></el-image>
                  </div>
                  <div style="padding: 10px">
                    <p>{{ item.title }}</p>
                    <p>￥{{ item.price }}</p>
                  </div>
                </el-card>
              </el-col>
            </li>
          </ul>

          <div style="display: flex; justify-content: right; align-items: center; width: 100%; margin: 36px">
            <el-button-group>
              <el-button type="primary" icon="el-icon-arrow-left" :disabled="page === 1" @click="prePage">上一页</el-button>
              <el-button type="primary" :disabled="nextDisable" @click="nextPage">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
            </el-button-group>
          </div>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { searchByUserId } from '@/api/model'
export default {
  name: 'searchPage',
  data () {
    return {
      productList: [],
      keyword: null,
      categoryFilter: 0,
      orderFilter: 0,
      descFilter: 1,
      page: 1,
      pageSize: 8,
      nextDisable: false,
      title: '3D Mall'
    }
  },
  created() {
    // 获取参数
    const keyword = this.$route.query.keyword
    if (keyword !== null && keyword !== undefined) {
      this.keyword = keyword
      this.title = '搜索页 -> ' + keyword
    }
    const modelType = this.$route.query.modelType
    if (modelType !== null && modelType !== undefined) {
      this.categoryFilter = Number(modelType)
      if (modelType !== '0') this.title = '搜索页 -> ' + this.$store.getters["staticStore/categoryList"][modelType].name
    }
  },
  mounted() {

    // 根据参数 - 关键词查询
    this.search()
  },
  methods: {
    search () {
      // 搜索
      searchByUserId(this.keyword, this.page, this.pageSize,
          this.descFilter, this.orderFilter, this.categoryFilter).then((res) => {
        this.$message.success(res.msg)
        if (res.data.length !== 0) this.productList = res.data
        else {
          if (this.page === 1) this.productList = res.data
          else this.page--
        }
        this.nextDisable = res.data.length < this.pageSize
      }).catch((err) => {
        console.log(err)
      })
    },
    newSearch () {
      this.page = 1
      this.descFilter = 1
      this.orderFilter = 0
      this.categoryFilter = 0
      this.search()
    },
    handleFilterChange() {
      this.page = 1
      this.search()
    },
    nextPage() {
      this.page++
      this.search()
    },
    prePage() {
      if (this.page > 1) {
        this.page--
        this.search()
        return
      }
      this.page = 1
    },
    openDetail(id, title) {
      let routeData = this.$router.resolve({
        path: `/detail?id=${id}&title=${title}`
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style scoped>
.search-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}
:deep(.search) {
  height: 30px;
  width: 720px;
  border-radius: 10px;
}

:deep(.el-row) {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
}

.filter-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
</style>
