<template>
  <div>
    <!--  筛选框  -->
    <div>
      <div style=" margin-top: 33px">
        状态:
        <el-radio-group v-model="status" @change="statusChange">
          <el-radio-button :label="null">全部</el-radio-button>
          <el-radio-button :label="0">待付款</el-radio-button>
          <el-radio-button :label="2">超时未支付</el-radio-button>
          <el-radio-button :label="1">已完成</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <!--  表格  -->
    <div style="width: 100%">
      <el-table :data="orderList" style="width: 100%" max-height="720px">
        <el-table-column label="订单详情">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: left; width: 100%">
              <el-avatar :size="25" style="margin-left: 33px; margin-right: 23px">
                <img v-if="scope.row.sellerIcon" :src="scope.row.sellerIcon"/>
                <div v-else> user </div>
              </el-avatar>
              {{ scope.row.sellerUsername }}
            </div>

            <div style="margin-left: 33px; margin-top: 23px; display: flex; width: 100%">
              <div style="width: 280px">
                订单编号: {{ scope.row.id }}
                <img v-if="scope.row.model.cover" :src="scope.row.model.cover" style="height: auto; width: 240px" @click="$router.push(`/detail?id=${scope.row.modelId}`)">
                <el-avatar v-else :size="80">商品已下架</el-avatar>
              </div>

              <div style="margin-left: 33px; width: 300px">
                <div style="font-size: 16px; font-weight: bold">{{ scope.row.model.title }}</div>
                <div style="font-size: 12px; color: silver">描述: {{ scope.row.model.description }}</div>
              </div>

              <div style="margin-left: 33px; width: 300px">
                <div>成交价格: {{ scope.row.price }}</div>
                <div>下单时间: {{ scope.row.createTime }}</div>
                <div v-if="scope.row.status === 1">支付时间: {{ scope.row.payTime }}</div>
                <div v-if="scope.row.status === 0">订单状态: 待付款</div>
                <div v-else-if="scope.row.status === 2">订单状态: 超时未支付</div>
                <div v-else-if="scope.row.status === 1">订单状态: 已完成</div>
              </div>
            </div>
            <el-divider></el-divider>
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="200">
          <template slot-scope="scope">
            <!--            <el-button type="text" @click="" :disabled="scope.row.status !== 0">支付</el-button>-->
            <!--            <el-button type="text" @click="" :disabled="scope.row.status !== 1">退款</el-button>-->
<!--            <el-button type="text" :disabled="scope.row.status !== 1">下载</el-button>-->
            <!--            <el-button type="text" @click="$refs.detail.open(scope.row)">查看详情</el-button>-->
            <el-button type="text" @click="$refs.delete.open(scope.row.id, '确定删除吗')" style="color: red">删除订单</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--  分页条  -->
    <div style="display: flex; justify-content: center; align-items: center">
      <el-pagination
          @size-change="sizeChange"
          @current-change="getOrders"
          :current-page.sync="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="pageSize"
          layout="total, sizes, prev, pager, next"
          :total="totalOrder">
      </el-pagination>
    </div>

    <TipsDialog ref="delete" @handleConfirm="confirmDelete"></TipsDialog>
    <OrderDetailBuyer ref="detail"></OrderDetailBuyer>
  </div>
</template>

<script>
import {deleteOrder, listOfOrder, numberOfOrder} from '@/api/order'
import TipsDialog from '@/components/TipsDialog'
import OrderDetailBuyer from '@/views/self/order/OrderDetailBuyer'
export default {
  name: 'SellOut',
  components: {
    TipsDialog,
    OrderDetailBuyer
  },
  data () {
    return {
      totalOrder: 0,
      status: null,
      page: 1,
      pageSize: 10,
      desc: 1,
      orderList: []
    }
  },
  mounted() {
    this.getNum()
    this.getOrders()
  },
  methods: {
    // TODO 获取订单数量
    getNum () {
      const promise = numberOfOrder(false, this.status)
      promise.then((res) => {
        if (res.code !== 200) return
        this.totalOrder = res.data
      })
    },

    // TODO 获取订单列表
    getOrders () {
      const promise = listOfOrder(this.page, this.pageSize, this.desc, false, this.status);
      promise.then((res) => {
        if (res.code !== 200) return
        this.orderList = res.data
        for (let i = 0; i < res.data; i++) {
          this.orderList[i].modelInfo = JSON.parse(this.orderList[i].modelInfo)
        }
      })
    },

    // TODO 页码改变
    sizeChange (e) {
      this.page = 1
      this.getOrders()
    },

    // TODO 状态改变
    statusChange (e) {
      this.page = 1
      this.getNum()
      this.getOrders()
    },

    // TODO
    confirmDelete (id) {
      const promise = deleteOrder(id, false);
      promise.then((res) => {
        if (res.code !== 200) return
        setTimeout(() => this.getOrders(), 1500)
      })
    }
  }
}
</script>

<style scoped>

</style>
