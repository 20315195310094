<template>
  <div style="display: grid; justify-content: center; align-items: center;">
      <el-form style="width: 300px;">
        <el-form-item label="用户名/邮箱">
          <el-input v-model.trim="loginForm.usernameOrEmailOrPhone"
          placeholder="Username / Your Email"></el-input>
        </el-form-item>

        <el-form-item>
          <label>图片验证码</label>
          <div style="display: flex; justify-content: space-between;">
            <img id="verify" @click="changeVerify" style="margin-top: 5px" src="/api/verify/app-login/100/32/4">
            <el-input placeholder="Verification Code" v-model.trim="loginForm.verifyCode" style="margin-left: 40px;"></el-input>
          </div>
        </el-form-item>

        <el-form-item>
          <label>
            密码 <slot><a href="#" >忘记密码? | Forgot ?</a></slot>
          </label>
          <div style="display: flex; justify-content: space-between;">
            <el-input placeholder="Password" v-model.trim="loginForm.password" show-password></el-input>
          </div>
        </el-form-item>
      </el-form>
      <el-button :disabled="signInDisable" type="primary" @click="signIn">登录 | Sign In</el-button>
    </div>

</template>

<script>
import { login } from '@/api/login'
import { removeUser, setToken } from '@/utils/storage'

export default {
  name: "LoginBox",
  data() {
    return {
      loginForm: {
        usernameOrEmailOrPhone: '',
        verifyCode: '',
        password: '',
        pattern: new RegExp("^(\\w+([-.][A-Za-z0-9]+)*){3,18}@\\w+([-.][A-Za-z0-9]+)*\\.\\w+([-.][A-Za-z0-9]+)*$")
      }
    }
  },
  computed: {
    signInDisable() {
      return this.loginForm.usernameOrEmailOrPhone === '' || this.loginForm.verifyCode === '' || this.loginForm.password === ''
    }
  },
  methods: {
    changeVerify () {
      const verify = window.document.getElementById('verify')
      verify.src = '/api/verify/app-login/100/32/4?time=' + new Date().getMilliseconds()
    },
    // 密码登录
    async signIn () {
      let success = false
      await login(this.loginForm).then((res) => {
        success = res.code === 200
        if (!success) return
        this.$message.success(res.msg)
        setToken(res.data)
        //


      }).catch((error) => {
        console.log(error)
      })

      if (success) {
        removeUser()
        await this.$store.dispatch('user/userInfoAction')
        setTimeout(() => {
          this.loginForm.password = ''
          this.loginForm.usernameOrEmailOrPhone = ''
          this.loginForm.verify = ''
          this.$router.push({
            path: '/main'
          })
        }, 2000)
      }
    }
  }
}
</script>

<style scoped>

</style>
