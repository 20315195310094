<template>

  <div style="display: grid; justify-content: center; align-items: center;" >
    <div style="border: 1px silver solid; border-radius: 8px; width: 640px; padding: 20px">
      <div class="horizontalCenter">
        <el-avatar   :size="60" shape="circle" style="margin-right: 20px">
          <img v-if="$store.getters['user/info'].icon" :src="$store.getters['user/info'].icon" >
          <div v-else> user </div>
        </el-avatar>
        <el-input class="horizontalCenter" style="width: 540px" type="textarea" placeholder="留下您宝贵的评论" v-model="comment.content"></el-input>
      </div>

      <el-rate class="horizontalCenter" style="margin-top: 20px" v-model="comment.star" :max="5"></el-rate>
      <div style="display: flex; justify-content: right; align-items: center">
        <el-button @click="publishComment" :disabled="publishDisable">发布</el-button>
      </div>
    </div>

    <div style="margin-top: 36px">
      <div style="display: flex; width: 80px">
        <el-button type="text" @click="defaultList" style="color: black">默认</el-button>
        <hr>
        <el-button type="text" @click="newest" style="color: black" >最新</el-button>
      </div>
      <el-empty v-if="commentList.length === 0" description="雁过无痕"></el-empty>
      <ul v-else>
        <li v-for="item in commentList" class="infinite-list-item" style="list-style: none;">
          <div style="display: flex;">
            <el-avatar :size="60" shape="circle" style="margin-right: 20px">
              <img v-if="item.icon" :src="item.icon" >
              <div v-else> user </div>
            </el-avatar>
            <div style="display: flex; justify-content: space-between; align-items: center; width: 80%">
              <div style="">
                <div>{{ item.username }}</div>
                <el-rate v-model="item.star" disabled show-score text-color="#ff9900" :score-template="`${item.star}`"/>
                <div style="font-size: 12px; margin-top: 10px">{{ item.content }}</div>
                <div style="color: silver; font-size: 12px; display: flex; justify-content: left; margin-top: 10px">发布于{{ item.publishTime }}</div>
              </div>
              <el-button type="text" @click="deleteComment(item.id)" style="color: red"
                         :disabled="$store.getters['user/info'].id !== item.userId && $store.getters['user/info'].id !== userId">删除</el-button>
            </div>
          </div>
          <hr>
        </li>
      </ul>
      <div style="display: flex; justify-content: center; align-items: center">
        <el-button type="text" @click="append" :disabled="noMore">加载更多</el-button>
      </div>
    </div>
  </div>

</template>

<script>
import { deleteComment, getComment, publishComment } from '@/api/comment'
import user from "@/store/modules/user";

export default {
  name: 'CommentZone',
  data() {
    return {
      comment: {
        content: '',
        star: 0,
        modelId: '',
        photo: null
      },
      commentList: [],
      commentFilter: {
        page: 1,
        pageSize: 20,
        desc: 0,
        star: -1,
        order: 0
      },
      noMore: false,
      userId : null
    }
  },

  mounted() {
  },

  methods: {
    // TODO 初始化评论区
    initZone(id, userId) {
      // TODO 评论绑定的模型（商品）id
      this.comment.modelId = id
      this.userId = userId
      // TODO 请求获取评论列表
      this.refreshComment()
    },
    // TODO 发表评论
    publishComment() {
      const promise = publishComment(this.comment);
      promise.then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
        this.comment.content = ''
        this.comment.star = 0
        this.refreshComment()
      })
    },
    // TODO 加载更多
    append() {
      if (this.noMore) {
        this.$message.success('没有更多了')
        return
      }
      this.page += 1
      const promise = getComment(this.comment.modelId, this.page, this.pageSize, this.commentFilter.desc, this.commentFilter.star, this.commentFilter.order);
      promise.then((res) => {
        if (res.code !== 200) return
        this.commentList.unshift(res.data)
        this.noMore = res.data.length < this.pageSize
      })
    },
    // TODO 刷新评论
    refreshComment() {
      this.page = 1
      this.pageSize = 20
      const promise = getComment(this.comment.modelId, this.page, this.pageSize, this.commentFilter.desc, this.commentFilter.star, this.commentFilter.order);
      promise.then((res) => {
        if (res.code !== 200) return
        this.commentList = res.data
        this.noMore = res.data.length < this.pageSize
      })
    },
    // TODO 获取最新
    newest() {
      this.commentFilter.desc = 1
      this.refreshComment()
    },
    // TODO 默认排序
    defaultList() {
      this.commentFilter.desc = 0
      this.refreshComment()
    },
    // TODO 删除评论
    deleteComment(id) {
      deleteComment(id).then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
        this.refreshComment()
      })
    }
  },
  computed: {
    publishDisable() {
      return this.comment.content === '' || this.comment.star === null
    }
  }
}
</script>

<style scoped>
.horizontalCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
