<template>
  <div id="radar" style="width: 100%; height: 480px" ></div>
</template>


<script>
import * as echarts from 'echarts'
import purple from '@/assets/theme/purple-passion.json'
export default {
  name: 'RadarAnalysis',
  props: {
    modelSet: {
    }
  },
  data() {
    return {
      chart: null,
      option: {
        title: { text: '总计雷达图' }, legend: {
          orient: 'vertical',
          left: 'right',
          top: '10%'
        },
        toolbox: { feature: { saveAsImage: {} } },
        radar: {
          indicator: [
            { name: '浏览' },
            { name: '点赞' },
            { name: '评论' },
            { name: '收藏' },
            { name: '下载' },
            { name: '成交' }
          ]
        },
        series: [{
          type: 'radar',
          data: [{ value: [0, 0, 0, 0, 0] }],
          label: { show: true }
        }]
      }
    }
  },
  mounted() {
    echarts.registerTheme('purple-passion', purple)
    const chartDom = document.getElementById('radar')
    this.chart = echarts.init(chartDom)
    this.chart.setOption(this.option)
  },

  methods: {
    // TODO 传数据进来画图
    reDraw(legendData, seriesData) {
      this.option.legend.data = legendData
      this.option.series[0].data = seriesData
      this.chart.setOption(this.option)
    },
  }
}
</script>

<style scoped>

</style>
