import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/store/modules/user'
import staticStore from '@/store/modules/static'
import cart from '@/store/modules/cart'
import notice from "@/store/modules/notice";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    staticStore,
    cart,
    notice
  }
})
