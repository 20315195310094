<template>
  <div>
    <el-empty description="找不到您想要的产品或页面"></el-empty>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>
