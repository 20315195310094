import request from '@/utils/request'


// TODO 提交反馈
export function submitFeedback(form) {
    return request({
        url: '/app/feedback',
        method: 'post',
        data: form
    })
}

// TODO 获取反馈历史
export function myFeedback(page, pageSize, desc) {
    return request({
        url: `/app/feedback/${page}/${pageSize}/${desc}`,
        method: 'get'
    })
}

// TODO 删除反馈
export function deleteFeedback(id) {
    return request({
        url: `/app/feedback/${id}`,
        method: 'delete'
    })
}

export function solve(id, solveType) {
    return request({
        url: `/app/feedback/${id}/${solveType}`,
        method: 'put'
    })
}
