<template>
  <div v-title data-title="个人中心 - 我的收藏">

    <el-breadcrumb separator-class="el-icon-arrow-right" style="margin: 10px">
      <el-breadcrumb-item :to="{ path: '/star' }">收藏夹列表</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="filter-box">

      <el-button icon="el-icon-folder-add" type="primary" size="mini" @click="$refs.star.dialogVisible = true">新建收藏夹</el-button>


      <div class="filter-item">
        <label>排序方式: </label>
        <el-select v-model="orderFilter" size="small" style="width: 120px" @change="handleFilterChange">
          <el-option label="创建时间" :value="1"></el-option>
          <el-option label="名称" :value="2"></el-option>
        </el-select>
      </div>

      <div class="filter-item">
        <el-radio-group v-model="desc" size="small" @change="handleFilterChange">
          <el-radio-button v-for="item in $store.getters['staticStore/descList']"
                           :label="item.id" :key="item.id"> {{ item.name }} </el-radio-button>
        </el-radio-group>
      </div>
    </div>

    <el-divider content-position="left"><div style="font-size: 16px"> 收藏夹列表 </div></el-divider>

    <el-empty v-if="favorListList.length === 0" description="还没有收藏夹哦, 请先创建"></el-empty>
    <el-row v-else>
      <ul style="overflow:auto; height: 560px; width: 100%; display: flex; justify-content: flex-start; flex-wrap: wrap;list-style-type: none;">
<!--        <el-col :span="4" >-->
          <li style="list-style: none; flex-basis: 20%; margin: 10px" v-for="item in favorListList" :key="item.id">
            <div style="display: grid; justify-content: center; border: 1px silver solid; border-radius: 8px; padding: 5px">
              <div style="display: flex; justify-content: center; align-items: center">
                <el-avatar v-if="item.cover" :src="item.cover" :size="100" shape="square" />
                <el-avatar v-else shape="square" :size="100"> 封面 </el-avatar>
              </div>
              <div class="text-item" style="justify-content: center;"> {{ item.name }} : {{ item.count }}</div>
              <div class="text-item" style="font-size: 12px; color: silver;  justify-content: center;"> 创建于{{ item.createTime }} </div>
              <div class="text-item" style="justify-content: space-around;">
                <el-button type="text" style="font-size: 12px" @click="listDetail(item.id, item.cover, item.name, item.remark, item.createTime)">打开</el-button>
                <el-button type="text" style="font-size: 12px" @click="$refs.edit.open(item)">编辑</el-button>
                <el-button type="text"  style="color: red; font-size: 12px" @click="$refs.tips.open(item.id)">删除</el-button>
              </div>
            </div>
          </li>
<!--        </el-col>-->
      </ul>

      <!--  分页条  -->
      <div style="display: flex; justify-content: center; align-items: center; width: 100%">
        <el-pagination
            @current-change="getList"
            :current-page.sync="page"
            :page-size.sync="pageSize"
            layout="total, sizes, prev, pager, next"
            :total="listTotal">
        </el-pagination>
      </div>
    </el-row>

    <UpdateStarDialog ref="edit" @handleChange="handleChange"></UpdateStarDialog>
    <TipsDialog ref="tips" @handleConfirm="handleDelete"></TipsDialog>
    <NewStarDialog ref="star" @handleSuccess="handleRequest"></NewStarDialog>
  </div>
</template>

<script>
import { deleteList, listList, numberOfMyList } from '@/api/favourite'
import UpdateStarDialog from '@/views/self/star/UpdateStarDialog'
import TipsDialog from '@/components/TipsDialog'
import NewStarDialog from '@/views/self/star/NewStarDialog'

export default {
  name: 'starPage',
  mounted() {
    this.getTotalNum()
    this.getList()
  },
  components: {
    UpdateStarDialog,
    TipsDialog,
    NewStarDialog
  },
  data() {
    return {
      favorListList: [],
      page: 1,
      pageSize: 8,
      orderFilter: 1,
      desc: 1,

      listTotal: 0
    }
  },
  methods: {
    getTotalNum () {
      numberOfMyList().then((res) => {
        if (res.code !== 200) return
        this.listTotal = res.data
      })
    },
    async getList() {
      const promise = listList(this.page, this.pageSize, this.orderFilter, this.desc);
      await promise.then((res) => {
        if (res.code !== 200) return
        this.favorListList = res.data
      })
    },

    handleFilterChange() {
      this.page = 1
      this.getList()
    },

    listDetail(id, cover, name, remark, createTime) {
      this.$router.push({
        path: '/starContent',
        query: {
          id: id,
          cover: cover,
          name: name,
          remark: remark,
          createTime: createTime
        }
      })
    },

    handleChange(item) {
      for (let i = 0; i < this.favorListList.length; i++) {
        if (item.id !== this.favorListList[i].id) continue
        this.$set(this.favorListList, i, item)
        break
      }
    },

    handleDelete(id) {
      deleteList(id).then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
      })
      this.getList()
    },

    handleRequest(list) {
      if (list === undefined || list === null) {
        return
      }
      if (this.favorListList.length < this.pageSize) {
        this.favorListList.push(list)
      }
    }
  }
}
</script>

<style scoped>
.filter-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /*margin: 10px;*/
  width: 100%;
  margin-top: 30px;
  padding: 10px;
}

.filter-item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 20px;
  /*width: 280px*/
  /*width: 20%;*/
}
:deep(.el-row) {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.text-item {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}
</style>
