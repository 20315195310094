
import request from '@/utils/request'

// 获取用户信息
export function getUserInfo () {
    return request({
        url: '/app/user',
        method: 'get'
    })
}

// 修改用户信息
export function setUserInfo (userForm) {
    return request({
        url: '/app/user',
        method: 'put',
        data: userForm
    })
}

// 设置新密码
export function setPassword (oldPassword, newPassword) {
    return request({
        url: `/app/user/setPsw?oldPassword=${oldPassword}&newPassword=${newPassword}`,
        method: 'put'
    })
}

// 修改邮箱 - 发送验证码到旧邮箱
export function setEmailVerifyOld() {
    return request({
        url: '/app/user/setEmail',
        method: 'get'
    })
}

// 修改邮箱 - 发送验证码到新邮箱
export function setEmailVerifyNew(form) {
    return request({
        url: '/app/user/setEmail',
        method: 'post',
        data: form
    })
}

// 修改邮箱 - 最终确认
export function applyNewEmail(form) {
    return request({
        url: '/app/user/setEmail',
        method: 'put',
        data: form
    })
}
