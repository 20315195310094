<template>
  <div :style="`height: ${height}; width: ${width}; display: flex; justify-content: center; align-items: center`">
    <div v-if="video">
      <el-upload class="avatar-uploader" action="/api/tempFile"
                 :show-file-list="false" :http-request="upload"
                 :before-upload="beforeVideoUpload">
        <video v-if="url" :src="url" controls="controls" :style="`height: ${height}; width: ${width};`"></video>
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </div>
    <div v-else>
      <img @click="openCropper" v-if="url" :src="url" style="width: 640px; height: 360px"/>
      <el-avatar v-else :size="80" shape="square">
        <i @click="openCropper" class="el-icon-plus avatar-uploader-icon" ></i>
      </el-avatar>
      <ImageCropper :check="check" :width="480" :height="270" ref="myCropper" @handleCheck="beforePhotoUpload" @handleChange="handleChange"></ImageCropper>
    </div>

  </div>
</template>

<script>
import { tempFile } from '@/api/file'
import ImageCropper from '@/components/ImageCropper'

export default {
  name: 'ExhibitComponent',
  components: { ImageCropper },
  props: {
    url: String,
    video: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '640px'
    },
    height: {
      type: String,
      default: '360px'
    },
  },
  mounted() {
  },
  data() {
    return {
      videoSizeLimit: 100,
      check: false
    }
  },
  methods: {
    openCropper() {
      this.$refs.myCropper.dialogVisible = true
    },
    // 上传
    upload (param) {
      const formData = new FormData()
      formData.append('file', param.file)
      tempFile(formData).then((res) => {
        if (res.code !== 200) return
        this.$emit('handleChange', res.data)
      })
    },
    handleChange(url) {
      this.$emit('handleChange', url)
    },
    // TODO  上传视频之前检查类型
    beforeVideoUpload (file) {
      const type = file.type
      let isPhoto = type === 'video/x-matroska' || type === 'video/mp4'
      let limit = file.size / 1024 / 1024 < this.videoSizeLimit

      if (!isPhoto) {
        this.$message.error('上传视频只能是 mp4 格式!')
      }
      if (!limit) {
        this.$message.error('上传视频大小不能超过 50MB!')
      }
      return isPhoto && limit
    },

    // TODO 上传图片之前检查类型
    beforePhotoUpload (file) {
      const type = file.type
      let isPhoto = (type === 'image/jpeg') || (type === 'image/png')
      let limit = ((file.size / 1024 / 1024) < 5)
      if (!isPhoto) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
      }
      if (!limit) {
        this.$message.error('上传头像图片大小不能超过 5MB!')
      }
      this.check = isPhoto && limit
    }
  }
}
</script>

<style scoped>
:deep(.avatar-uploader .el-upload) {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

:deep(.avatar-uploader .el-upload:hover) {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
</style>
