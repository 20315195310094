<template>
  <div v-title :data-title="title">
    <el-row>
      <el-col :span="left" id="exhibit">
        <div style="margin-right: 20px; margin-top: 25px">
          <el-tabs v-model="model.defaultShow" @tab-click="handleClick">
            <el-tab-pane label="模型展示" :name="1">
              <el-empty description="作者没有上传模型本体哦"
                  v-if="model.modelPath === null || model.modelPath === ''"></el-empty>
              <ThreeDimensionExhibit v-else ref="exhibit"></ThreeDimensionExhibit>
            </el-tab-pane>

            <el-tab-pane label="图片介绍" :name="2">
              <el-carousel :interval="4000" type="card"  >
                <el-carousel-item v-for="(item, index) in photoList" :key="index" style="height:270px; width:480px">
                  <img :src="item" class="image">
                </el-carousel-item>
              </el-carousel>
            </el-tab-pane>

            <el-tab-pane label="视频介绍" :name="3">
              <div style="display: flex; justify-content: center; align-items: center">
                <video v-if="model.video !== null && model.video !== ''" :src="model.video" controls="controls" style="height: 450px; width: 800px; "></video>
                <el-empty v-else description="作者没有上传视频介绍哦"></el-empty>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
      <el-col :span="right" id="info">
        <div style="margin-top: 75px">
          <el-descriptions class="margin-top" :title="model.title" :column="1" size="medium" border>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-collection-tag"></i>
                类别
              </template>
              {{ $store.getters["staticStore/uploadCategoryList"][model.category - 1].name }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-info"></i>
                描述
              </template>
              {{ model.description }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
<!--                <i class="el-icon-s-finance"></i>-->
<!--                <i class="el-icon-bank-card"></i>-->
<!--                <i class="el-icon-money"></i>-->
                <i class="el-icon-coin"></i>
                价格
              </template>
              ￥{{ model.price }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-time"></i>
                发布时间
              </template>
              {{ model.createTime }}
            </el-descriptions-item>
            <!--   点击跳转作者主页   -->
            <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-user"></i>
                作者
              </template>
              <div style="display: flex; align-items: center;" @click="toAuthor(model.userId, model.username)">
                <el-avatar :src="model.icon" size="large"></el-avatar>
                <div style="margin-left: 20px">{{ model.username }}</div>
              </div>
            </el-descriptions-item>
          </el-descriptions>
          <div style="display: flex; justify-content: space-around; align-items: center; margin-top: 36px">
            <el-button type="primary" @click="pay">立即购买</el-button>
            <el-button title="加入购物车" icon="el-icon-shopping-cart-2" type="danger" style="margin-left: 20px" @click="$store.dispatch('cart/addToList', model.id)">加入购物车</el-button>
          </div>
          <div style="display: flex; justify-content: space-around; align-items: center; width: 100%; margin-top: 36px">
            <div><i :class="{ 'action-icon': true }" class="el-icon-view"/> {{ model.views }} 看过</div>
            <div @click="click2Like">
              <i :class="{
                'action-icon': true,
                'high-light': model.isLike }" class="el-icon-magic-stick"/>
              {{ model.likes }} 点赞
            </div>
            <div @click="$refs.favourite.open(model.id)">
              <i :class="{
                'action-icon': true,
                'high-light': model.isCollected,
                'el-icon-star-off': !model.isCollected,
                'el-icon-star-on': model.isCollected }"/>
              {{ model.collect }} 收藏
            </div>
            <div><i :class="{ 'action-icon': true }" class="el-icon-download"/> {{ model.download }} 下载</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-divider content-position="left">评论区</el-divider>
    <!--  评论区  -->
    <el-row>
      <el-col :span="24">
        <CommentZone ref="comment"></CommentZone>
      </el-col>
    </el-row>
    <!--  收藏弹窗  -->
    <AddFavouriteDialog  ref="favourite" @handleChange="handleChange"></AddFavouriteDialog>
  </div>
</template>

<script>
import { queryById } from '@/api/model'
import { like } from '@/api/userAction'
import CommentZone from '@/views/home/itemDetail/comment'
import ThreeDimensionExhibit from '@/views/home/itemDetail/3d-board-exhibit'
import AddFavouriteDialog from '@/views/home/itemDetail/AddFavouriteDialog'
import {generateOrder} from "@/api/order";
import {payRequest} from "@/api/pay";
import user from "@/store/modules/user";

export default {
  name: 'itemDetail',
  components: {
    CommentZone,
    ThreeDimensionExhibit,
    AddFavouriteDialog
  },
  async mounted() {
    await this.getModel()
    const width = document.getElementById('exhibit').offsetWidth
    const height = document.getElementById('info').offsetHeight
    if (this.model.modelPath) {
      this.$refs.exhibit.initAll(width, height)
      this.$refs.exhibit.loadModel(this.model.modelPath)
    }
    this.$refs.comment.initZone(this.model.id, this.model.userId)
  },
  data () {
    return {
      model: null,
      left: 16,
      right: 8,
      photoList: [],
      title: '3D Mall'
    }
  },
  created() {
    this.title = '详情页 -> ' + this.$route.query.title
  },
  methods: {
    handleChange(countIncrement, isCollected) {
      this.model.isCollected = isCollected
      this.model.collect += countIncrement
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    // 获取模型信息并初始化
    async getModel() {
      // 接收的数据赋值页面
      await queryById(this.$route.query.id).then((res) => {
        if (res.code !== 200) return
        // 接收数据
        this.model = res.data
        // 提示信息
        this.$message.success(res.msg)
      }).catch((error) => {
        console.log(error)
      })
      this.photoList.length = 0
      this.photoList.unshift(this.model.cover)
      if (this.model.frontView !== undefined && this.model.frontView !== null) {
        this.photoList.unshift(this.model.frontView)
      }
      if (this.model.leftView !== undefined && this.model.leftView !== null) {
        this.photoList.unshift(this.model.leftView)
      }
      if (this.model.topView !== undefined && this.model.topView !== null) {
        this.photoList.unshift(this.model.topView)
      }
      if (this.model.otherView !== undefined && this.model.otherView !== null) {
        this.photoList.unshift(this.model.otherView)
      }
    },

    // 点赞
    click2Like() {
      like(this.$route.query.id).then((res) => {
        if (res.code !== 200) return
        this.model.likes += (this.model.isLike ? -1 : 1)
        this.model.isLike = !this.model.isLike
        this.$message.success(res.msg)
      }).catch((error) => {
        console.log(error)
      })
    },


    // TODO 根据勾选的商品 - 调用方法获取支付id 然后 调用方法去支付
    async pay() {
      if (this.model === null || this.model.id === null) location.reload()

      const payId = await this.getPayId([this.model.id])

      this.gotoPay(payId)
      // console.log(payId)
    },

    // TODO 获取支付id
    async getPayId(idList) {
      let payId = null
      // TODO 向服务器发起请求 - 生成订单
      const promise = generateOrder(idList)
      await promise.then((res) => {
        if (res.code !== 200) return
        payId = res.data
      })
      return payId
    },

    // TODO 根据支付id 去支付
    gotoPay(payId) {
      const loading = this.$loading({
        lock: true,
        text: '订单已经生成, 准备跳转...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      setTimeout(() => {
        const payPromise = payRequest(payId)
        payPromise.then((res) => {
          if (res.code !== 200) return
          // 获取返回的数据
          const data = res.data
          // console.log(data)
          // 创建一个div元素，并将返回的数据设置为div的innerHTML
          const div = document.createElement('div')
          div.innerHTML = data

          // 将div元素添加到页面的body中
          document.body.appendChild(div);

          // 设置表单的target属性为_blank，以在新窗口中打开提交的表单
          document.forms[0].setAttribute('target','_blank')

          // 提交表单
          document.forms[0].submit()
        })
        loading.close()
      }, 3000)
    },

    toAuthor (userId, username) {
      let routeData = this.$router.resolve({
        path: `/visitor/${userId}?username=${username}`
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.image {
  width: 480px;
  height: 270px;
  display: block;
}

.action-icon {
  font-size: 25px;
}

.high-light {
  color: red;
}



</style>
