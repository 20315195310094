<template>
  <div>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
      <slot name="tips">
        <div>
          {{ content }}
        </div>
      </slot>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'DeleteDialog',
  data() {
    return {
      dialogVisible: false,
      id: '',
      content: '删除收藏夹会删除原收藏夹内容',
      title: '提示'
    }
  },
  methods: {
    confirm() {
      this.$emit('handleConfirm', this.id)
      this.dialogVisible = false
    },
    open(id, tips) {
      if (tips) {
        this.content = tips
      }
      this.id = id
      this.dialogVisible = true
    },
    openWithTittle(id, content, title) {
      if (content) {
        this.content = content
      }
      this.id = id
      this.title = title
      this.dialogVisible = true
    }
  }

}
</script>

<style scoped>

</style>
