import Vuex from 'vuex'
import Vue from 'vue'
import {numberOfNotice} from "@/api/notice";
Vue.use(Vuex)

export default {
  namespaced: true,
  state () {
    return {
      notRead: 0,
      total: 0,
      read: 0,
      show: 0
    }
  },
  getters: {
    getNotRead (state) {
      return state.notRead
    },
    getShow (state) {
      return state.show
    }
  },
  mutations: {
    setNotRead (state, notRead) {
      state.notRead = notRead
      if (state.total !== 0) {
        state.read = state.total - notRead
      }
    },
    setTotal (state, total) {
      state.total = total
      state.show = total
    },
    setShow (state, status) {
      if (status === null) state.show = state.total
      else if (status === true) state.show = state.read
      else this.totalNotice = state.show = state.notRead
    }
  },
  actions: {
    async getNum (context) {
      await numberOfNotice(null).then((res) => {
        if (res.code !== 200) return
        context.commit('setTotal', res.data)
      })
      await numberOfNotice(false).then((res) => {
        if (res.code !== 200) return
        context.commit('setNotRead', res.data)
      })
    }
  },
  modules: {
  }
}
