<template>
  <div style="display: grid">
    <el-breadcrumb separator-class="el-icon-arrow-right" style="margin: 20px">
      <el-breadcrumb-item :to="{ path: '/modelSet' }">作品集</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="search-box">
      <el-input class="search" placeholder="搜索你的作品集" v-model="keyword">
        <el-button slot="append" icon="el-icon-search" @click="newSearch"></el-button>
      </el-input>
    </div>

    <div style="margin-top: 10px">
      <!--   筛选框   -->
      <div class="filter-box">
        <div class="filter-item">
          <label>状态: </label>
          <el-select v-model="statusFilter" size="small" style="width: 100px" @change="numChange">
            <el-option v-for="item in $store.getters['staticStore/statusList']"
                       :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>

        <div class="filter-item">
          <label>类别: </label>
          <el-select v-model="categoryFilter" size="small" style="width: 150px" @change="numChange">
            <el-option v-for="item in $store.getters['staticStore/categoryList']"
                       :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>

        <div class="filter-item">
          <label>排序: </label>
          <el-select v-model="orderFilter" size="small" style="width: 120px" @change="search">
            <el-option v-for="item in $store.getters['staticStore/orderList']"
                       :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>

        <div class="filter-item">
          <el-radio-group v-model="descFilter" size="small" @change="search">
            <el-radio-button v-for="item in $store.getters['staticStore/descList']"
                             :label="item.id" :key="item.id"> {{ item.name }} </el-radio-button>
          </el-radio-group>
        </div>
      </div>

      <!--   结果框   -->
      <div style="margin-top: 10px">
        <el-empty v-if="productList.length === 0" description="还没有作品哦, 快去上传吧"></el-empty>
        <div v-else>
          <el-table :data="productList" style="width: 100%;" max-height="640">
            <el-table-column fixed label="封面" width="320">
              <template slot-scope="scope">
                <img :src="scope.row.cover" class="image">
              </template>
            </el-table-column>

            <el-table-column label="商品详情" width="240" show-overflow-tooltip>
              <template slot-scope="scope">
                <div style="padding: 10px; width: 200px;">
                  <h3>{{ scope.row.title }}</h3>
                  <p style="font-size: 12px; color: silver">{{ scope.row.description }}</p>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="发布时间" prop="createTime" width="240" show-overflow-tooltip>
            </el-table-column>

            <el-table-column label="部分指标" width="135" show-overflow-tooltip>
              <template slot-scope="scope">
                <div style="display: grid; justify-content: flex-start; align-items: center;">
                  <div><i :class="{ 'action-icon': true }" class="el-icon-view"/> {{ scope.row.views }} 看过</div>
                  <div><i :class="{ 'action-icon': true }" class="el-icon-magic-stick"/>{{ scope.row.likes }} 点赞</div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="模型状态" width="120">
              <template slot-scope="scope">
                <p v-if="scope.row.status === 1">上架中</p>
                <p v-if="scope.row.status === 0">下架中</p>
                <p v-if="scope.row.status === 2">审核中</p>
                <p v-if="scope.row.status === 3">强制下架/审核未通过</p>
              </template>
            </el-table-column>

            <el-table-column fixed="right" label="操作" width="300">
              <template slot-scope="scope">
                <div style="width: 240px;height: 160px; display: flex; justify-content: space-around; align-items: center; margin-right: 20px">
                  <el-link icon="el-icon-link" :underline="false" @click.native="openDetail(scope.row.id, scope.row.title)">详情页</el-link>
                  <el-link type="primary" v-if="scope.row.status === 0 || scope.row.status === 3" @click.native.prevent="upDown(scope.row.id, scope.$index)" :underline="false">申请上架</el-link>
                  <el-link type="primary" v-else-if="scope.row.status === 1" @click.native.prevent="upDown(scope.row.id, scope.$index)" :underline="false">下架</el-link>
                  <el-link type="danger" v-else disabled>上架</el-link>
                  <el-link icon="el-icon-edit-outline" :underline="false" @click.native="$router.push(`/update?id=${scope.row.id}`)">编辑</el-link>
                  <el-link style="color: red" icon="el-icon-delete" :underline="false" @click.native="deleteItem(scope.row.id)">删除</el-link>
                </div>
              </template>
            </el-table-column>
          </el-table>


        </div>
      </div>

      <!--  分页条  -->
      <div style="display: flex; justify-content: center; align-items: center; width: 100%">
        <el-pagination
            @current-change="search"
            :current-page.sync="page"
            :page-size.sync="pageSize"
            layout="total, sizes, prev, pager, next"
            :total="totalModel">
        </el-pagination>
      </div>
    </div>



    <TipsDialog ref="delete" @handleConfirm="confirmDelete"></TipsDialog>
  </div>
</template>

<script>
import {deleteModel, numberOfMyModel, searchByUserId, setStatus} from '@/api/model'
import { mapActions } from 'vuex'
import TipsDialog from '@/components/TipsDialog'
export default {
  name: 'modelSet',
  components: {
    TipsDialog
  },
  data () {
    return {
      keyword: null,
      productList: [],
      categoryFilter: 0,
      orderFilter: 0,
      descFilter: 1,
      statusFilter: -1,
      page: 1,
      pageSize: 10,
      totalModel: 0
    }
  },
  async mounted() {
    // 根据参数 - 关键词查询
    await this.userInfoAction()
    this.getNum()
    this.search()
  },
  methods: {
    ...mapActions('user', ['userInfoAction']),
    getNum () {
      const promise = numberOfMyModel(this.categoryFilter, this.statusFilter)
      promise.then((res) => {
        if (res.code !== 200) return
        this.totalModel = res.data
      })
    },

    // 按照当前参数搜索
    search () {
      // 搜索
      const promise = searchByUserId(this.keyword, this.page, this.pageSize,
          this.descFilter, this.orderFilter, this.categoryFilter,
          this.statusFilter, this.$store.getters["user/info"].id)
      promise.then((res) => {
        if (res.code !== 200) return
        this.productList = res.data
      })
    },

    // TODO 清空筛选重新搜索
    newSearch () {
      this.page = 1
      this.descFilter = 1
      this.orderFilter = 0
      this.categoryFilter = 0
      this.search()
    },

    // TODO 上架下架
    upDown(id, index) {
      setStatus(id).then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
        this.productList[index].status = 1 - this.productList[index].status
      })
    },

    // TODO 删除作品（二次确认弹框）
    deleteItem(id) {
      this.$refs.delete.open(id, "确定删除吗")
    },

    // TODO 确认删除
    confirmDelete(id) {
      deleteModel(id).then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
        this.search()
      })
    },

    numChange () {
      this.page = 1
      this.getNum()
      this.search()
    },
    openDetail(id, title) {
      let routeData = this.$router.resolve({
        path: `/detail?id=${id}&title=${title}`
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style scoped>
.search-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}
:deep(.search) {
  height: 30px;
  width: 720px;
  border-radius: 10px;
}

.image {
  height: 160px;
  width: auto;
  display: block;
}
:deep(.el-row) {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.filter-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px;
  width: 100%;
}

.filter-item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /*width: 280px*/
  /*width: 20%;*/
}

.action-icon {
  font-size: 16px;
}
</style>
