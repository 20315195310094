<template>
  <div>
    <div style="display: flex; justify-content: center; align-items: center">
      <div style="width: 50%;">
        <el-steps :active="step">
          <el-step title="步骤 1" description="验证旧邮箱"></el-step>
          <el-step title="步骤 2" description="验证新邮箱"></el-step>
          <el-step title="步骤 3" description="完成"></el-step>
        </el-steps>
      </div>
    </div>

    <div v-if="step === 1" style="display: grid; justify-content: center; align-items: center;">
      <el-form style="width: 300px;">
        <el-form-item label="旧邮箱验证码">
          <el-input placeholder="Code From Original Email" v-model.trim="setEmailForm.oldVerification">
            <el-button size="mini" slot="append" icon="el-icon-message" @click="sendCode"></el-button>
          </el-input>
        </el-form-item>

        <el-form-item label="新邮箱">
          <el-input v-model.trim="setEmailForm.newEmail" placeholder="New Email">
          </el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="nextStep" :disabled="nextDisable">下一步 | Next</el-button>
    </div>

    <div v-if="step === 2" style="display: grid; justify-content: center; align-items: center;">
      <el-form style="width: 300px;">
        <el-form-item label="新邮箱">
          <el-input v-model.trim="setEmailForm.newEmail" disabled></el-input>
        </el-form-item>

        <el-form-item label="新邮箱验证码">
          <el-input placeholder="Code From New Email" v-model.trim="setEmailForm.newVerification"></el-input>
        </el-form-item>
      </el-form>
      <div style="display: flex; justify-content: space-between; align-items: center">
        <el-button type="primary" @click="this.step = 1">上一步 | Pre</el-button>
        <el-button type="primary" @click="applyUpdate" :disabled="applyDisable">应用变更 | Apply</el-button>
      </div>
    </div>

    <div v-if="step === 3" style="display: flex; justify-content: center">
      <div>
        <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-top: 33px">
          已完成
        </div>
        <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-top: 23px">
          <el-button type="primary" @click="step = 1">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { applyNewEmail, setEmailVerifyNew, setEmailVerifyOld } from "@/api/user"
import { mapMutations } from "vuex"
import {removeUser} from "@/utils/storage";
export default {
  name: "SetEmail",
  data () {
    return {
      step: 1,
      setEmailForm: {
        newEmail: '',
        oldVerification: '',
        newVerification: ''
      }
    }
  },
  methods: {
    ...mapMutations('user', ['setEmail']),
    // TODO 发送验证码到新邮箱
    nextStep () {
      setEmailVerifyNew(this.setEmailForm).then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
        this.step = 2
      })
    },

    // TODO 发送验证码 到旧邮箱
    sendCode () {
      setEmailVerifyOld().then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
      })
    },

    // TODO 最终修改
    applyUpdate () {
      let success = false
      applyNewEmail(this.setEmailForm).then((res) => {
        success = res.code === 200
        if (!success) return
        this.$message.success(res.msg)
        this.setEmail(this.setEmailForm.newEmail)
        this.setEmailForm.newEmail = ''
        this.setEmailForm.newVerification = ''
        this.setEmailForm.oldVerification = ''
        this.step = 3
      })
      if (success) {
        removeUser()
        this.$store.dispatch('user/userInfoAction')
      }
    }
  },
  computed: {
    nextDisable() {
      return this.setEmailForm.newEmail === '' || this.setEmailForm.oldVerification === ''
    },
    applyDisable() {
      return this.setEmailForm.newVerification === ''
    }
  }
}
</script>

<style scoped>

</style>
