<template>
  <div>
    <div>
      <el-table
          ref="multipleTable" tooltip-effect="dark"
          style="width: 100%" :stripe="true" max-height="800"
          :data="$store.getters['cart/list']"
          @selection-change="handleSelectionChange">

        <el-table-column type="selection" width="55"/>

        <el-table-column label="封面" width="300">
          <template slot-scope="scope">
            <img v-if="scope.row.cover" :src="scope.row.cover" style="height: auto; width: 240px" @click="$router.push(`/detail?id=${scope.row.id}`)">
            <el-avatar v-else :size="80">商品已下架</el-avatar>
          </template>
        </el-table-column>

        <el-table-column prop="title" label="标题/名称" width="300"/>

        <el-table-column prop="description" label="描述" width="300" show-overflow-tooltip/>

        <el-table-column
            label="作者"
            width="300">
          <template slot-scope="scope">
            <div style="display: flex; align-items: center">
              {{ scope.row.username }}
              <el-avatar :src="scope.row.icon" shape="circle" :size="60" style="margin-left: 10px"/>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="price" label="价格" width="300"/>
      </el-table>
    </div>
    <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 10px">
      <div style="margin-left: 20px">合计: {{ price }}</div>
      <div style="display: grid;">
        <div>(虚拟产品暂不支持退款, 请谨慎下单)</div>
        <div style="display: flex; justify-content: right; margin-top: 10px">
          <el-button type="info" :disabled="multipleSelection.length === 0" @click="removeCart">移除</el-button>
          <el-button style="margin-right: 20px;" type="danger" @click="pay" :disabled="multipleSelection.length === 0">结算</el-button>
        </div>
      </div>

    </div>
  </div>
</template>
<script>



import { generateOrder } from '@/api/order'
import { payRequest } from '@/api/pay'

export default {
  name: 'cartPage',
  mounted() {
    this.$store.dispatch('cart/getList')
  },
  data() {
    return {
      multipleSelection: []
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // TODO 根据勾选的商品 - 调用方法获取支付id 然后 调用方法去支付
    async pay() {
      let idList = []
      for (const item of this.multipleSelection) {
        idList.push(item.id)
      }

      const payId = await this.getPayId(idList)

      this.gotoPay(payId)

      // console.log(payId)
      setTimeout(() => {
        this.$store.dispatch('cart/removeFromList', idList)
      }, 5000)
    },

    // TODO 获取支付id
    async getPayId(idList) {
      let payId = null
      // TODO 向服务器发起请求 - 生成订单
      const promise = generateOrder(idList)
      await promise.then((res) => {
        if (res.code !== 200) return
        payId = res.data
      })
      return payId
    },

    // TODO 根据支付id 去支付
    gotoPay(payId) {
      const loading = this.$loading({
        lock: true,
        text: '订单已经生成, 准备跳转...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      setTimeout(() => {
        const payPromise = payRequest(payId)
        payPromise.then((res) => {
          if (res.code !== 200) return
          // 获取返回的数据
          const data = res.data
          // console.log(data)
          // 创建一个div元素，并将返回的数据设置为div的innerHTML
          const div = document.createElement('div')
          div.innerHTML = data

          // 将div元素添加到页面的body中
          document.body.appendChild(div);

          // 设置表单的target属性为_blank，以在新窗口中打开提交的表单
          document.forms[0].setAttribute('target','_blank')

          // 提交表单
          document.forms[0].submit()
        })
        loading.close()

      }, 3000)
    },

    // TODO 移除购物车
    removeCart() {
      let idList = []
      for (const item of this.multipleSelection) {
        idList.push(item.id)
      }
      this.$store.dispatch('cart/removeFromList', idList)
    }
  },
  computed: {
    price() {
      let price = 0
      for (let i = 0; i < this.multipleSelection.length; i++) {
        price += this.multipleSelection[i].price
      }
      return Number (price.toFixed(2))
    }
  }
}
</script>

<style scoped>

</style>
