<template>
  <div class="container" v-title data-title="3D Mall 登录页">
    <div id="main" style="width: 100%; height: 100%"></div>
    <div id="dialog">
      <div class="photo-box">
        <img src="../../assets/login_icon.png" alt="主图" width="auto" height="300">
      </div>
      <div class="signUp-box">
        <div class="logo">
          <div v-if="activeIndex === 1" style="width: 100%;">验证码登录</div>
          <div v-else-if="activeIndex === 2" style="width: 100%;">密码登录</div>
          <div v-else-if="activeIndex === 3" style="width: 100%;">找回密码</div>
        </div>
        <RegistryBox v-if="activeIndex === 1"></RegistryBox>
        <LoginBox  v-else-if="activeIndex === 2">
          <template>
            <a href="#" @click="activeIndex = 3">忘记密码? | Forgot?</a>
          </template>
        </LoginBox>
        <ResetPsw v-else-if="activeIndex === 3"></ResetPsw>
        <br>

        <el-button type="primary"
                   v-if="activeIndex === 1"
                   class="button-2n"
                   @click="activeIndex = 2">密码登录 | Password</el-button>
        <el-button type="primary"
                   v-else-if="activeIndex === 2"
                   class="button-2n"
                   @click="activeIndex = 1">邮箱登录 | Email</el-button>
        <el-button type="primary"
                   v-else
                   class="button-2n"
                   @click="activeIndex = 2">返回登录 | To Sign In</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import ResetPsw from '@/views/self/safe/resetPsw'
import LoginBox from '@/views/login/loginBox'
import RegistryBox from '@/views/login/registryBox'
import * as echarts from 'echarts'


export default {
  name: "loginPage",
  components: {
    ResetPsw,
    LoginBox,
    RegistryBox
  },
  data () {
    return {
      activeIndex: 1,
      chart: null,
      option: {
        graphic: {
          elements: [
              {
              type: 'text',
              left: 'center',
              top: '10%',
              style: {
                text: '3D Mall, More 3D',
                fontSize: 80,
                fontWeight: 'bold',
                lineDash: [0, 200],
                lineDashOffset: 0,
                fill: 'transparent',
                stroke: '#409EFF',
                lineWidth: 2
              },
              keyframeAnimation: {
                duration: 5000,
                loop: false,
                keyframes: [
                  {
                    percent: 0.7,
                    style: {
                      fill: 'transparent',
                      lineDashOffset: 200,
                      lineDash: [200, 0]
                    }
                  },
                  {
                    // Stop for a while.
                    percent: 0.8,
                    style: { fill: 'transparent' }
                  },
                  { percent: 1, style: { fill: '#409EFF' } }
                ]
              }
            }
          ]
        }
      }
    }
  },


  mounted() {
    const chartDom = document.getElementById('main')
    this.myChart = echarts.init(chartDom)
    this.myChart.setOption(this.option)
  },
  methods: {
  }
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 840px;
  width: 100%;
}

#dialog {
  content: '';
  position: absolute;
  width: 800px;
  height: 480px;
  border: 2px purple solid;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.5);
  margin-top: 5%;
  margin-left: 10%;
  display: flex;
}

.photo-box {
  height: 100%;
  border-right: 1px silver solid;
  display: grid;
  justify-content: center;
  align-items: center;
}

.logo {
  font-family: "Ink Free", serif;
  /*font-family: "Segoe Script", serif;*/
  font-weight: bold;
  color: blueviolet;
  font-size: 25px;
}

.signUp-box {
  height: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
  align-content: center;
}

.button-2n {
  width: 300px;
  height: 42px;
  color: blueviolet;
  font-weight: bold;
  font-size: 15px;
  background: white;
  border-radius: 8px;
  border: 1px silver solid;
}
</style>
