<template>
  <div id="pie" style="width: 600px; height: 600px">

  </div>
</template>

<script>
import * as echarts from 'echarts'
import purple from "@/assets/theme/purple-passion.json";
export default {
  name: 'PieAnalysis',
  data() {
    return {
      option: {
        avoidLabelOverlap: false,
        title: { text: 'Analysis', subtext: '', left: 'center' },
        label: { show: true },
        tooltip: { trigger: 'item' },
        toolbox: { feature: { saveAsImage: {} } },
        legend: { orient: 'vertical', left: 'right', data: []},
        series: [{
            type: 'pie',
            radius: '50%',
            data: [],
            emphasis: {
              label: { show: true, fontSize: '24', fontWeight: 'bold' },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
        }]
      },
      chart: null
    }
  },
  methods: {
    reDraw(seriesData, title) {
      this.option.title.subtext = title
      this.option.series[0].data = seriesData
      this.chart.setOption(this.option)
    },
    initPie(width, height) {
      echarts.registerTheme('purple-passion', purple)
      const chartDom = document.getElementById('pie')
      chartDom.style.width = width + 'px'
      chartDom.style.height = height + 'px'
      this.chart = echarts.init(chartDom)
      this.chart.setOption(this.option)
    }
  },
  mounted() {

  },

}
</script>

<style scoped>

</style>
