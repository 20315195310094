<template>
  <div>
    <div v-if="step === 1" style="display: grid; justify-content: center; align-items: center;">
      <el-form style="width: 300px;">
        <el-form-item label="邮箱">
          <el-input placeholder="Your Email" v-model.trim="registryForm.email"></el-input>
        </el-form-item>

        <el-form-item>
          <label>图片验证码</label>
          <div style="display: flex; justify-content: space-between;">
            <img id="app-registry" @click="changeVerifyRegistry('app-registry')" src="/api/verify/app-registry/100/32/4">
            <el-input v-model.trim="registryForm.verify" style="margin-left: 15px;" placeholder="Verification Code">
              <el-button :disabled="sendCodeDisable" size="mini" slot="append" icon="el-icon-message" @click="sendCode"></el-button>
            </el-input>
          </div>
        </el-form-item>
      </el-form>
      <el-button :disabled="nextDisable" type="primary" @click="step = 2">下一步 | Next</el-button>
    </div>

    <div v-if="step === 2" style="display: grid; justify-content: center; align-items: center;">
      <el-form style="width: 300px;">
        <el-form-item label="邮箱">
          <el-input disabled v-model.trim="registryForm.email"></el-input>
        </el-form-item>

        <el-form-item label="邮箱验证码">
          <el-input v-model.trim="registryForm.emailCode"
          placeholder="Email Code"></el-input>
        </el-form-item>
      </el-form>
      <div style="display: flex; justify-content: space-between; align-items: center">
        <el-button type="primary" @click="step = 1">上一步 | Pre</el-button>
        <el-button :disabled="signInDisable" type="primary" @click="signUp">登录 | Sign In</el-button>
      </div>

    </div>

  </div>

</template>

<script>
import { registry, registryCode } from '@/api/login'
import { removeUser, setToken } from '@/utils/storage'

export default {
  name: 'RegistryBox',
  data () {
    return {
      registryForm: {
        email: '',
        emailCode: '',
        verify: ''
      },
      step: 1,
      nextDisable: true,
      pattern: new RegExp("^(\\w+([-.][A-Za-z0-9]+)*){3,18}@\\w+([-.][A-Za-z0-9]+)*\\.\\w+([-.][A-Za-z0-9]+)*$")
    }
  },
  methods: {
    // TODO 重新获取验证码
    changeVerifyRegistry (id) {
      const verify = window.document.getElementById(id)
      verify.src = `/api/verify/${id}/100/32/4?time=` + new Date().getMilliseconds()
    },

    // TODO 发送验证码
    sendCode () {
      if (!this.pattern.test(this.registryForm.email)) {
        this.$message.error('邮箱格式不正确')
        return
      }
      const promise = registryCode(this.registryForm.email, this.registryForm.verify)
      promise.then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
        this.nextDisable = false
        //
      }).catch((error) => {
        console.log(error)
      })
    },

    // TODO 登录
    async signUp () {
      let success = false

      await registry(this.registryForm).then((res) => {
        success = res.code === 200
        if (!success) return
        this.$message.success(res.msg)
        setToken(res.data)
        this.registryForm.emailCode = ''
        this.registryForm.email = ''
        this.registryForm.verify = ''
      }).catch((error) => {
        console.log(error)
      })


      if (success) {
        removeUser()
        await this.$store.dispatch('user/userInfoAction')
        this.$router.push({
          path: '/main'
        })
      }
    }
  },
  computed: {
    sendCodeDisable() {
      return this.registryForm.verify === '' || this.registryForm.email === ''
    },
    signInDisable () {
      return this.registryForm.emailCode === '' || this.registryForm.email === ''
    }
  }
}
</script>

<style scoped>

</style>
