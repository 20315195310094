<template>
  <div>
    <el-dialog title="新建收藏夹" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">

      <div style="display: grid; justify-content: center; align-items: center;">
        <!--  头像  -->
        <el-form ref="form" :model="favorList" label-width="80px" style="width: 420px">

          <el-form-item label="封面" style="display: flex; align-items: center;">
            <el-avatar :size="80" shape="square">
              <img @click="openCropper" v-if="favorList.cover" :src="favorList.cover"/>
              <i @click="openCropper" v-else class="el-icon-plus avatar-uploader-icon" ></i>
            </el-avatar>
            <ImageCropper :check="illegal" ref="myCropper" @handleCheck="beforeAvatarUpload" @handleChange="handleChange"></ImageCropper>
          </el-form-item>

          <el-form-item label="名称">
            <el-input v-model.trim="favorList.name" placeholder="收藏夹名称"></el-input>
          </el-form-item>
          <el-form-item label="简介">
            <el-input type="textarea" placeholder="收藏夹简介" v-model.trim="favorList.remark"></el-input>
          </el-form-item>
          <el-form-item>
            <el-radio-group v-model="favorList.isPublic">
              <el-radio :label="true" :value="true">公开</el-radio>
              <el-radio :label="false" :value="false">私密</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: space-around; align-items: center">
        <el-button type="primary" @click="createStarList">创 建</el-button>
        <el-button @click="resetForm">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createList } from '@/api/favourite'
import VueCropper from 'vue-cropper'
import ImageCropper from '@/components/ImageCropper'

export default {
  name: 'NewStarDialog',
  components: {
    VueCropper,
    ImageCropper
  },
  data() {
    return {
      dialogVisible: false,
      favorList: {
        cover: null,
        name: '',
        remark: '',
        isPublic: true
      },
      type: null,
      illegal: false
    }
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    handleChange(param) {
      this.favorList.cover = param
    },
    // TODO 上传图片之前检查类型
    beforeAvatarUpload (file) {
      const type = file.type

      const isPhoto = type === 'image/jpeg' || type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5

      if (!isPhoto) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!')
      }
      this.illegal = isPhoto && isLt5M
    },

    // TODO 创建收藏夹
    createStarList() {
      const promise = createList(this.favorList)
      promise.then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
        this.favorList.id = res.data
        this.$emit('handleSuccess', JSON.parse(JSON.stringify(this.favorList)))
        this.resetForm()
      })
    },

    // TODO 重置表单
    resetForm() {
      this.favorList.cover = null
      this.favorList.name = ''
      this.favorList.isPublic = true
      this.favorList.remark = ''
      this.dialogVisible = false
    },
    openCropper() {
      this.$refs.myCropper.dialogVisible = true
    }
  }
}
</script>

<style scoped>
:deep(.avatar-uploader .el-upload) {
  /*border: 1px dashed #d9d9d9;*/
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
:deep(.avatar-uploader .el-upload:hover) {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
</style>
