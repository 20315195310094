<template>
  <div class="profile">
    <!--  头像  -->
    <div style="display: grid; justify-content: center; align-items: center;">
      <el-avatar :size="80" shape="circle">
        <div v-if="updateForm.icon === '' || updateForm.icon === null">头像</div>
        <el-image v-else style="width: 80px; height: 80px" :src="updateForm.icon" :preview-src-list="[updateForm.icon]"/>
      </el-avatar>
      <el-button type="primary" size="mini" style="margin-top: 10px" @click="$refs.myCropper.dialogVisible = true">点击上传</el-button>
      <ImageCropper ref="myCropper" :check="check" @handleCheck="beforeAvatarUpload" @handleChange="handleChange"></ImageCropper>
    </div>


    <el-form ref="form" :model="updateForm" label-width="80px" style="margin-top: 36px;  width: 420px">
      <el-form-item label="用户名">
        <el-input v-model="updateForm.username"></el-input>
      </el-form-item>
      <el-form-item label="简介">
        <el-input type="textarea" placeholder="您的留言" v-model="updateForm.message"></el-input>
      </el-form-item>
      <el-form-item label="性别">
        <el-radio-group v-model="updateForm.gender">
          <el-radio :label="1" :value="1"><i class="el-icon-male"></i>男</el-radio>
          <el-radio :label="0" :value="0"><i class="el-icon-female"></i>女</el-radio>
          <el-radio :label="2" :value="2"><i class="el-icon-question"></i>保密</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="生日">
        <el-date-picker value-format="yyyy-MM-dd" v-model="updateForm.birthday" type="date" placeholder="选择日期"/>
      </el-form-item>
      <el-form-item label="邮箱">
        <div style="display: flex; justify-content: space-between; padding-right: 30px">
          <el-input disabled v-model="updateForm.email" :style="{ width: '220px' }"></el-input>
          <a href="#" @click.prevent="$router.push('/safety?activeName=third')">更换邮箱</a>
        </div>
      </el-form-item>
    </el-form>
    <div style="display: flex; justify-content: space-around; align-items: center; margin-top: 20px; margin-bottom: 20px">
      <el-button @click="cancelEdit">取消</el-button>
      <el-button type="primary" @click="saveInfo">保存</el-button>
    </div>
  </div>
</template>

<script>
import ImageCropper from '@/components/ImageCropper'
import {removeUser} from "@/utils/storage";
import {setUserInfo} from "@/api/user";

export default {
  name: 'EditProfile',
  components: {
    ImageCropper
  },
  props: {
    step: {}
  },
  data () {
    return {
      updateForm: {
        icon: null,
        realName: null,
        username: null,
        birthday: null,
        gender: null,
        email: null,
        message: '',
        id: null
      },
      check: false
    }
  },
  mounted () {
    this.setAdminInfo(this.$store.getters['user/info'])
  },
  methods: {
    handleChange (e) {
      this.updateForm.icon = e
    },
    // TODO 更新个人信息
    async saveInfo () {
      const promise = setUserInfo(this.updateForm)
      let success = false
      await promise.then((res) => {
        success = res.code === 200
        if (!success) return
        this.$message.success(res.msg)
      })

      if (success) {
        removeUser()
        await this.$store.dispatch('user/userInfoAction')
        this.cancelEdit()
      }
    },

    // TODO 上传之前检查类型
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 5

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 5MB!')
      }
      this.check = isJPG && isLt2M
    },

    // TODO 设置资料
    setAdminInfo (userInfo) {
      this.updateForm.username = userInfo.username
      this.updateForm.message = userInfo.message
      this.updateForm.gender = userInfo.gender
      this.updateForm.birthday = userInfo.birthday
      this.updateForm.email = userInfo.email
      this.updateForm.icon = userInfo.icon
      this.updateForm.id = userInfo.id
    },

    //
    cancelEdit () {
      this.$emit('handleSuccess')
      this.setAdminInfo(this.$store.getters['user/info'])
    }
  }
}
</script>

<style scoped>
:deep(.avatar-uploader .el-upload) {
  /*border: 1px dashed #d9d9d9;*/
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
:deep(.avatar-uploader .el-upload:hover) {
  border-color: #409EFF;
}

.profile {
  display: grid;
  justify-content: center;
  align-items: center;
  margin: 30px;
  padding: 30px;
  border-radius: 8px;
  border: 1px silver solid;
  width: 560px;
}
</style>
