<template>

  <div>
    <div>
      <el-empty v-if="feedback.length === 0" description="还没有反馈过哦"></el-empty>
      <div v-else >

        <el-radio-group v-model="desc" @change="ratioChange">
          <el-radio-button :label="1">最新</el-radio-button>
          <el-radio-button :label="0">最早</el-radio-button>
        </el-radio-group>

        <el-table :data="feedback" style="width: 100%" max-height="600">
          <el-table-column fixed prop="feedbackTime" label="反馈时间" width="160"/>

          <el-table-column label="反馈图片说明" width="120">
            <template slot-scope="scope">
              <div style="display: flex; align-items: center">
                <el-avatar :size="80" shape="square">
                  <div v-if="scope.row.photo === '' || scope.row.photo === null">未提交图片</div>
                  <el-image v-else style="width: 100px; height: 100px" :src="scope.row.photo" :preview-src-list="[scope.row.photo]"/>
                </el-avatar>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="content" label="反馈内容" width="160" show-overflow-tooltip/>

          <el-table-column label="反馈状态" width="80">
            <template slot-scope="scope">
              <div v-if="scope.row.status === 0">未读</div>
              <div v-else-if="scope.row.status === 1">已读</div>
              <div v-else-if="scope.row.status === 2">已回复</div>
              <div v-else-if="scope.row.status === 3">已解决</div>
              <div v-else-if="scope.row.status === 4">未解决</div>
            </template>
          </el-table-column>

          <el-table-column prop="resTime" label="回复时间" width="135"/>

          <el-table-column prop="response" label="回复内容" width="160" show-overflow-tooltip/>

          <el-table-column label="回复图片说明" width="120">
            <template slot-scope="scope">
              <div style="display: flex; align-items: center">
                <el-avatar :size="80" shape="square">
                  <div v-if="scope.row.resPhoto === '' || scope.row.resPhoto === null">未提交图片</div>
                  <el-image v-else style="width: 100px; height: 100px" :src="scope.row.resPhoto" :preview-src-list="[scope.row.resPhoto]"/>
                </el-avatar>
              </div>
            </template>
          </el-table-column>

          <el-table-column fixed="right" label="操作" width="300">
            <template slot-scope="scope">
              <el-button @click.native.prevent="detail(scope.row.content)" type="text" size="small">
                反馈详情
              </el-button>

              <el-button @click.native.prevent="detail(scope.row.response)" type="text" size="small" :disabled="!scope.row.response">
                回复详情
              </el-button>
              <el-button :disabled="scope.row.status !== 2" @click.native.prevent="openDissolve(scope.row.id)" type="text" size="small">
                未解决
              </el-button>
              <el-button @click.native.prevent="openSolve(scope.row.id)" type="text" size="small" :disabled="scope.row.status === 3">
                已解决
              </el-button>
              <el-button style="color: red" @click.native.prevent="openConfirm(scope.row.id)" type="text" size="small">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <div style="display: flex; justify-content: right; margin: 20px">
          <el-button-group>
            <el-button type="primary" icon="el-icon-arrow-left" :disabled="page === 1" @click="prePage">上一页</el-button>
            <el-button type="primary" :disabled="nextDisable" @click="nextPage">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
          </el-button-group>
        </div>
      </div>

    </div>

    <TipsDialog ref="tips" @handleConfirm="confirmTips"></TipsDialog>
    <TipsDialog ref="delete" @handleConfirm="deleteFeedback"></TipsDialog>
    <TipsDialog ref="solve" @handleConfirm="solveFeedback"></TipsDialog>
    <TipsDialog ref="dissolve" @handleConfirm="dissolveFeedback"></TipsDialog>
  </div>



</template>

<script>
import TipsDialog from '@/components/TipsDialog'
import { deleteFeedback, myFeedback, solve } from '@/api/feedback'
export default {
  name: 'HistoryFeedback',
  components: {
    TipsDialog,
  },
  data() {
    return {
      page: 1,
      pageSize: 5,
      desc: 0,
      feedback: [],
      nextDisable: false
    }
  },
  mounted() {
    this.getFeedback()
  },
  methods: {
    // TODO 弹窗查看详情
    detail(item) {
      this.$refs.tips.open(null, item)
    },

    // TODO 打开删除二次确认
    openConfirm(id) {
      this.$refs.delete.open(id, "确定删除吗")
    },

    // TODO 详情弹窗确定事件
    confirmTips(id) {
      this.$refs.tips.dialogVisible = false
    },

    // TODO 删除二次确认确定事件
    async deleteFeedback(id) {
      const promise = deleteFeedback(id)
      await promise.then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
        this.getFeedback()
      })
      this.$refs.delete.dialogVisible = false
    },

    // TODO 请求获取反馈
    async getFeedback() {
      const promise = myFeedback(this.page, this.pageSize, this.desc)
      await promise.then((res) => {
        if (res.code !== 200) return
        const length = res.data.length;
        if (length !== 0) this.feedback = res.data
        else {
          if (this.page === 1) this.feedback = res.data
          else this.page--
        }
        if (length < this.pageSize) {
          this.nextDisable = true
        }
      })
    },

    //
    ratioChange(e) {
      this.getFeedback()
    },

    nextPage() {
      this.page++
      this.getFeedback()
    },

    prePage() {
      if (this.page > 1) {
        this.page--
        this.getFeedback()
        return
      }
      this.page = 1
    },
    solveFeedback(id) {
      const promise = solve(id, 3);
      promise.then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
        for (let i = 0; i < this.feedback.length; i++) {
          if (this.feedback[i].id === id) {
            this.feedback[i].status = 3
            break
          }
        }
      })
    },
    dissolveFeedback(id) {
      const promise = solve(id, 4);
      promise.then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
        for (let i = 0; i < this.feedback.length; i++) {
          if (this.feedback[i].id === id) {
            this.feedback[i].status = 4
            break
          }
        }
      })
    },
    openSolve(id) {
      this.$refs.solve.open(id, '确定已解决吗')
    },
    openDissolve(id) {
      this.$refs.dissolve.open(id, '确定未解决吗')
    }
  }
}
</script>

<style scoped>

</style>
