<template>
  <div style="display: grid; justify-content: center">
    <div id="webgl" style="">

    </div>

    <div style="">
      <!--   模型文件 & 背景颜色   -->
      <div style="display: flex; justify-content: flex-start">
        <div>
          <label style="margin-left: 20px; ">模型文件:</label>
          <el-select v-model="modelFolder" placeholder="请选择" style="width: 100px">
            <el-option :value="f1" :label="'/'"></el-option>
            <el-option :value="f2" :label="'/source'"></el-option>
          </el-select>
          <input id="model" class="uploadButton" style="margin-top: 5px;" type="file" @change="checkAndUpload"/>
        </div>

        <div class="config">
          <label style="">背景颜色</label>
          <el-color-picker v-model="backgroundColor" @change="backgroundColorChange"></el-color-picker>
        </div>
      </div>

      <!--   其他文件   -->
      <div style="display: flex; align-items: center">
        <label style="margin-left: 20px; ">其它文件:</label>
        <el-select v-model="otherFolder" placeholder="请选择" style="width: 100px">
          <el-option :value="f1" :label="'/'"></el-option>
          <el-option :value="f3" :label="'/textures'"></el-option>
        </el-select>
        <div>
          <label class="custom-file-upload">
            <span>选择文件</span>
            <input type="file" id="fileInput" @change="uploadOther" />
          </label>
        </div>

        <div>
          <label class="custom-file-upload">
            <span>选择文件夹</span>
            <input id="folderInput" style="margin-top: 5px;" type="file"
                   @change="uploadOther" webkitdirectory/>
          </label>
        </div>
        <div>
          <el-button type="primary" @click="loadModel(modelName)" size="small">重新加载</el-button>
        </div>
      </div>

      <div>
        <div class="config" style="justify-content: flex-start">
          <label style="margin-left: 20px; margin-right: 20px">太阳光颜色 & 光强</label>
          <el-color-picker v-model="sunLightColor" @change="sunLightColorChange"></el-color-picker>
        </div>
        <el-slider style="margin-left: 20px; width: 90%" @change="sunLightIntensityChange" v-model="sunLightIntensity" show-input :min="0" :max="10"></el-slider>
      </div>
      <div>
        <div class="config" style="justify-content: flex-start">
          <label style="margin-left: 20px; margin-right: 20px">环境光颜色 & 光强</label>
          <el-color-picker v-model="ambientLightColor" @change="ambientLightColorChange"></el-color-picker>
        </div>
        <el-slider style="margin-left: 20px; width: 90%" @change="ambientLightIntensityChange" v-model="ambientLightIntensity" show-input :min="0" :max="10"></el-slider>
      </div>
      <div>
        <div class="config" style="justify-content: flex-start">
          <label style="margin-left: 20px; margin-right: 20px">点光源颜色 & 光强</label>
          <el-color-picker v-model="pointLightColor" @change="pointLightColorChange"></el-color-picker>
        </div>
        <el-slider style="margin-left: 20px; width: 90%" @change="pointLightIntensityChange" v-model="pointLightIntensity" show-input :min="0" :max="10"></el-slider>
      </div>
      <div>
        <div class="config" style="justify-content: flex-start">
          <label style="margin-left: 20px; margin-right: 20px">半球光颜色 & 光强</label>
          <el-color-picker v-model="hemisphereLightColor" @change="hemisphereLightColorChange"></el-color-picker>
        </div>
        <el-slider style="margin-left: 20px; width: 90%" @change="hemisphereLightIntensityChange" v-model="hemisphereLightIntensity" show-input :min="0" :max="10"></el-slider>
      </div>
      <HugeUpload :showProgress="showProgress" ref="huge"></HugeUpload>
    </div>

    <el-dialog :title="tipsTitle" :visible.sync="dialogVisible" width="30%">
      <el-progress :text-inside="true" :stroke-width="26" :percentage="percent"></el-progress>
    </el-dialog>
  </div>

</template>

<script>
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { generateFolder, merge } from '@/api/file'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import HugeUpload from '@/components/HugeUpload'
import Notification from 'element-ui'
import Message from 'element-ui'

export default {
  name: 'ThreeDimensionBoardEdit',
  components: {
    HugeUpload
  },
  props: {
    width: {
      type: Number,
      default: 800
    },
    height: {
      type: Number,
      default: 450
    },
    modelPath: String,
    root: String
  },
  data() {
    return {
      backgroundColor: '#bfe3dd',

      // 3D 场景对象Scene
      scene: null,
      // 透视投影相机
      camera: null,
      // 画布
      canvas: null,
      // 渲染器对象
      renderer: null,
      controls: null,
      textureLoader: null,
      loader: null,
      object: null,

      // TODO 太阳光 & 光强 & 颜色
      sunLight0: null,
      sunLight1: null,
      sunLight2: null,
      sunLight3: null,
      sunLightIntensity: 2,
      sunLightColor: '#ffffff',

      // TODO 环境光 & 光强 & 颜色
      ambientLight: null,
      ambientLightIntensity: 1,
      ambientLightColor: '#ffffff',

      // TODO 点光源 & 光强 & 颜色
      pointLight: null,
      pointLightIntensity: 1,
      pointLightColor: '#ffffff',

      // TODO 半球光 & 光强 & 颜色
      hemisphereLight0: null,
      hemisphereLight2: null,
      hemisphereLightIntensity: 0.5,
      hemisphereLightColor: '#ffffff',

      // TODO 加载管理器
      loadingManager: null,
      mtl: null,

      illegal: false,
      modelFolder: null,
      f1: null,
      f2: null,
      f3: null,
      otherFolder: null,
      rootPath: null,
      modelName: null,
      showProgress: true,
      dialogVisible: false,
      percent: 0,
      tipsTitle: '上传中',
    }
  },

  async mounted() {
    if (this.root !== undefined && this.root !== null && this.root !== '') {
      this.rootPath = this.root
      this.modelFolder = this.root
      this.otherFolder = this.root
      this.f1 = this.root
      this.f2 = this.root + '/source'
      this.f3 = this.root + '/textures'
      // this.initExhibition()
      this.initLoadingManager(this.root)
      return
    }
    await generateFolder().then((res) => {
      if (res.code !== 200) return

      this.rootPath = '/' + res.data
      this.modelFolder = '/' + res.data
      this.otherFolder = '/' + res.data
      this.f1 = '/' + res.data
      this.f2 = '/' + res.data + '/source'
      this.f3 = '/' + res.data + '/textures'
      // this.initExhibition()
      this.initLoadingManager('/' + res.data)
    })
  },
  methods: {
    // TODO 初始化全部
    initExhibition(width, height) {
      width = width * 0.8
      height = width / 16 * 9
      this.initScene()
      this.initCamera(width || this.width, height || this.height)
      this.initRenderer(width || this.width, height || this.height)
      this.initControl()
      const axesHelper = new THREE.AxesHelper(50)
      this.scene.add(axesHelper)
      document.getElementById('webgl').appendChild(this.renderer.domElement)
    },

    // TODO 初始化 Renderer
    initRenderer(width, height) {
      // 初始化 Renderer
      this.renderer = new THREE.WebGLRenderer()
      // 设置画布尺寸
      this.renderer.setSize(width, height)
      this.renderer.shadowMap.enabled = true
      // 渲染
      this.renderer.render(this.scene, this.camera)
    },

    // TODO 初始化scene
    initScene() {
      // 初始化 Scene
      this.scene = new THREE.Scene()
      // 背景颜色
      this.scene.background = new THREE.Color(this.backgroundColor)

      this.initLight()
    },

    // TODO 初始化灯光
    initLight() {
      // 半球光
      this.hemisphereLight0 = new THREE.HemisphereLight(this.hemisphereLightColor, this.backgroundColor, this.hemisphereLightIntensity)
      this.hemisphereLight0.position.set(500, 500, 500)
      this.scene.add(this.hemisphereLight0)

      this.hemisphereLight1 = new THREE.HemisphereLight(this.hemisphereLightColor, this.backgroundColor, this.hemisphereLightIntensity)
      this.hemisphereLight1.position.set(-500, -500, 500)
      this.scene.add(this.hemisphereLight1)

      // 环境光
      this.ambientLight = new THREE.AmbientLight(this.ambientLightColor, this.ambientLightIntensity)
      this.scene.add(this.ambientLight)

      // 太阳光/平行光
      this.sunLight0 = this.getSunLight(0, 500, 500)
      this.sunLight1 = this.getSunLight(-500, 0, 500)
      this.sunLight2 = this.getSunLight(500, 0, 500)
      this.sunLight3 = this.getSunLight(0, -500, 500)
      this.scene.add(this.sunLight0)
      this.scene.add(this.sunLight1)
      this.scene.add(this.sunLight2)
      this.scene.add(this.sunLight3)

      // 点光源
      this.pointLight = new THREE.PointLight(this.pointLightColor, this.pointLightIntensity)
      this.pointLight.position.set(300, 300, 300)
      this.scene.add(this.pointLight)

      // const sportLight = new THREE.SpotLight(0xffffff,1)
      // sportLight.position.set(500,500,300)
      // sportLight.angle = Math.PI / 6
      // sportLight.penumbra = 0.2 //边缘羽化
      // sportLight.castShadow = true
      // this.scene.add(sportLight)
    },

    // TODO 初始化 camera
    initCamera(width, height) {
      // 透视投影相机的四个参数fov, aspect, near, far构成一个四棱台3D空间，
      // 被称为视锥体，只有视锥体之内的物体，才会渲染出来，视锥体范围之外的物体不会显示在Canvas画布上。
      this.camera = new THREE.PerspectiveCamera(25, width / height, 1, 3000)
      // 透视投影相机位置置于 200, 200, 200
      this.camera.position.set(200, 200, 200)
      // 看向坐标原点
      this.camera.lookAt(0, 0, 0)
    },

    // TODO 初始化 control
    initControl() {
      // 初始化鼠标控制事件 - 滚轮控制缩放、鼠标单击拖动调整方向
      this.controls = new OrbitControls(this.camera, this.renderer.domElement)
      this.controls.enableDamping = true
      this.controls.enableZoom = true
      this.controls.screenSpacePanning = false
      this.controls.minDistance = 1
      this.controls.maxDistance = 150
      // this.controls.maxPolarAngle = Math.PI * 2
    },

    // TODO 初始化模型加载器
    initLoader(url) {
      // TODO 如果场景中已经有模型 - 重新初始化
      if (this.object !== null) {
        this.initScene()
      }
      if (url.endsWith(".obj")) this.loader = new OBJLoader(this.loadingManager)
      else if (url.endsWith(".fbx")) this.loader = new FBXLoader(this.loadingManager)
      else if (url.endsWith(".gltf")) this.loader = new GLTFLoader(this.loadingManager)
      else {
        this.$message.error('文件格式不支持')
      }
    },

    // TODO 获取太阳光
    getSunLight (x, y, z) {
      let sunLight = new THREE.DirectionalLight(this.sunLightColor, this.sunLightIntensity)
      sunLight.visible = true
      sunLight.castShadow = true
      sunLight.shadow.camera.near = -1000 // 产生阴影的最近距离
      sunLight.shadow.camera.far = 1000 // 产生阴影的最远距离
      sunLight.shadow.camera.left = -1000 // 产生阴影距离位置的最左边位置
      sunLight.shadow.camera.right = 1000 // 最右边
      sunLight.shadow.camera.top = 1000 // 最上边
      sunLight.shadow.camera.bottom = -1000 // 最下面
      sunLight.shadow.bias = -0.01 // 用于解决阴影水波纹条纹阴影的问题
      sunLight.shadow.mapSize.set(4096, 4096) // 阴影清晰度
      sunLight.position.set(x, y, z)
      return sunLight
    },

    // TODO --------------- 以上内容为初始化各类配置 ---------------
    // TODO --------------- 以下内容为加载模型和循环渲染 ---------------

    // TODO 加载模型
    loadModel (url) {
      if (url === null || url === undefined) {
        this.$message.error('暂无模型可加载')
        return
      }
      this.initProgress('模型加载中')
      // 加载模型
      this.initLoader(url)
      this.loader.load(url, (object) => {
          if (url.endsWith(".gltf")) object = object.scene

          object.position.set(0, 0, 0)

          const box = new THREE.Box3().setFromObject(object);
          const x = (box.max.x - box.min.x)
          const y = (box.max.y - box.min.y)
          const z = (box.max.z - box.min.z)
          const maxDim = Math.max(x, y, z)
          const scale = 25 / maxDim
          object.scale.set(scale, scale, scale)

          //遍历模型字节点，获取相关参数设置
          object.traverse(function(child) {
            if (child.isMesh) {
              child.castShadow = true
              child.receiveShadow = true
              child.frustumCulled = false
              child.material.emissiveMap = child.material.map
              // child.material.shininess = 0.5

              // child.material.transparent = true // 材质允许透明 如果有玻璃材质时开启
              // child.material.opacity = 1 // 材质默认透明度
            }
          })

          this.object = object
          this.scene.add(object)
          // 可以在这里添加其他的处理代码，比如动画播放等
          this.animate()
        })

    },

    // TODO 循环渲染
    animate () {
      requestAnimationFrame(this.animate)
      this.renderer.render(this.scene, this.camera)
      this.controls.update()
    },

    // TODO 定义修改进度条的函数
    initLoadingManager(root) {
      const changePercent = (success, total) => {
        this.percent = ((Number) ((success / total).toFixed(2))) * 100
        if (success === total) {
          this.dialogVisible = false
          this.percent = 0
          this.tipsTitle = '上传中'
        }
      }

      // 创建一个LoadingManager实例
      this.loadingManager = new THREE.LoadingManager()
      // 当所有资源加载完成时的回调函数
      this.loadingManager.onLoad = function() {
        Message.Message({type: 'success', message: '加载完成' })
      }
      // 当特定资源加载完成时的回调函数
      this.loadingManager.onProgress = function(item, loaded, total) {
        console.log('资源加载进度: ' + loaded + ' / ' + total)
        changePercent(loaded, total)
      }

      // 加载失败时
      this.loadingManager.onError = function (url, error) {
        const split = url.split(root)
        // console.error('There was an error loading ' + url, error);
        Notification.Notification({
          title: '加载失败',
          message: `${split[1]}`,
          duration: 30000,
          type: 'warning',
          position: 'top-left'
        })
      }
    },

    // TODO --------------- 以上内容为加载模型和循环渲染 ---------------
    // TODO --------------- 以下内容为文件上传相关 ---------------

    // TODO 检查并上传模型
    async checkAndUpload (e) {
      this.showProgress = true
      let file = e.target.files[0]
      const filename = file.name;
      const isZip = filename.endsWith('.zip') || filename.endsWith('.rar')
      const isModel = this.isModel(filename)
      const isLt100M = file.size / 1024 / 1024 < 500

      if (!isZip && !isModel) {
        this.$message.error('请上传模型文件')
        return
      }
      if (isZip) {
        this.$message.info('tips: 上传的是压缩文件, 模型的相对位置选择将无效哦!')
      }
      if (!isLt100M) {
        this.$message.error('上传模型大小不能超过 500MB!')
        return
      }

      let fileParam = await this.$refs.huge.upload(file)
      if (fileParam === null) {
        this.$message.error('上传失败, 请重新上传')
        return
      }
      const promise = merge(fileParam, isZip, true, this.modelFolder)
      promise.then((res) => {
        if (res.code !== 200) return
        let modelName = isZip ? res.data[1] : res.data
        if (!this.isModel(modelName)) {
          this.$message.error('未发现所支持的格式(.obj/.fbx/.gltf)')
          return
        }

        if (isZip) {
          this.rootPath = res.data[0]
          this.modelFolder = res.data[0]
          this.otherFolder = res.data[0]
          this.f1 = res.data[0]
          this.f2 = res.data[0] + '/source'
          this.f3 = res.data[0] + '/textures'
          // this.initExhibition()
          this.initLoadingManager(res.data[0])
        }

        if (modelName.endsWith('.gltf')) {
          this.$message.info('.gltf格式的模型需要在其它文件上传中补充上传.bin文件哦')
        }

        this.$emit('handleChange', [isZip ? res.data[0] : this.rootPath, modelName])
        this.modelName = modelName
        this.loadModel(modelName)
      })
      document.getElementById('model').value  = ''
    },

    // TODO 上传其它贴图等文件
    async uploadOther (e) {
      let files = e.target.files
      const length = files.length
      this.showProgress = length === 1

      this.dialogVisible = !this.showProgress
      this.initProgress('上传中')

      let fileToMerge = []
      for(let i = 0; i < length; i++) {
        let file = files[i]
        let isLt100M = file.size / 1024 / 1024 < 100 || (file.name.endsWith('.bin'))
        if (!isLt100M) {
          this.$message.error(`上传附件大小不能超过 50MB!, ${file.name}`)
          continue
        }
        let fileParam = await this.$refs.huge.upload(file)
        if (fileParam === null) {
          this.$message.error(`${file.name}上传失败, 请重新上传`)
          continue
        }
        fileToMerge.push(fileParam)
        this.calPercent(i + 1, 2 * length)
      }

      for (let i = 0; i < length; i++) {
        let param = fileToMerge[i]
        await merge(param, false, false, this.otherFolder).then((res) => {
          if (res.code === 200 && this.showProgress) this.$message.success(res.msg)
          if (res.code === 201) {
            this.$notify({
              title: '提示',
              message: `${param.originalFilename}上传失败`,
              duration: 0
            })
          }
        })
        this.calPercent(i + 1 + length, 2 * length)
      }
      this.dialogVisible = false

      this.loadModel(this.modelName)
      document.getElementById('folderInput').value = ''
    },

    // TODO 判断是否为模型文件
    isModel(filename) {
      return filename.endsWith('.obj') || filename.endsWith('.fbx') || filename.endsWith('.gltf')
    },

    // TODO 初始化进度条
    initProgress(title) {
      this.percent = 0
      this.tipsTitle = title
      this.dialogVisible = true
    },

    // TODO 计算进度
    calPercent(item, total) {
      this.percent = (item / total).toFixed(2) * 100
    },

    // TODO --------------- 以上内容为文件上传相关 ---------------
    // TODO --------------- 以下内容为各类光线配置改变相关的函数 ---------------

    // TODO 背景颜色改变
    backgroundColorChange (value) {
      this.scene.background = new THREE.Color(value)
      this.renderer.render(this.scene, this.camera)
      this.hemisphereLight0.groundColor = new THREE.Color(value)
      this.hemisphereLight1.groundColor = new THREE.Color(value)

      // TODO 后期更新通知父组件 - 修改表单值
    },

    // TODO 太阳光颜色改变
    sunLightColorChange (value) {
      this.sunLight0.color = new THREE.Color(value)
      this.sunLight1.color = new THREE.Color(value)
      this.sunLight2.color = new THREE.Color(value)
      this.sunLight3.color = new THREE.Color(value)

      // TODO 后期更新通知父组件 - 修改表单值
    },
    // TODO 太阳光光强改变
    sunLightIntensityChange (value) {
      this.sunLight0.intensity = value
      this.sunLight1.intensity = value
      this.sunLight2.intensity = value
      this.sunLight3.intensity = value

      // TODO 后期更新通知父组件 - 修改表单值
    },

    // TODO 点光源颜色改变
    pointLightColorChange (value) {
      this.pointLight.color = new THREE.Color(value)

      // TODO 后期更新通知父组件 - 修改表单值
    },
    // TODO 点光源光强改变
    pointLightIntensityChange (value) {
      this.pointLight.intensity = value

      // TODO 后期更新通知父组件 - 修改表单值
    },


    // TODO 环境光颜色改变
    ambientLightColorChange (value) {
      this.ambientLight.color = new THREE.Color(value)

      // TODO 后期更新通知父组件 - 修改表单值
    },
    // TODO 环境光光强改变
    ambientLightIntensityChange (value) {
      this.ambientLight.intensity = value

      // TODO 后期更新通知父组件 - 修改表单值
    },

    // TODO 环境光颜色改变
    hemisphereLightColorChange (value) {
      this.hemisphereLight0.color = new THREE.Color(value)
      this.hemisphereLight1.color = new THREE.Color(value)

      // TODO 后期更新通知父组件 - 修改表单值
    },
    // TODO 半球光 光强改变
    hemisphereLightIntensityChange (value) {
      this.hemisphereLight0.intensity = value
      this.hemisphereLight1.intensity = value

      // TODO 后期更新通知父组件 - 修改表单值
    }
  }
}
</script>

<style scoped>
.uploadButton::file-selector-button{
  padding: 6px 10px;
  background-color: #1E9FFF;
  border: 1px solid #1E9FFF;
  border-radius: 3px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
}

.uploadButton {
  width: 160px;
}


#fileInput {
  display: none;
}
#folderInput {
  display: none;
}
.custom-file-upload {
  color: #fff;
  display: inline-block;
  cursor: pointer;
  padding: 6px 10px;
  border: 1px solid #1E9FFF;
  border-radius: 3px;

  background: #1E9FFF;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  text-align: center;
  font-size: 12px;
  margin: 5px;
}

.custom-file-upload span {
  margin-right: 8px;
}

.config {
  display: flex;
  justify-content: center;
  align-items: center
}
</style>
