<template>
  <div>
    <div v-if="step === 1" style="display: grid; justify-content: center; align-items: center;">
      <br>
      <el-form style="width: 300px;">
        <el-form-item label="邮箱">
          <el-input placeholder="Your Email" v-model.trim="findPswForm.email"></el-input>
        </el-form-item>

        <el-form-item>
          <label>图片验证码</label>
          <div style="display: flex; justify-content: space-between;">
            <img id="app-findPsw"
                 @click="changeVerify('app-findPsw')"
                 src="/api/verify/app-findPsw/100/32/4">
            <el-input v-model.trim="findPswForm.verify" style="margin-left: 15px;" placeholder="Verification Code">
              <el-button :disabled="sendCodeDisable" size="mini" slot="append" icon="el-icon-message" @click="sendCode"></el-button>
            </el-input>
          </div>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="step = 2" :disabled="nextDisable">下一步 | Next</el-button>
    </div>

    <div v-if="step === 2" style="display: grid; justify-content: center; align-items: center;">
      <el-form style="width: 300px;">
        <el-form-item label="邮箱">
          <el-input disabled v-model.trim="findPswForm.email"></el-input>
        </el-form-item>

        <el-form-item label="邮箱验证码">
          <el-input placeholder="Email Code" v-model.trim="findPswForm.emailCode"></el-input>
        </el-form-item>

        <el-form-item label="新密码">
          <el-input placeholder="New Password" type="password" v-model.trim="findPswForm.newPassword" show-password></el-input>
        </el-form-item>
      </el-form>
      <div style="display: flex; justify-content: space-between; align-items: center">
        <el-button type="primary" @click="step = 1">上一步 | Pre</el-button>
        <el-button :disabled="applyDisable" type="primary" @click="findPassword">应用变更 | Apply</el-button>
      </div>
    </div>

  </div>

</template>

<script>
import { findPsw, sendEmailFindPsw } from "@/api/login";

export default {
  name: "ResetPsw",
  data() {
    return {
      findPswForm: {
        email: '',
        newPassword: '',
        emailCode: '',
        verify: ''
      },
      step: 1,
      nextDisable: true
    }
  },
  methods: {
    // TODO 重新获取验证码
    changeVerify (id) {
      console.log(id)
      const verify = window.document.getElementById(id)
      verify.src = `/api/verify/${id}/100/32/4?time=` + new Date().getMilliseconds()
    },

    // TODO 找回密码
    findPassword () {
      // TODO 参数校验
      const newPassword = this.findPswForm.newPassword;
      if (newPassword.length < 6) {
        this.$message.error("新密码长度不能少于6位")
        return
      }
      // TODO 发送请求 - 找回密码
      findPsw(this.findPswForm).then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
        this.findPswForm.newPassword = ''
        this.findPswForm.email = ''
        this.findPswForm.emailCode = ''
        this.findPswForm.verify = ''
        this.changeVerify()
      }).catch((error) => {
        console.log(error)
      })
    },

    // TODO 发送验证码
    sendCode () {
      const promise = sendEmailFindPsw(this.findPswForm.email, this.findPswForm.verify);
      promise.then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
        this.nextDisable = false
        //
      }).catch((error) => {
        console.log(error)
      })
    },
  },

  computed: {
    sendCodeDisable () {
      // return false
      return this.findPswForm.email === '' || this.findPswForm.verify === ''
    },
    applyDisable() {
      return this.findPswForm.emailCode === '' || this.findPswForm.newPassword === '' || this.findPswForm.email === ''
    }
  }
}
</script>

<style scoped>

</style>
