import request from '@/utils/request'

// TODO 获取当前商品是否在各个收藏夹中
export function favouriteStatus (modelId) {
    return request({
        url: `/app/favourite/${modelId}`,
        method: 'get'
    })
}

// TODO 添加收藏
export function addFavourite (add, del, modelId) {
    return request({
        url: '/app/favourite',
        method: 'post',
        data: {
            add: add,
            del: del,
            modelId: modelId
        }
    })
}

// TODO 点赞
export function like (modelId) {
    return request({
        url: `/app/model/${modelId}`,
        method: 'post'
    })
}


// TODO 获取累计数据
export function statisticsByAction() {
    return request({
        url: '/app/action',
        method: 'get'
    })
}

// TODO 根据 用户id查询/关键词查询
export function getMySet (page, pageSize, userId, orderBy, desc) {
    let url = `/app/model/${page}/${pageSize}/${desc}/${orderBy}/0?userId=${userId}`
    return request({
        url: url,
        method: 'get'
    })
}

// TODO 获取近7日数据
export function statisticsByWeek(type) {
    return request({
        url: `/app/action/${type}`,
        method: 'get'
    })
}

// TODO 获取收入统计
export function incomeList() {
    return request({
        url: '/app/action/income',
        method: 'get'
    })
}
