<template>
  <div style="margin-top: 20px;">
    <el-tabs v-model="activeName" @tab-click="" type="card">
      <el-tab-pane label="修改密码" name="first">
        <SetPsw></SetPsw>
      </el-tab-pane>

      <el-tab-pane label="设置密码" name="second">
        <ResetPsw></ResetPsw>
      </el-tab-pane>

      <el-tab-pane label="修改邮箱" name="third">
        <SetEmail></SetEmail>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ResetPsw from '@/views/self/safe/resetPsw'
import SetPsw from '@/views/self/safe/setPsw'
import SetEmail from '@/views/self/safe/setEmail'
export default {
  name: "safetyPage",
  components: {
    ResetPsw,
    SetPsw,
    SetEmail
  },
  data() {
    return {
      activeName: 'first'
    }
  },
  methods: {
  },
  mounted() {
    let activeName = this.$route.query.activeName
    if (activeName) {
      for (let active of ['first', 'second', 'third']) {
        if (active !== activeName) continue
        this.activeName = active
        break
      }
    }
  }
}
</script>

<style scoped>

</style>
