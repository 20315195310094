<template>
  <div style="margin-left: 16px">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="购入" name="first">
        <purchase></purchase>
      </el-tab-pane>
      <el-tab-pane label="售出" name="second">
        <sell-out></sell-out>
      </el-tab-pane>
<!--      <el-tab-pane label="退款申请" name="third">-->
<!--        <p>退款申请 - 开发中</p>-->
<!--        <p>退款申请 - 开发中</p>-->
<!--        <p>退款申请 - 开发中</p>-->
<!--        <p>退款申请 - 开发中</p>-->
<!--      </el-tab-pane>-->
    </el-tabs>
  </div>
</template>

<script>
import purchase from '@/views/self/order/purchase'
import SellOut from '@/views/self/order/SellOut'
export default {
  name: 'orderPage',
  components: {
    purchase,
    SellOut
  },
  data() {
    return {
      activeName: 'first'
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    }
  }
}
</script>

<style scoped>

</style>
