<template>
  <div>
    <el-row :gutter="20">
      <el-row>
        <div class="grid-total">
          <el-col :span="8">
            <div class="grid-left bg" @click="$router.push('/main')">
              <div class="logo">3D Mall, More 3D</div>
            </div>
          </el-col>
          <el-col :span="16">
            <div class="grid-right bg">
              <table>
                <tr class="header">
                  <td class="header-item" style="width: 100px;" v-if="token !== null">
                  {{ $store.getters['user/info'].username }}, 你好!
                    <br>

                </td>
                <td class="header-item" v-if="token === null"> <router-link to="/login" class="header-link">注册/登录</router-link></td>
                <td class="header-item" v-else>
                  <el-avatar :size="50"  @click.native="$router.push('/userInfo')">
                    <img v-if="$store.getters['user/info'].icon" :src="$store.getters['user/info'].icon"/>
                    <div v-else> user </div>
                  </el-avatar>
                </td>
                <td class="header-item">
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      <i class="el-icon-menu"></i>
                      菜单
                      <i class="el-icon-arrow-down el-icon--left"/>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item icon="el-icon-user" @click.native="$router.push('/userInfo')">个人信息</el-dropdown-item>
                      <el-dropdown-item icon="el-icon-lock" @click.native="$router.push('/safety')">账号安全</el-dropdown-item>
                      <el-dropdown-item icon="el-icon-collection" @click.native="$router.push('/modelSet')">我的作品</el-dropdown-item>
                      <el-dropdown-item icon="el-icon-s-order" @click.native="$router.push('/orders')">我的订单</el-dropdown-item>
                      <el-dropdown-item icon="el-icon-document" @click.native="$router.push('/feedback')">意见反馈</el-dropdown-item>
                      <el-dropdown-item icon="el-icon-switch-button" @click.native="logout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </td>
                <td class="header-item" @click="$router.push('/message')">
<!--                  <i class="el-icon-chat-round"/>-->
                  <el-badge :value="$store.getters['notice/getNotRead']" :max="99" class="item">
                    <a href="#" @click.prevent><i class="el-icon-bell" style="font-size: 20px"></i></a>
<!--                    <el-button title="消息" icon="el-icon-chat-line-round" size="small"></el-button>-->
                  </el-badge>
                </td>
                <td class="header-item" @click="$router.push('/cart')">
                  <el-badge :value="0 || $store.getters['cart/list'].length" :max="99" class="item">
                    <a href="#" @click.prevent><i class="el-icon-shopping-cart-2" style="font-size: 24px"></i></a>
<!--                    <el-button title="购物车" icon="el-icon-shopping-cart-2" type="danger" size="small"></el-button>-->
                  </el-badge>
                </td>
                <td class="header-item">
                  <a style="text-decoration: none; display: flex;align-items: center" href="#" @click.prevent="$router.push('/star')">收藏<i class="el-icon-star-off" style="font-size: 24px"></i></a>
<!--                  <el-button type="primary" icon="el-icon-star-off" size="mini" @click.native="$router.push('/star')">收藏</el-button>-->
                </td>
                <td class="header-item">
                  <a style="text-decoration: none; display: flex;align-items: center" href="#" @click.prevent="$router.push('/upload')">上传<i class="el-icon-upload el-icon--left" style="font-size: 24px"></i></a>
<!--                  <el-button @click="$router.push('/upload')" type="primary" size="mini">上传<i class="el-icon-upload el-icon&#45;&#45;right"></i></el-button>-->
                </td>
<!--                <td><a href="#" style="font-size: 12px; margin-right: 5px">English | 中文</a></td>-->
              </tr>
            </table>
          </div>
        </el-col>
        </div>
      </el-row>
    </el-row>
    <router-view></router-view>
  </div>
</template>

<script>
import {getToken, removeToken, removeUser} from "@/utils/storage";
import { mapActions } from 'vuex'
import {numberOfNotice} from "@/api/notice";
import {logout} from "@/api/login";
export default {
  name: "homePage",
  methods: {
    ...mapActions('user', ['userInfoAction']),
    logout() {
      logout().then((res) => {
        if (res.code !== 200) return
        removeUser()
        removeToken()
        location.reload()
      })
    }
  },
  computed: {
    token () {
      return getToken()
    }
  },
  mounted() {
    if (getToken() !== null) {
      this.userInfoAction()
      this.$store.dispatch('cart/getList')
      this.$store.dispatch('notice/getNum')
    }
  },
  data () {
    return {
      length: 33,
      notRead: 0
    }
  }
}
</script>

<style scoped>
.bg {
  background: white;
}
.grid-total {
  border: 1px lightskyblue solid;
  height: 66px;
}

.grid-right {
  /*border: 1px lightskyblue solid;*/
  /*border-radius: 4px;*/
  height: 66px;
  display: flex;
  justify-content: right;
  align-items: center;
}
.grid-left {
  /*border: 1px lightskyblue solid;*/
  /*border-radius: 4px;*/
  height: 66px;
  display: flex;
  justify-content: left;
  align-items: center;
}
.header {
  font-size: small;
  font-family: 楷体, fangsong;
  font-weight: bold;
}

.header-item {
  width: 80px;
  text-align: center;
  text-decoration: none;
}

.header-link {
  text-decoration: none;
}

.logo {
  margin-left: 20px;
  font-family: "Ink Free", serif;
  font-weight: bold;
  color: blueviolet;
  font-size: 20px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
</style>
