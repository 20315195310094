<template>
  <div>
    <!--  筛选框  -->
    <div style="margin-left: 33px; margin-top: 23px">
      <el-radio-group v-model="read" @change="statusChange">
        <el-radio-button :label="null">全部</el-radio-button>
        <el-radio-button :label="false">未读</el-radio-button>
        <el-radio-button :label="true">已读</el-radio-button>
      </el-radio-group>
      <el-radio-group v-model="desc" style="margin-left: 33px" @change="getNotice">
        <el-radio-button :label="1">最新</el-radio-button>
        <el-radio-button :label="0">最早</el-radio-button>
      </el-radio-group>
    </div>

    <div id="tableDiv" style="width: 100%; height: 75vh">
      <el-table :data="noticeList" style="width: 100%; " :row-class-name="tableRowClassName">
        <el-table-column prop="title" label="消息类型" width="180"></el-table-column>
        <el-table-column prop="content" label="消息内容" width="300" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createTime" label="消息时间" width="200"></el-table-column>
        <el-table-column label="状态" width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.isRead">已读</div>
            <div v-else>未读</div>
          </template>
        </el-table-column>
        <el-table-column fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="$refs.notice.openWithTittle(scope.row.id, scope.row.content, scope.row.title)">查看</el-button>
            <el-button type="text" :disabled="!scope.row.isRead" @click="mark(scope.row.id, false)">标记未读</el-button>
            <el-button type="text" style="color: red" @click="$refs.delete.open(scope.row.id, '确定要删除吗')">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--  分页条  -->
    <div style="display: flex; justify-content: center; align-items: center">
      <el-pagination
          @size-change="sizeChange"
          @current-change="getNotice"
          :current-page.sync="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="pageSize"
          layout="total, sizes, prev, pager, next"
          :total="$store.getters['notice/getShow']">
      </el-pagination>
    </div>
<!--    -->
    <TipsDialog ref="notice" @handleConfirm="confirmNotice"></TipsDialog>
    <TipsDialog ref="delete" @handleConfirm="confirmDelete"></TipsDialog>
  </div>
</template>

<script>
import { deleteNotice, listOfNotice, markNotice } from '@/api/notice'
import TipsDialog from '@/components/TipsDialog'
export default {
  name: 'index',
  components: {
    TipsDialog
  },
  data () {
    return {
      totalNotice: 0,
      noticeList: [],
      page: 1,
      pageSize: 10,
      desc: 1,
      read: null,
      height: 0
    }
  },
  created() {

  },
  mounted() {
    this.getNotice()
  },
  watch: {
    read: {
      handler(newValue) {
        this.$store.commit('notice/setShow', newValue)
      }
    }
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (row.isRead === true) {
        return 'success-row'
      } else  {
        return 'warning-row'
      }
    },
    getNotice () {
      const promise = listOfNotice(this.page, this.pageSize, this.desc, this.read);
      promise.then((res) => {
        if (res.code !== 200) return
        this.noticeList = res.data
      })
    },
    sizeChange (e) {
      this.page = 1
      this.getNotice()
    },
    statusChange (e) {
      this.page = 1
      this.getNotice()
    },

    // TODO 确定消息弹窗
    confirmNotice (id) {
      for (let notice of this.noticeList) {
        if (notice.id !== id) continue
        if (notice.isRead === true) break
        this.mark(id, true)
      }
    },
    // TODO 标记函数
    mark (id, read) {
      const promise = markNotice(id, read);
      promise.then((res) => {
        if (res.code !== 200) return
        this.$store.dispatch('notice/getNum')
        this.getNotice()
      })
    },
    confirmDelete (id) {
      const promise = deleteNotice(id);
      promise.then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
        this.getNotice()
        this.$store.dispatch('notice/getNum')
      })
    }
  }
}
</script>

<style scoped>
:deep(.el-table .warning-row) {
  background: lightyellow;
}

:deep(.el-table .success-row) {
  background: white;
}
</style>
