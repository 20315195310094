import request from '@/utils/request'

// TODO 请求支付
export function payRequest (payId) {
    return request({
        url: `/app/pay/ali/pay?payId=${payId}`,
        method: 'get'
    })
}


// TODO 退款
export function refund (payId, orderId) {
    return request({
        url: `/app/pay/ali/refund?payId=${payId}&orderId=${orderId}`,
        method: 'post'
    })
}

// TODO 检测订单状态
export function checkPayStatus (payId) {
    return request({
        url: `/app/pay/ali/check?payId=${payId}`,
        method: 'get'
    })
}
