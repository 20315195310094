import Vuex from 'vuex'
import Vue from 'vue'
import { getUserInfo } from '@/api/user'
import { getUser, setUser } from '@/utils/storage'

Vue.use(Vuex)

export default {
    namespaced: true,
    state () {
        return {
            userInfo: {
                username: '游客'
            }
        }
    },
    getters: {
        info (state) {
            // const user = JSON.parse(getUser())
            // store.commit('user/setUserInfo', user)
            return state.userInfo
        }
    },
    mutations: {
        setUserInfo (state, userInfo) {
            // setUser(JSON.stringify(userInfo))
            // state.userInfo = userInfo
            Vue.set(state.userInfo, 'username', userInfo.username)
            Vue.set(state.userInfo, 'icon', userInfo.icon)
            Vue.set(state.userInfo, 'message', userInfo.message)
            Vue.set(state.userInfo, 'gender', userInfo.gender)
            Vue.set(state.userInfo, 'birthday', userInfo.birthday)
            Vue.set(state.userInfo, 'email', userInfo.email)
            Vue.set(state.userInfo, 'id', userInfo.id)
            Vue.set(state.userInfo, 'createTime', userInfo.createTime)
            // Vue.set(state.userInfo, 'username', userInfo.username)
        },
        setEmail(state, email) {
            Vue.set(state.userInfo, 'email', email)
            setUser(JSON.stringify(state.userInfo))
        }
    },
    actions: {
        async userInfoAction (context) {
            if (getUser() === null || getUser() === undefined) {
                await getUserInfo().then((res) => {
                    setUser(JSON.stringify(res.data))
                    context.commit('setUserInfo', res.data)
                })
            }
            else context.commit('setUserInfo', JSON.parse(getUser()))
        }
    },
    modules: {
    }
}
