<template>
<!--  <div class="main-box">-->

<!--      <div style="display: flex; justify-content: space-around; align-items: center; margin-top: 20px; margin-bottom: 20px">-->
<!--        <el-button type="primary" @click="saveInfo">保存</el-button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

  <div style="width: 100%; display: flex; justify-content: center; align-items: center">
    <UserProfile v-if="step === 1" :step.sync="step" :user-info="userInfo"></UserProfile>
    <EditProfile ref="edit" v-else :step.sync="step" @handleSuccess="handleUpdateSuccess"></EditProfile>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { setUserInfo } from '@/api/user'
import ImageCropper from '@/components/ImageCropper'
import UserProfile from '@/views/self/userInfo/profile'
import EditProfile from '@/views/self/userInfo/EditProfile'
import {removeUser} from "@/utils/storage";

export default {
  name: 'userInfo',
  components: {
    ImageCropper,
    EditProfile,
    UserProfile
  },
  data () {
    return {
      userInfo: {
        username: null,
        message: null,
        icon: null,
        gender: null,
        birthday: null,
        email: null,
        id: null
      },
      check: false,
      step: 1
    }
  },
  async mounted() {
    await this.userInfoAction()
    this.userInfo = JSON.parse(JSON.stringify(this.$store.getters['user/info']))
  },
  methods: {
    ...mapActions('user', ['userInfoAction']),
    // 上传图片之前检查类型
    beforeAvatarUpload (file) {
      const type = file.type;
      console.log(type)
      const isPhoto = type === 'image/jpeg' || type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5

      if (!isPhoto) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!')
      }
      this.check = isPhoto && isLt5M
    },
    handleChange(param) {
      this.userInfo.icon = param
    },
    saveInfo () {
      setUserInfo(this.userInfo).then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
        this.$store.commit('user/setUserInfo', res.data)
      })
    },
    openCropper() {
      this.$refs.myCropper.dialogVisible = true
    },

    change() {
      this.$message.info('充值功能开发中')
    },
    async handleUpdateSuccess () {
      await removeUser()
      // window.location.reload()
      await this.userInfoAction()
      this.userInfo = JSON.parse(JSON.stringify(this.$store.getters['user/info']))
      this.step = 1
    }
  }
}
</script>

<style scoped>
:deep(.avatar-uploader .el-upload) {
  /*border: 1px dashed #d9d9d9;*/
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
:deep(.avatar-uploader .el-upload:hover) {
  border-color: #409EFF;
}
</style>
