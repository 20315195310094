<template>
  <div>
    <el-row>
      <!--   左边   -->
      <el-col :span="12">
        <div id="income" style="width: 100%; height: 100%;display: flex; justify-content: space-between; align-items: center">
          <div style="width: 33.3%; height: 160px;" class="innerCenter" v-for="index in (3)" :key="index">
            <div>
              <div class="innerCenter">
                <i :class="incomeIcon[index - 1]" style="font-size: 36px"/>
              </div>
              <div class="innerCenter">
                {{ incomeTips[index - 1] }} : {{ incomeValue[index - 1] }}
              </div>
            </div>
          </div>
        </div>
        <el-collapse v-model="activeIndex" accordion @change="handleChange(checkList)">

          <el-collapse-item title="全项数据雷达图" name="1" style="width: 100%">
            <RadarAnalysis ref="radar" id="radar"></RadarAnalysis>
          </el-collapse-item>

          <el-collapse-item title="分项数据统计" name="2" style="width: 100%">
            <el-radio-group v-model="radio" @change="drawPie(checkList)">
              <el-radio v-for="item in $store.getters['staticStore/analysisList']" :label="item.id" :key="item.id">
                {{ item.label }}
              </el-radio>
            </el-radio-group>
            <PieAnalysis ref="pie" id="pie"></PieAnalysis>
          </el-collapse-item>

          <el-collapse-item title="近7天数据" name="3" style="width: 100%">
            <el-radio-group v-model="radio4Week" @change="handleChange(checkList)">
              <el-radio v-for="item in $store.getters['staticStore/analysisList']" :label="item.id" :key="item.id">
                {{ item.label }}
              </el-radio>
            </el-radio-group>
            <LineAnalysis ref="line" id="line"></LineAnalysis>
          </el-collapse-item>
        </el-collapse>
      </el-col>

      <!--   右边   -->
      <el-col :span="12">
        <div style="margin-left: 10px; font-weight: bold; display: flex; justify-content: flex-start; align-items: center">
          我的作品
          <el-select v-model="orderFilter" placeholder="请选择" style="width: 160px; margin-left: 20px" @change="reGet">
            <el-option v-for="item in $store.getters['staticStore/orderList']"
                :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>

          <el-radio-group v-model="descFilter" size="small" @change="reGet" style="margin-left: 20px">
            <el-radio-button :label="1">降序</el-radio-button>
            <el-radio-button :label="0">升序</el-radio-button>
          </el-radio-group>
        </div>
        <br>
        <el-checkbox-group v-model="checkList" @change="handleChange" :max="5">
          <ul class="infinite-list" style="overflow: auto; max-height: 600px;">
            <li style="display: flex; justify-content: left; align-items: center;">
              <el-avatar shape="square" style="width: 160px; height: 90px">
                <i class="el-icon-document-checked" style="font-size: 60px"/>
              </el-avatar>
              <el-checkbox style="margin-left: 20px" :label="null">全部作品</el-checkbox>
            </li>
            <li v-for="item in modelSet" style="display: flex; justify-content: left; align-items: center;" :key="item.id">
              <img :src="item.cover" style="width: 160px; height: 90px">
              <el-checkbox style="margin-left: 20px" :label="item.id">{{ item.title }}</el-checkbox>
            </li>
          </ul>
        </el-checkbox-group>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import RadarAnalysis from '@/views/self/analysis/radarAnalysis'
import PieAnalysis from '@/views/self/analysis/pieAnalysis'
import {getMySet, incomeList, statisticsByAction, statisticsByWeek} from '@/api/userAction'
import LineAnalysis from '@/views/self/analysis/lineAnalysis'

export default {
  name: 'dataAnalysis',
  components: {
    PieAnalysis,
    RadarAnalysis,
    LineAnalysis
  },
  data() {
    return {
      activeAction: 'view',
      activeIndex: '1',
      // TODO 被选中的列表
      checkList: [],
      // TODO 模型列表
      modelSet: [],
      modelAnalysis: [],
      radio: 0,
      radio4Week: 0,
      lineAnalysis: [[], [], [], [], [], []],
      orderFilter: 0,
      descFilter: 1,
      incomeIcon: ['el-icon-bank-card', 'el-icon-money', 'el-icon-coin'],
      incomeTips: ['累计收入', '昨日收入', '今日收入'],
      incomeValue: [0.0, 0.0, 0.0]
    }
  },
  methods: {
    // TODO 切换行时响应
    async handleChange(param) {
      if (this.activeIndex === '1') {
        this.drawRadar(param)
      } else if (this.activeIndex === '2') {
        this.drawPie(param)
      } else {
        await this.getLineData()
        this.drawLine(param)
      }
    },

    // TODO 获取模型列表
    async getModels(page, pageSize) {
      // let success = false
      // this.$message.info('数据加载中')
      const promise = getMySet(page, pageSize, this.$store.getters["user/info"].id, this.orderFilter, this.descFilter)
      await promise.then(res => {
        if (res.code !== 200) return
        res.data.forEach(model => this.modelSet.push(model))
        if (res.data.length === pageSize) {
          this.getModels(page + 1, pageSize)
        } else {
          this.getRadarPieData()
        }
      })
    },

    // TODO 绘制雷达图
    drawRadar(param) {
      // console.log(param)
      // console.log(this.modelAnalysis)
      let seriesData = [], legendData = []
      param.forEach(id => {
        for(let analysis of this.modelAnalysis) {
          if (analysis.id !== id) continue
          seriesData.push(analysis)
          legendData.push(analysis.name)
          break
        }
      })
      // console.log(seriesData)
      this.$refs.radar.reDraw(legendData, seriesData)
    },

    // TODO 绘制饼状图
    drawPie(param) {
      let seriesData = []
      param.forEach(id => {
        if (id === null) return
        for (let analysis of this.modelAnalysis) {
          if (analysis.id !== id) continue
          let series = { name: analysis.name, id: analysis.id, value: analysis.value[this.radio]}
          seriesData.push(series)
          break
        }
      })
      this.$refs.pie.reDraw(seriesData,
        this.$store.getters["staticStore/analysisList"][this.radio].label)
    },

    // TODO 绘制折线统计图
    drawLine(param) {
      let seriesData = [], legend = []
      param.forEach(id => {
        for(let analysis of this.lineAnalysis[this.radio4Week]){
          if (analysis.id !== id) continue
          seriesData.push(analysis)
          legend.push(analysis.name)
          break
        }
      })
      // TODO 绘制折线图
      // console.log(seriesData)
      this.$refs.line.reDraw(legend, seriesData)
    },

    // TODO 获取雷达图和饼状图的数据
    async getRadarPieData() {
      let analysis = []
      await statisticsByAction().then((res) => {
        if (res.code !== 200) return
        // console.log(res.data)
        res.data.forEach(r => analysis.push(r))
      })

      for(let ana of analysis) {
        if (ana.id === null) {
          ana.name = '全部作品'
          this.modelAnalysis.push(ana)
          continue
        }
        for (let model of this.modelSet) {
          if (model.id !== ana.id) continue
          ana.name = model.title
          this.modelAnalysis.push(ana)
          break
        }
      }

      // this.$message.success('数据加载完成')
    },

    // TODO 获取折线统计图的数据
    async getLineData() {
      if (this.lineAnalysis[this.radio4Week].length !== 0) return
      let lineAnalysis = []
      await statisticsByWeek(this.radio4Week).then((res) => {
        if (res.code !== 200) return
        // console.log(res.data)
        res.data.forEach(item => lineAnalysis.push(item))
      })

      for (let item of lineAnalysis) {
        for(let model of this.modelSet) {
          if (model.id !== item.id) continue
          let analysis = { id: item.id, data: item.value, name: model.title, type: 'line' }
          this.lineAnalysis[this.radio4Week].push(analysis)
          break
        }
        if (item.id === null) {
          let analysis = { id: null, data: item.value, name: '全部作品', type: 'line' }
          this.lineAnalysis[this.radio4Week].push(analysis)
        }
      }
    },

    async reGet() {
      this.modelSet = []
      await this.getModels(1, 20)
    },

    getIncome () {
      const promise = incomeList()
      promise.then((res) => {
        if (res.code !== 200) return
        this.incomeValue = res.data
      })
    }
  },
  async mounted() {
    await this.$store.dispatch('user/userInfoAction')
    await this.getModels(1, 20)
    // await this.getRadarPieData()
    const radar = document.getElementById('radar')
    const width = radar.clientWidth
    const height = radar.clientHeight
    this.$refs.line.initLine(width, height)
    this.$refs.pie.initPie(width, height)

    this.getIncome()

    const incomeEle = document.getElementById('income')
    const incomeWidth = incomeEle.offsetWidth
    incomeEle.style.height = (incomeWidth / 3) + 'px'
  }
}
</script>

<style scoped>
.innerCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
