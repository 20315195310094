<template>
  <div>
    <el-dialog title="文件上传中" :visible.sync="dialogVisible" width="30%">
      <el-progress :text-inside="true" :stroke-width="26" :percentage="percent"></el-progress>
    </el-dialog>
  </div>
</template>

<script>
import SparkMD5 from 'spark-md5'
import {breakPointUpload, checkUpload} from "@/api/file";

export default {
  name: 'HugeUpload',
  props: {
    showProgress: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      chunkSize: 2 * 1024 * 1024,
      sizeThreshold: 10 * 1024 *1024,
      chunks: 0,
      percent: 0,
      dialogVisible: false
    }
  },
  mounted() {
  },

  methods: {
    // TODO 更新进度条
    updatePercentage(success, total) {
      if (success >= total) this.percent = 100
      else this.percent = success === total ? 100 : (success / total).toFixed(2) * 100
    },

    // TODO 转换时间单位
    convert(timeValue) {
      // TODO 毫秒 => 秒
      timeValue /= 1000
      if (timeValue < 60) return timeValue.toFixed(2) + '秒'
      let minute = (timeValue - timeValue % 60) / 60
      let second = (timeValue % 60).toFixed(2)
      if (minute < 60) return minute + '分钟' + second + '秒'
      let hour = (minute - minute % 60) / 60
      minute %= 60
      if (hour < 24) return hour + "小时" +  minute + '分钟' + second + '秒'
      let day = (hour - hour % 24) / 24
      hour %= 24
      return day + "天" +  hour + "小时" +  minute + '分钟' + second + '秒'
    },

    // TODO 重置文件
    reset() {
      this.chunks = 0
      this.percent = 0
    },

    // TODO 上传 - 默认传进来之前已经做了各方面(文件类型、文件大小)的检查
    async upload(file) {
      if (!file) return null
      const filename = file.name
      const fileSize = file.size

      // TODO 记录开始时间
      let startTime = new Date().getTime()

      // TODO 获取文件md5
      this.chunks = Math.ceil(fileSize / this.chunkSize)
      let MD5
      if (fileSize < this.sizeThreshold) {
        MD5 = await this.getFileMd5Mini(file)
      } else {
        MD5 = await this.getFileMD5(file)
      }

      // TODO 上传结果 - 带着这组数据合并则可以直接保存到minio
      let result = {
        originalFilename: filename,
        identification: MD5,
        chunks: this.chunks
      }

      // TODO 显示进度条
      this.dialogVisible = this.showProgress

      // TODO 发送前检查请求情况
      let uploadedChunks = await this.checkUploadByMD5(MD5) || []

      // TODO 更新进度条
      if (this.showProgress) this.updatePercentage(uploadedChunks.length, this.chunks)

      // TODO 如果已经完成
      if (uploadedChunks.length === this.chunks) {
        this.dialogVisible = false
        if (this.showProgress) this.$message.success(`上传完成, 共耗时${this.convert(new Date().getTime() - startTime)}`)
        return result
      }

      // TODO 同步等待上传完成
      let success = false
      await this.createBigFile(file, MD5, uploadedChunks).then((res) => {
        success = res
      })

      // TODO 如果完成 返回
      if (success) {
        this.dialogVisible = false
        if (this.showProgress)
        this.$message.success(`上传完成, 共耗时${this.convert(new Date().getTime() - startTime)}`)
        return result
      }
      else {
        this.reset()
        if (this.showProgress)
        this.$message.error(`上传失败, 请重试, 共耗时${this.convert(new Date().getTime() - startTime)}`)
        this.dialogVisible = false
        return null
      }
    },

    // TODO 整个文件计算MD5
    getFileMd5Mini(file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsBinaryString(file)
        fileReader.onload = (e) => {
          resolve(SparkMD5.hashBinary(e.target.result, false))
        }

        fileReader.onerror = function () {
          this.$message.error('文件读取出错, 请检查文件')
          reject(new Error('文件读取出错, 请检查文件'))
        }
      })
    },

    // TODO 分片计算文件MD5
    getFileMD5(file) {
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();
      // 获取文件分片对象（注意它的兼容性，在不同浏览器的写法不同）
      const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      // 当前分片下标
      let currentChunk = 0

      // 加载下一个分片
      const loadNext = () => {
        const start = currentChunk * this.chunkSize
        const end = start + this.chunkSize >= file.size ? file.size : start + this.chunkSize
        // 文件分片操作，读取下一分片(fileReader.readAsArrayBuffer操作会触发onload事件)
        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
      }
      loadNext()

      return new Promise((resolve, reject) => {
        fileReader.onload = function (e) {
          spark.append(e.target.result);
          if (currentChunk < this.chunks) {
            currentChunk++
            loadNext();
          } else {
            // 该文件的md5值
            const md5 = spark.end()
            resolve(md5);
          }
        }
        fileReader.onerror = function () {
          this.$message.error('文件读取出错，请检查该文件！')
          reject(new Error('文件读取出错，请检查该文件！'))
        }
      })
    },

    // TODO 检查上传状况
    async checkUploadByMD5(md5) {
      let uploaded = []
      await checkUpload(md5).then((res) => {
        if (res.code !== 200) return
        uploaded = res.data
      })
      return uploaded
    },

    // TODO 切片上传大文件
    createBigFile(file, md5, uploadedChunks) {
      return new Promise(async (resolve, reject) => {
        // 创建切片
        const fileChunks = []
        // 切片序号 - 同时计算总切片数
        let totalChunks = 0
        for (let cur = 0; cur < file.size; cur += this.chunkSize) {
          if (uploadedChunks.includes(String(++totalChunks))) continue
          // 未上传过此分片
          fileChunks.push({
            chunkNumber: totalChunks,
            chunk: file.slice(cur, cur + this.chunkSize),
          })
        }

        // 已经完成的数量
        let success = uploadedChunks.length
        if (success > totalChunks) resolve(true)
        // TODO 更新进度条
        if (this.showProgress) this.updatePercentage(success, totalChunks)

        // 连续失败次数
        let consecutiveFailure = 0

        // TODO 定义 - 并发和断点续传
        const uploadFileChunks = async (list) => {
          // 并发池
          const pool = []
          // 最大并发量
          const max = 2
          // 可接受最大连续失败数
          const failureMax = 50
          // 完成的数量
          let finish = 0
          // 失败的列表
          const failList = []

          // TODO 遍历文件列表
          for (let item of list) {
            // TODO 连续失败次数 >= failureMax, 停止请求
            if (consecutiveFailure >= failureMax) {
              this.$message.error('文件上传失败，请稍后再试！');
              reject(new Error('文件上传失败，请稍后再试！'))
              this.percent = 0
              this.dialogVisible = false
              return false
            }

            // TODO 获取切片并上传
            const formData = new FormData()
            formData.append('file', item.chunk)
            const task = breakPointUpload(md5, item.chunkNumber, formData)

            // TODO 定义异步处理
            task.then((res) => {
              const returnUploaded = res.data
              if (res.code === 200 && returnUploaded.includes(String(item.chunkNumber))) {
                // 请求结束后将该Promise任务从并发池中移除
                const poolIndex = pool.findIndex((item) => item === task)
                pool.splice(poolIndex, 1)
                if (this.showProgress) this.updatePercentage(++success, totalChunks)
                consecutiveFailure = 0
              }
              else {
                consecutiveFailure++
                failList.push(item)
              }

              if (returnUploaded.length === totalChunks) {
                // 全部分片都上传成功后后端返回文件信息
                resolve(true)
              }
            })
                .catch(() => {
                  consecutiveFailure++
                  failList.push(item)
                })
                .finally(() => {
                  finish++
                  // 所有请求都请求完成
                  if (finish === list.length && failList.length) {
                    uploadFileChunks(failList)
                  }
                })

            // TODO 添加任务到并发池中
            pool.push(task)

            // TODO 任务池满则阻塞
            if (pool.length === max) {
              // 每当并发池跑完一个任务，就再塞入一个任务，避免内存泄漏
              await Promise.race(pool)
            }
          }
        }

        // TODO 开始传文件
        uploadFileChunks(fileChunks)
      })
    },

  }
}
</script>

<style scoped>

</style>
