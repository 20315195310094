<template>
  <div style="margin-left: 16px">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="意见反馈" name="first">
        <ToFeedback></ToFeedback>
      </el-tab-pane>
      <el-tab-pane label="反馈历史" name="second">
        <HistoryFeedback></HistoryFeedback>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ToFeedback from '@/views/self/feedback/ToFeedback'
import HistoryFeedback from '@/views/self/feedback/HistoryFeedback'
export default {
  name: 'FeedBack',
  components: {
    ToFeedback,
    HistoryFeedback
  },
  data() {
    return {
      activeName: 'first',

    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },

  }
}
</script>

<style scoped>






</style>
