<template>
  <div>
    <el-dialog title="我的收藏" :visible.sync="visible" width="30%" >
      <div class="status-check-box">
        <div style="width: 100%; border: 1px solid silver; border-radius: 8px; padding: 10px">
          <ul style="list-style: none; overflow:auto; max-width: 100%; max-height: 240px; padding: 10px">
            <li style="width: 100%; display: flex; justify-content: space-between; align-items: center;"
                v-for="(item, index) in favouriteStatus" :key="favouriteStatus.appFavorListId">
              <el-checkbox v-model="item.status" :disabled="favouriteStatus[index].disable"> {{ item.name }}</el-checkbox>
              {{ item.size }}
            </li>
          </ul>
          <el-divider></el-divider>
          <div style="display: flex; justify-content: center; align-items: center">
            <el-button type="primary" @click="confirmCollect" size="small" style="width: 120px">确定</el-button>
          </div>

        </div>


      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addFavourite, favouriteStatus } from '@/api/userAction'

export default {
  name: 'AddFavouriteDialog',
  components: {
  },
  data() {
    return {
      visible: false,
      favouriteStatus: [],
      originStatus: []
    }
  },
  methods: {
    // 获取收藏夹信息
    async open (id, listId) {

      await favouriteStatus(id).then((res) => {
        this.favouriteStatus = res.data
        this.originStatus = JSON.parse(JSON.stringify(res.data))
        if (listId !== undefined) {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].appFavorListId !== listId) continue
            this.favouriteStatus[i].status = !this.favouriteStatus[i].status
            this.favouriteStatus[i].disable = true
            break
          }
        }
      }).catch((error) => {
        console.log(error)
      })
      this.visible = true
    },
    // 发起请求 - 收藏/取消收藏
    confirmCollect() {
      const del = []
      const add = []
      let isCollected = false
      let count = 0
      for (let i = 0; i < this.favouriteStatus.length; i++) {
        isCollected = this.favouriteStatus[i].status || isCollected
        if (this.originStatus[i].status === this.favouriteStatus[i].status) continue
        if (this.favouriteStatus[i].status) {
          add.push(this.favouriteStatus[i].appFavorListId)
          count++
        }
        else {
          del.push(this.favouriteStatus[i].appFavorListId)
          count--
        }
      }
      // 发送请求
      addFavourite(add, del, this.$route.query.id).then((res) => {
        this.$message.success(res.msg)
        // 弹窗消失
        this.visible = false
        this.$emit('handleChange', count, isCollected)
      }).catch((error) => {
        console.log(error)
      })
    },
  }
}
</script>

<style scoped>
.status-check-box {
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
