<template>
  <div>
    <el-divider content-position="left"><div style="font-size: 16px"> 收藏夹列表 </div> </el-divider>

    <el-empty v-if="favorListList.length === 0" description="ta还没有创建过收藏夹哦"></el-empty>
    <el-row v-else>
      <ul style="overflow:auto; max-height: 640px; width: 100%; display: flex; justify-content: flex-start; flex-wrap: wrap;list-style-type: none;">
        <!--        <el-col :span="4" >-->
        <li style="list-style: none; flex-basis: 20%; margin: 10px" v-for="item in favorListList" :key="item.id"
          @click="listDetail(item.id, item.cover, item.name, item.remark, item.createTime)">
          <div style="display: grid; justify-content: center; border: 1px silver solid; border-radius: 8px; padding: 5px">
            <div style="display: flex; justify-content: center; align-items: center">
              <el-avatar v-if="item.cover" :src="item.cover" :size="100" shape="square" />
              <el-avatar v-else shape="square" :size="100"> 封面 </el-avatar>
            </div>
            <div class="text-item" style="justify-content: center;"> {{ item.name }} : {{ item.count }}</div>
            <div class="text-item" style="font-size: 12px; color: silver;  justify-content: center;"> 创建于{{ item.createTime }} </div>
          </div>
        </li>
        <!--        </el-col>-->
      </ul>

      <div style="display: flex; justify-content: right; align-items: center; width: 100%; margin: 10px">
        <el-button-group>
          <el-button type="primary" icon="el-icon-arrow-left" :disabled="page === 1" @click="prePage">上一页</el-button>
          <el-button type="primary" :disabled="nextDisable" @click="nextPage">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        </el-button-group>
      </div>

    </el-row>
  </div>
</template>

<script>
import {getFavorList} from '@/api/visit'

export default {
  name: 'FavorList',
  mounted() {
    this.getList()
  },
  components: {
  },
  props: {
    userId: {
      required: true
    }
  },
  data() {
    return {
      favorListList: [],
      page: 1,
      pageSize: 8,
      nextDisable: false
    }
  },
  methods: {
    async getList() {
      const promise = getFavorList(this.userId, this.page, this.pageSize)
      await promise.then((res) => {
        if (res.code !== 200) return
        if (res.data.length !== 0) this.favorListList = res.data
        else {
          if (this.page === 1)this.favorListList = res.data
          else this.page--
        }
        this.nextDisable = res.data.length < this.pageSize
      })
    },

    listDetail(id, cover, name, remark, createTime) {
      this.$emit('handleChange', {
        id: id,
        cover: cover,
        name: name,
        remark: remark,
        createTime: createTime
      })
    },

    prePage() {
      if (this.page > 1) {
        this.page--
        this.getList()
        return
      }
      this.page = 1
    },

    nextPage() {
      if (this.nextDisable) return
      this.page++
      this.getList()
    }
  }
}
</script>

<style scoped>
:deep(.el-row) {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.text-item {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}
</style>
