import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)

export default {
    namespaced: true,
    state () {
        return {
            categoryOptions: [
                { id: 0, name: '全部/All' },
                { id: 1, name: '动物/Animal' },
                { id: 2, name: '家具/Furniture' },
                { id: 3, name: '汽车/Vehicles' },
                { id: 4, name: '武器/Weapons' },
                { id: 5, name: '科技/Technology' },
                { id: 6, name: '时尚/Fashion' },
                { id: 7, name: '食物/Food' },
                { id: 8, name: '建筑/Architecture' },
                { id: 9, name: '运动/Sports' },
                { id: 10, name: '其它/Others' }
            ],

            orderOptions: [
                { id: 0, name: '按发布时间' },
                { id: 1, name: '浏览量' },
                { id: 2, name: '点赞数' },
                { id: 3, name: '收藏量' },
                { id: 4, name: '下载量' },
                { id: 5, name: '成交量' },
                { id: 6, name: '价格'}
            ],

            descOptions: [
                { id: 0, name: '升序' },
                { id: 1, name: '降序' }
            ],

            statusOptions: [
                { id: -1, name: '全部' },
                { id: 1, name: '已上架' },
                { id: 0, name: '已下架' },
                { id: 2, name: '审核中' },
                { id: 3, name: '强制下架' }
            ],

            uploadCategoryOptions: [
                { id: 1, name: '动物/Animal' },
                { id: 2, name: '家具/Furniture' },
                { id: 3, name: '汽车/Vehicles' },
                { id: 4, name: '武器/Weapons' },
                { id: 5, name: '科技/Technology' },
                { id: 6, name: '时尚/Fashion' },
                { id: 7, name: '食物/Food' },
                { id: 8, name: '建筑/Architecture' },
                { id: 9, name: '运动/Sports' },
                { id: 10, name: '其它/Others'}
            ],

            splitAnalysisOptions: [
                { id: 0, label: '浏览', en: 'view' },
                { id: 1, label: '点赞', en: 'like' },
                { id: 2, label: '评论', en: 'comment' },
                { id: 3, label: '收藏', en: 'collect' },
                { id: 4, label: '下载', en: 'download' },
                { id: 5, label: '成交', en: 'deal' }
            ]
        }
    },
    getters: {
        categoryList(state) {
            return state.categoryOptions
        },
        orderList(state) {
            return state.orderOptions
        },
        descList(state) {
            return state.descOptions
        },
        statusList(state) {
            return state.statusOptions
        },
        uploadCategoryList(state) {
            return state.uploadCategoryOptions
        },
        analysisList(state) {
            return state.splitAnalysisOptions
        }
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    }
}
