import Vuex from 'vuex'
import Vue from 'vue'
import { append, getCart, remove } from '@/api/cart'
import { Message } from 'element-ui'
Vue.use(Vuex)

export default {
    namespaced: true,
    state () {
        return {
            cartList: []
        }
    },
    getters: {
        list (state) {
            return state.cartList
        }
    },
    mutations: {
        setCart(state, cart) {
            state.cartList = cart
        }
    },
    actions: {
        async getList (context) {
            await getCart().then((res) => {
                context.commit('setCart', res.data)
            })
        },
        async addToList(context, id) {
            let success = true
            await append(id).then((res) => {
                success = res.code === 200
                if (!success) return
                Message({
                    type: 'success',
                    message: res.msg
                })
            })
            if (success) await context.dispatch('getList')
        },
        async removeFromList(context, idList) {
            console.log("移除购物车内容, 其id为: " + idList)
            let success = true
            await remove(idList).then((res) => {
                success = res.code === 200
                if (!success) return
                Message({
                    type: 'success',
                    message: res.msg
                })
            })
            if (success) await context.dispatch('getList')
        }
    },
    modules: {
    }
}
