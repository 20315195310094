import request from '@/utils/request'

// TODO 发送请求 - 生成订单信息
export function generateOrder (listOfId) {
    return request({
        url: '/app/order',
        method: 'post',
        data: {
            ids: listOfId
        }
    })
}

// TODO 订单数量
export function numberOfOrder (isBuyer, status) {
    let url = `/app/order/${isBuyer}`
    if (status !== null) url += ("?status=" + status)
    return request({
        url: url,
        method: 'get'
    })
}

// TODO 获取订单列表
export function listOfOrder(page, pageSize, desc, isBuyer, status) {
    let url = `/app/order/${page}/${pageSize}/${desc}/${isBuyer}`
    if (status !== null) url += ("?status=" + status)
    return request({
        url: url,
        method: 'get'
    })
}

// TODO 请求打包下载
export function ask4DownloadZip(id) {
    return request({
        url: `/app/order/downloadInfo?orderId=${id}`,
        method: 'get'
    })
}

// TODO 下载
export function download(id, chunkIndex, lastIndex) {
    return request({
        url: `/app/order/download?orderId=${id}&chunkIndex=${chunkIndex}&lastIndex=${lastIndex}`,
        method: 'get',
        responseType: 'blob'
    })
}


// TODO 删除订单
export function deleteOrder(id, isBuyer) {
    return request({
        url: `/app/order/${id}/${isBuyer}`,
        method: 'delete'
    })
}
