import request from '@/utils/request'


// TODO 获取消息数量
export function numberOfNotice(read) {
    let url = '/app/notice'
    if (read !== null) url += '?read=' + read
    return request({
        url: url,
        method: 'get'
    })
}

// TODO 消息列表
export function listOfNotice(page, pageSize, desc, read) {
    let url = `/app/notice/${page}/${pageSize}/${desc}`
    if (read !== null) url += ('?read=' + read)
    return request({
        url: url,
        method: 'get'
    })
}

// TODO 标记消息
export function markNotice (id, read) {
    return request({
        url: `/app/notice/${id}/${read}`,
        method: 'put'
    })
}

// TODO 删除消息
export function deleteNotice (id) {
    return request({
        url: `/app/notice/${id}`,
        method: 'delete'
    })
}
