<template>
  <div style="border: 1px silver solid; padding: 20px; border-radius: 12px; margin: 25px">
    <el-descriptions class="margin-top" title="个人信息" :column="1" size="medium" border style="width: 600px" :label-style="labelStyle">
      <el-descriptions-item label="头像" >
        <div style="display: flex; align-items: center;">
          <el-avatar :size="80" shape="circle">
            <div v-if="$store.getters['user/info'].icon === '' || $store.getters['user/info'].icon === null">头像</div>
            <el-image v-else style="width: 80px; height: 80px" :src="$store.getters['user/info'].icon" :preview-src-list="[userInfo.icon]"/>
          </el-avatar>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="用户名">
        {{ $store.getters['user/info'].username }}
      </el-descriptions-item>
      <el-descriptions-item label="主页留言">
        {{ $store.getters['user/info'].message }}
      </el-descriptions-item>

      <el-descriptions-item label="邮箱">
        {{ $store.getters['user/info'].email }}
      </el-descriptions-item>

      <el-descriptions-item label="性别">
        <div v-if="$store.getters['user/info'].gender === 1"><i class="el-icon-male"></i>男</div>
        <div v-else-if="$store.getters['user/info'].gender === 0"><i class="el-icon-female"></i>女</div>
        <div v-else>保密</div>
      </el-descriptions-item>

      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-present">生日</i>
        </template>
        {{ $store.getters['user/info'].birthday }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-time"></i>
          注册日期
        </template>
        {{ $store.getters['user/info'].createTime }}
      </el-descriptions-item>
    </el-descriptions>
    <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin: 25px">
      <el-button type="primary" @click="$emit('update:step', 2)">编辑</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminProfile',
  props: {
    step: {},
    userInfo: {
      type: Object
    }
  },
  data () {
    return {
      labelStyle: {
        width: '120px'
      }
    }
  }
}
</script>

<style scoped>

</style>
