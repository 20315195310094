<template>
  <div>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right" style="margin: 10px">
        <el-breadcrumb-item :to="{ path: '/star' }">收藏夹列表</el-breadcrumb-item>
        <el-breadcrumb-item>{{ favorList.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div style="display: flex">
      <img v-if="favorList.cover" :src="favorList.cover" style="height: 160px; width: auto"/>
      <el-avatar v-else shape="square" :size="160"> 封面 </el-avatar>
      <div style="margin-left: 20px">
        <div style="font-size: 20px; font-weight: bold"> {{ favorList.name }} </div>
        <div style="font-size: 16px;">创建于 {{ favorList.createTime }}</div>
        <div><div style="font-size: 16px; font-weight: bold">简介:</div><div style="font-size: 13px; color: silver"> {{ favorList.remark || "啥也没留下"}}</div></div>
      </div>
    </div>


    <el-divider content-position="left"> </el-divider>

    <div class="filter-box">
      <div class="filter-item">
        <label>排序: </label>
        <el-select v-model="orderFilter" size="small" style="width: 120px" @change="getFavourites">
          <el-option label="收藏时间" :value="1"></el-option>
<!--          <el-option label="名称" value="2"></el-option>-->
        </el-select>
      </div>

      <div class="filter-item">
        <el-radio-group v-model="descFilter" size="small" @change="getFavourites">
          <el-radio-button v-for="item in $store.getters['staticStore/descList']"
                           :label="item.id" :key="item.id"> {{ item.name }} </el-radio-button>
        </el-radio-group>
      </div>
    </div>

    <el-empty v-if="favourites.length === 0" description="还没有收藏哦, 快去浏览收藏吧"></el-empty>


    <div v-else>
      <el-table :data="favourites" style="width: 100%" max-height="600">
        <el-table-column fixed label="封面" width="400">
          <template slot-scope="scope">
            <img :src="scope.row.modelVo.cover" class="image">
          </template>
        </el-table-column>

        <el-table-column label="商品详情" width="360" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="padding: 10px; width: 200px;">
              <h3>标题: {{ scope.row.modelVo.title }}</h3>
              <p style="font-size: 12px; color: silver">描述: {{ scope.row.modelVo.description }}</p>
              <div>￥{{ scope.row.modelVo.price }}</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="收藏于" width="200" prop="createTime"/>

        <el-table-column fixed="right" label="操作" width="300">
          <template slot-scope="scope">
            <div style="width: 240px;height: 160px; display: flex; justify-content: space-around; align-items: center; margin-right: 20px">
              <el-link icon="el-icon-link" :underline="false" @click.native.prevent="openDetail(scope.row.modelVo.id, scope.row.modelVo.title)">详情页</el-link>
              <el-link :underline="false" icon="el-icon-folder-opened" @click.native.prevent="$refs.favourite.open(scope.row.modelVo.id, favorList.id)">移动至</el-link>
              <el-link style="color: red" icon="el-icon-delete" :underline="false" @click.native.prevent="openDelete(scope.row.id)">删除</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div style="display: flex; justify-content: right; margin: 20px">
        <el-button-group>
          <el-button type="primary" icon="el-icon-arrow-left" :disabled="page === 1" @click="prePage">上一页</el-button>
          <el-button type="primary" :disabled="nextDisable" @click="nextPage">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        </el-button-group>
      </div>
    </div>

    <TipsDialog ref="delete" :handleConfirm="deleteFavourite"></TipsDialog>
    <AddFavouriteDialog  ref="favourite"></AddFavouriteDialog>
  </div>
</template>

<script>
import {deleteFavourite, listFavourites} from '@/api/favourite'
import TipsDialog from '@/components/TipsDialog'
import AddFavouriteDialog from "@/views/home/itemDetail/AddFavouriteDialog";
export default {
  name: 'StarContentPage',
  components: {
    AddFavouriteDialog,
    TipsDialog
  },
  data() {
    return {
      favorList: {
        name: '',
        cover: '',
        id: '',
        remark: '',
        createTime: ''
      },
      favourites: [],
      page: 1,
      pageSize: 10,
      orderFilter: 1,
      descFilter: 1,
      nextDisable: false
    }
  },

  mounted() {
    this.favorList.name = this.$route.query.name
    this.favorList.cover = this.$route.query.cover
    this.favorList.id = this.$route.query.id
    this.favorList.remark = this.$route.query.remark
    this.favorList.createTime = this.$route.query.createTime
    this.getFavourites()

  },


  methods: {
    // TODO 查询
    getFavourites() {
      const promise = listFavourites(this.favorList.id, this.page, this.pageSize, this.orderFilter, this.descFilter)
      promise.then((res) => {
        if (res.code !== 200) return
        if (res.data.length !== 0) this.favourites = res.data
        else {
          if (this.page === 1) this.favourites = res.data
          else this.page--
        }
        this.nextDisable = res.data.length < this.pageSize
      })
    },

    // TODO 下一页
    nextPage() {
      this.page++
      this.getFavourites()
    },

    // TODO 上一页
    prePage() {
      if (this.page > 1) {
        this.page--
        this.getFavourites()
        return
      }
      this.page = 1
    },

    // TODO 打开二次确认弹框
    openDelete(id) {
      this.$refs.delete.open(id, '你确定要移除此收藏吗?')
    },

    // TODO 确认删除收藏
    deleteFavourite(id) {
      const promise = deleteFavourite(id);
      promise.then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
      })
    },

    openDetail(id, title) {
      let routeData = this.$router.resolve({
        path: `/detail?id=${id}&title=${title}`
      })
      window.open(routeData.href, '_blank')
    }
  }

}
</script>

<style scoped>
.filter-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /*margin: 10px;*/
  width: 100%;
  margin-top: 30px;
}

.filter-item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 20px;
  /*width: 280px*/
  /*width: 20%;*/
}

.image {
  height: 160px;
  width: auto;
  display: block;
}
</style>
