<template>
  <div v-title data-title="3D Mall 首页">
    <div class="search-box">
      <el-input class="search" placeholder="搜索你的创意" v-model="keyword">
        <el-button slot="append" icon="el-icon-search" @click="search(0, keyword)"></el-button>
      </el-input>
    </div>
    <!--  轮播图  -->
    <div class="slide-box">
      <div class="slide">
        <el-carousel :interval="3000" type="card" height="300px">
          <el-carousel-item  v-for="item in slideList" :key="item.id">
            <el-image style="width: auto; height: 300px" :src="item.cover" fit="scale-down" @click="openDetail(item.id, item.title)"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <div>
      <div>分类</div>
      <div style="margin-top: 10px">
        <el-row>
          <el-col :span="8" v-for="item in categoryList" :key="item.id">
            <el-card :body-style="{ padding: '0px' }" @click.native="search(item.id, '')">
              <img :src="item.url" class="image">
              <div style="padding: 10px;">
                <span>{{ item.name }}</span>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import Helicopter from '../../assets/category/Helicopter.png'
import Ferrari from '../../assets/category/Ferrari.png'
import M4A1 from '../../assets/category/M4A1.png'
import Furniture from '../../assets/category/Furniture.png'
import Food from '../../assets/category/Food.png'
import Architecture from '../../assets/category/Architecture.png'
import Animals from '../../assets/category/Animals.png'
import Sports from '../../assets/category/Sports.png'
import Fashion from '../../assets/category/Fashion.png'
import { slide } from '@/api/model'
export default {
  name: "mainPage",
  data () {
    return {
      keyword: null,
      categoryList: [
        { id: 1, name: '动物/Animal', url: Animals },
        { id: 2, name: '家具/Furniture', url: Furniture },
        { id: 3, name: '汽车/Vehicles', url: Ferrari },
        { id: 4, name: '武器/Weapons', url: M4A1 },
        { id: 5, name: '科技/Technology', url: Helicopter },
        { id: 6, name: '时尚/Fashion', url: Fashion },
        { id: 7, name: '食物/Food', url: Food },
        { id: 8, name: '建筑/Architecture', url: Architecture },
        { id: 9, name: '运动/Sports', url: Sports },
      ],
      slideList: []
    }
  },
  mounted() {
    this.getSlideData()
  },
  methods: {
    search (modelType, keyword) {
      let routeData = this.$router.resolve({
        path: '/search',
        query: {
          keyword: keyword,
          modelType: modelType
        }
      })
      window.open(routeData.href, '_blank')
    },

    getSlideData () {
      const promise = slide()
      promise.then((res) => {
        if (res.code !== 200) return
        this.slideList = res.data
      })
    },

    openDetail(id, title) {
      let routeData = this.$router.resolve({
        path: `/detail?id=${id}&title=${title}`
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style scoped>
.search-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}
:deep(.search) {
  height: 30px;
  width: 720px;
  border-radius: 10px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.5;
  line-height: 200px;
  margin: 0;
}

.slide-box {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.slide {
  height: 100%;
  width: 1200px;
}

.image {
  width: 100%;
  /*height: auto;*/
  display: block;
}
:deep(.el-row) {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}


</style>
