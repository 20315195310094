import axios from 'axios'
import Message from 'element-ui'
import { getToken, removeToken, removeUser } from '@/utils/storage'
import router from '@/router'

// import store from '@/store/index'
// import
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers['Access-Control-Allow-Origin'] = ''
axios.defaults.headers["Access-Control-Allow-Credentials"] = 'true'
axios.defaults.withCredentials = true

const service = axios.create({
  baseURL: '/api',
  timeout: 10000
})

// 添加请求拦截器 - 发送请求之前做什么
service.interceptors.request.use(
  function (config) {
    config.headers.Authorization = getToken()
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    // 成接收到响应后的行为
    // axios默认多包装一层data 这里进行拆包
    const res = response.data
    // 响应不成功
    if (res.code !== 200) {
      // 提示
      if (res.code === 401) {
        removeToken()
        removeUser()
        Message.Message({type: 'error', message: '验证过期,  请重新登录'})
        if (window.location.hash !== '#/login') {
            router.push('/login');
        }
      }
      else if (res.code === 404) {
          router.push({
              path: '/notFound'
          })
      }
      else if (res.code === 500) {
          Message.Message({
              type: 'error',
              message: res.msg
          })
      }
      // 抛出错误的promise
      // return Promise.reject(res.msg)
    }
    return res
  },
  function (error) {
    // 处理错误响应
    // Message.Message({type: 'error', message: ''})
    // alert('发生未知错误')
    console.log(error)
    return error
  }
)

// 导出实例
export default service
