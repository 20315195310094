import request from '@/utils/request'


// TODO 发布评论
export function publishComment(comment) {
    return request({
        url: '/app/comment',
        method: 'post',
        data: comment
    })
}

// TODO 获取评论列表
export function getComment(modelId, page, pageSize, desc, star, order) {
    return request({
        url: `/app/comment/${modelId}/${page}/${pageSize}/${desc}/${star}/${order}`,
        method: 'get'
    })
}

// TODO 删除评论
export function deleteComment(id) {
    return request({
        url: `/app/comment/${id}`,
        method: 'delete'
    })
}
