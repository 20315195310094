<template>
  <div>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right" style="margin: 10px">
        <el-breadcrumb-item to="" @click.native="$emit('handleChange')">
          <el-link>收藏夹列表</el-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ favorList.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div style="display: flex">
      <img v-if="favorList.cover" :src="favorList.cover" style="height: 160px; width: auto"/>
      <el-avatar v-else shape="square" :size="160"> 封面 </el-avatar>
      <div style="margin-left: 20px">
        <div style="font-size: 20px; font-weight: bold"> {{ favorList.name }} </div>
        <div style="font-size: 16px;">创建于 {{ favorList.createTime }}</div>
        <div><div style="font-size: 16px; font-weight: bold">简介:</div><div style="font-size: 13px; color: silver"> {{ favorList.remark || "啥也没留下"}}</div></div>
      </div>
    </div>


    <el-divider content-position="left"> </el-divider>

    <el-empty v-if="favourites.length === 0" description="此收藏夹空空如也"></el-empty>


    <div v-else>
      <el-table :data="favourites" style="width: 100%" max-height="480">
        <el-table-column fixed label="封面" width="400">
          <template slot-scope="scope">
            <img :src="scope.row.modelVo.cover" class="image">
          </template>
        </el-table-column>

        <el-table-column label="商品详情" width="360" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="padding: 10px; width: 200px;">
              <h3>标题: {{ scope.row.modelVo.title }}</h3>
              <p style="font-size: 12px; color: silver">描述: {{ scope.row.modelVo.description }}</p>
              <div>￥{{ scope.row.modelVo.price }}</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="收藏于" width="200" prop="createTime"/>

        <el-table-column fixed="right" label="操作" width="300">
          <template slot-scope="scope">
            <div style="width: 240px;height: 160px; display: flex; justify-content: space-around; align-items: center; margin-right: 20px">
              <el-link icon="el-icon-link" :underline="false" @click.native.prevent="openDetail(scope.row.modelVo.id, scope.row.modelVo.title)">详情页</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div style="display: flex; justify-content: right; margin: 20px">
        <el-button-group>
          <el-button type="primary" icon="el-icon-arrow-left" :disabled="page === 1" @click="prePage">上一页</el-button>
          <el-button type="primary" :disabled="nextDisable" @click="nextPage">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        </el-button-group>
      </div>
    </div>
  </div>
</template>

<script>
import { getListContent } from '@/api/visit'

export default {
  name: 'ListContent',
  props: {
    favorList: {
      required: true
    }
  },
  components: {
  },
  data() {
    return {
      favourites: [],
      page: 1,
      pageSize: 5,
      nextDisable: false
    }
  },

  mounted() {
  },


  methods: {
    // TODO 查询
    getFavourites() {
      const promise = getListContent(this.favorList.id, this.page, this.pageSize)
      promise.then((res) => {
        if (res.code !== 200) return
        if (res.data.length !== 0) this.favourites = res.data
        else {
          if (this.page === 1) this.favourites = res.data
          else this.page--
        }
        this.nextDisable = res.data.length < this.pageSize
      })
    },

    // TODO 下一页
    nextPage() {
      this.page++
      this.getFavourites()
    },

    // TODO 上一页
    prePage() {
      if (this.page > 1) {
        this.page--
        this.getFavourites()
        return
      }
      this.page = 1
    },
    openDetail(id, title) {
      let routeData = this.$router.resolve({
        path: `/detail?id=${id}&title=${title}`
      })
      window.open(routeData.href, '_blank')
    }
  }

}
</script>

<style scoped>
.image {
  height: 160px;
  width: auto;
  display: block;
}
</style>
