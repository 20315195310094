<template>
  <div>
    <el-dialog title="图片裁剪" :visible.sync="dialogVisible" width="50%" :before-close="handleClose" :modal-append-to-body='false'>

      <div style="display: grid; justify-content: center; align-items: center">
        <vue-cropper style="width: 500px; height: 500px"
          ref="cropper"
          :fixedBox="true"
          :img="imgUrl"
          :outputSize="1"
          :autoCropWidth="width"
          :autoCropHeight="height"
          :auto-crop="true"/>
        <input type="file" @change="handleFileChange"/>
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: space-around; align-items: center">
        <el-button type="primary" @click="cropImage">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

// import Api from '@/js/api.js' //接口url配置文件

import { tempFile } from '@/api/file'

export default {
  name: 'ImageCropper',
  data() {
    return {
      dialogVisible: false,
      imgUrl: '',
      fileName: ''
    }
  },
  components: {
  },
  props: {
    width: {
      type: Number,
      default: 300
    },
    height: {
      type: Number,
      default: 300
    },
    check: {
      type: Boolean
    }
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    cropImage() {
      this.$refs.cropper.getCropBlob((blob) => {
        // 在这里可以上传blob数据到服务器
        this.upload(blob)
      })
    },

    async handleFileChange(e) {
      const file = e.target.files[0]
      if (!file) return

      await this.$emit('handleCheck', file)
      if (!this.check) {
        return
      }
      this.imgUrl = URL.createObjectURL(file)
      this.fileName = file.name
    },

    // TODO 上传
    upload(blob) {
      const formData = new FormData()

      // TODO 上传
      formData.append('file', blob, this.fileName)
      tempFile(formData).then((res) => {
        if (res.code !== 200) return
        this.$emit('handleChange', res.data)
        this.dialogVisible = false
        this.imgUrl = ''
      })
    }

  }

}
</script>

<style scoped>


</style>
