<template>
  <div style="width: 100%;">
    <el-breadcrumb separator-class="el-icon-arrow-right" style="margin: 20px">
      <el-breadcrumb-item :to="{ path: '/modelSet' }">作品集</el-breadcrumb-item>
      <el-breadcrumb-item>{{ modelInfo.title }}</el-breadcrumb-item>
    </el-breadcrumb>

    <div style="width:100%; display: flex; justify-content: space-around; align-items: center;">
      <div id="edit" style="width: 60%; height: 60vh">
        <el-tabs v-model="activeName">
          <el-tab-pane label="模型" name="first">
            <ThreeDimensionBoardEdit :modelPath="modelInfo.modelPath" :root="modelInfo.root" ref="model"
                                     @handleChange="changeModel">
            </ThreeDimensionBoardEdit>
          </el-tab-pane>
          <el-tab-pane class="exhibit" label="*封面" name="second">
            <ExhibitComponent :url="modelInfo.cover" @handleChange="changeCover"></ExhibitComponent>
          </el-tab-pane>

          <el-tab-pane class="exhibit" label="正视图" name="third">
            <ExhibitComponent :url="modelInfo.frontView" @handleChange="changeFront"></ExhibitComponent>
          </el-tab-pane>

          <el-tab-pane class="exhibit" label="左视图" name="fourth">
            <ExhibitComponent :url="modelInfo.leftView" @handleChange="changeLeft"></ExhibitComponent>
          </el-tab-pane>

          <el-tab-pane class="exhibit" label="俯视图" name="fifth">
            <ExhibitComponent :url="modelInfo.topView" @handleChange="changeTop"></ExhibitComponent>
          </el-tab-pane>

          <el-tab-pane class="exhibit"  label="其它视图" name="sixth">
            <ExhibitComponent :url="modelInfo.otherView" @handleChange="changeOther"></ExhibitComponent>
          </el-tab-pane>
          <el-tab-pane class="exhibit"  label="视频介绍" name="seventh">
            <ExhibitComponent :video="true" :url="modelInfo.video" @handleChange="changeVideo"></ExhibitComponent>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div style="width: 30%; border: 1px silver solid; border-radius: 8px">
        <p style="margin-left: 25px">基本信息</p>
        <div >
          <el-form ref="form" :model="modelInfo" label-width="80px" style="width: 90%">
            <el-form-item label="*标题">
              <el-input v-model.trim="modelInfo.title"></el-input>
            </el-form-item>
            <el-form-item label="*描述">
              <el-input type="textarea" v-model.trim="modelInfo.description"></el-input>
            </el-form-item>
            <el-form-item label="价格">
              <el-input v-model="modelInfo.price"></el-input>
            </el-form-item>
            <el-form-item label="默认展示">
              <el-radio-group v-model="modelInfo.defaultShow">
                <el-radio :label="1" :value="1">模型</el-radio>
                <el-radio :label="2" :value="2">图片</el-radio>
                <el-radio :label="3" :value="3">视频</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="分类">
              <el-select v-model="modelInfo.category" placeholder="请选择分类">
                <el-option
                    v-for="item in $store.getters['staticStore/uploadCategoryList']"
                    :label="item.name" :value="item.id" :key="item.id">
                  {{ item.name }}
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <div style="display: flex; justify-content: space-around; align-items: center">
                <el-button @click="$router.push('/modelSet')">返 回</el-button>
                <el-button type="primary" @click="$refs.edit.open(1, '提交修改后会重新审核, 确定提交修改吗')" :disabled="updateDisable">应 用 修 改</el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <tips-dialog ref="edit" @handleConfirm="finalUpdate"></tips-dialog>
  </div>
</template>

<script>
import { updateModel } from "@/api/model"
import ExhibitComponent from '@/views/self/upload/exhibit'
import ThreeDimensionBoardEdit from '@/views/self/upload/3d-board-edit'
import { queryById } from "@/api/model"
import TipsDialog from '@/components/TipsDialog'

export default {
  name: 'updatePage',
  components: {
    ExhibitComponent,
    ThreeDimensionBoardEdit,
    TipsDialog
  },
  async mounted() {
    const id = this.$route.query.id
    if (id === undefined || id === null) this.$router.push('/notFound')
    const edit = document.getElementById('edit')
    const width = edit.offsetWidth
    const height = edit.offsetHeight
    this.$refs.model.initExhibition(width, height)

    queryById(id, "1").then((res) => {
      if (res.code !== 200) return
      this.modelInfo = res.data
      // TODO 加载模型
      this.$refs.model.loadModel(this.modelInfo.modelPath)
    })
    // 特殊处理
    this.modelInfo.createTime = null
    this.modelInfo.updateTime = null
  },

  data () {
    return {
      activeName: 'first',
      modelInfo: {}
    }
  },
  methods: {
    changeCover(param) {
      this.modelInfo.cover = param
    },
    changeFront(param) {
      this.modelInfo.frontView = param
    },
    changeLeft(param) {
      this.modelInfo.leftView = param
    },
    changeTop(param) {
      this.modelInfo.topView = param
    },
    changeOther(param) {
      this.modelInfo.otherView = param
    },
    changeVideo(param) {
      this.modelInfo.video = param
    },
    changeModel(param) {
      this.modelInfo.root = param[0]
      this.modelInfo.modelPath = param[1]
    },
    // 提交作品
    finalUpdate() {
      // if (typeof (this.modelInfo.price) !== typeof (0.99)) {
      //   this.$message.error('价格不能为空')
      //   return
      // }
      updateModel(this.modelInfo).then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
      })
    }
  },
  computed: {
    updateDisable() {
      let flag
      if (this.modelInfo.defaultShow === 1) {
        flag = this.modelInfo.root === '' || this.modelInfo.modelPath === ''
      } else if (this.modelInfo.defaultShow === 2) {
        flag = this.modelInfo.frontView === ''
      } else {
        flag = this.modelInfo.video === ''
      }
      return this.modelInfo.title === '' || this.modelInfo.cover === '' || flag
    }
  }

}
</script>

<style scoped>

.exhibit {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border-left: 1px silver solid;
  border-right: 1px silver solid;
  border-bottom: 1px silver solid;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

</style>
