<template>
  <div>
    <!--  筛选框  -->
    <div>
      <div style=" margin-top: 33px">
        状态:
        <el-radio-group v-model="status" @change="statusChange">
          <el-radio-button :label="null">全部</el-radio-button>
          <el-radio-button :label="0">待付款</el-radio-button>
          <el-radio-button :label="1">待下载</el-radio-button>
          <el-radio-button :label="2">超时未支付</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <!--  表格  -->
    <div style="width: 100%">
      <el-table :data="orderList" style="width: 100%" max-height="720px">
        <el-table-column label="订单详情">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: left; width: 100%">
              <el-avatar :size="25" style="margin-left: 33px; margin-right: 23px">
                <img v-if="scope.row.sellerIcon" :src="scope.row.sellerIcon"/>
                <div v-else> user </div>
              </el-avatar>
              {{ scope.row.sellerUsername }}
            </div>

            <div style="margin-left: 33px; margin-top: 23px; display: flex; width: 100%">
              <div style="width: 280px">
                订单编号: {{ scope.row.id }}
                <img v-if="scope.row.model.cover" :src="scope.row.model.cover" style="height: auto; width: 240px" @click="openDetail(scope.row.modelId, scope.row.model.title)">
                <el-avatar v-else :size="80">商品已下架</el-avatar>
              </div>

              <div style="margin-left: 33px; width: 300px">
                <div style="font-size: 16px; font-weight: bold">{{ scope.row.model.title }}</div>
                <div style="font-size: 12px; color: silver">描述: {{ scope.row.model.description }}</div>
              </div>

              <div style="margin-left: 33px; width: 300px">
                <div>成交价格: {{ scope.row.price }}</div>
                <div>下单时间: {{ scope.row.createTime }}</div>
                <div v-if="scope.row.status === 1">支付时间: {{ scope.row.payTime }}</div>
                <div v-if="scope.row.status === 0">订单状态: 待付款</div>
                <div v-else-if="scope.row.status === 1">订单状态: 待下载</div>
                <div v-else-if="scope.row.status === 2">订单状态: 超时未支付</div>
              </div>
            </div>
            <el-divider></el-divider>
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="200">
          <template slot-scope="scope">
<!--            <el-button type="text" @click="" :disabled="scope.row.status !== 0">支付</el-button>-->
<!--            <el-button type="text" @click="" :disabled="scope.row.status !== 1">退款</el-button>-->
            <el-button type="text" v-if="scope.row.status === 0" @click="gotoPay(scope.row.payId)">去付款</el-button>
            <el-button type="text" v-else @click="checkStatus(scope.row.payId)" :disabled="scope.row.status === 1">刷新</el-button>
            <el-button type="text" :disabled="scope.row.status !== 1" @click="tryDownload(scope.row.id, scope.row.model.title)">去下载</el-button>
<!--            <el-button type="text" @click="$refs.detail.open(scope.row)">查看详情</el-button>-->
            <el-button type="text" @click="$refs.delete.open(scope.row.id, '删除后将永远无法恢复订单(无法再下载该模型), 确定吗')" style="color: red">删除订单</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--  分页条  -->
    <div style="display: flex; justify-content: center; align-items: center">
      <el-pagination
          @size-change="sizeChange"
          @current-change="getOrders"
          :current-page.sync="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="pageSize"
          layout="total, sizes, prev, pager, next"
          :total="totalOrder">
      </el-pagination>
    </div>

    <TipsDialog ref="delete" @handleConfirm="confirmDelete"></TipsDialog>
    <OrderDetailBuyer ref="detail"></OrderDetailBuyer>

    <el-dialog title="下载中" :visible.sync="progressVisible" width="30%">
      <div style="width: 100%; display: flex; justify-content: center; align-items: center">
        <el-progress type="circle" :percentage="progress"></el-progress>
      </div>
<!--      <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-top: 20px">-->
<!--        <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-top: 20px">-->
<!--          继续&nbsp <el-button type="primary" icon="el-icon-video-play" circle></el-button>-->

<!--        </div>-->
<!--        <div style="width: 100%; display: flex; justify-content: center; align-items: center; margin-top: 20px">-->
<!--          暂停&nbsp <el-button type="danger" icon="el-icon-video-pause" circle></el-button>-->
<!--        </div>-->
<!--      </div>-->
    </el-dialog>
  </div>
</template>

<script>
import {ask4DownloadZip, deleteOrder, download, listOfOrder, numberOfOrder} from '@/api/order'
import TipsDialog from '@/components/TipsDialog'
import OrderDetailBuyer from '@/views/self/order/OrderDetailBuyer'
import { checkPayStatus, payRequest } from '@/api/pay'
import {breakPointUpload} from "@/api/file";

export default {
  name: 'purchase',
  components: {
    TipsDialog,
    OrderDetailBuyer
  },
  data () {
    return {
      totalOrder: 0,
      status: null,
      page: 1,
      pageSize: 10,
      desc: 1,
      orderList: [],
      progress: 0,
      progressVisible: false,
      fileMap: new Map(),
      // downloadId: null
    }
  },
  mounted() {
    this.getNum()
    this.getOrders()
  },
  methods: {
    // TODO 获取订单数量
    getNum () {
      const promise = numberOfOrder(true, this.status)
      promise.then((res) => {
        if (res.code !== 200) return
        this.totalOrder = res.data
      })
    },

    getOrders () {
      const promise = listOfOrder(this.page, this.pageSize, this.desc, true, this.status);
      promise.then((res) => {
        if (res.code !== 200) return
        this.orderList = res.data
        for (let i = 0; i < res.data; i++) {
          this.orderList[i].modelInfo = JSON.parse(this.orderList[i].modelInfo)
        }
      })
    },

    sizeChange (e) {
      this.page = 1
      this.getOrders()
    },

    statusChange (e) {
      this.page = 1
      this.getNum()
      this.getOrders()
    },

    // TODO 删除订单
    confirmDelete (id) {
      const promise = deleteOrder(id, true);
      promise.then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
        setTimeout(() => location.reload(), 1500)
      })
    },

    // TODO 下载模型
    async tryDownload (id) {
      let downloadInfo = null
      const promise = ask4DownloadZip(id)
      await promise.then((res) => {
        if (res.code !== 200) return
        downloadInfo = res.data
      })

      this.progress = 0
      this.progressVisible = true
      // this.downloadId = id
      this.download(id, downloadInfo)
    },

    download (id, downloadInfo) {
      const that = this
      let size = downloadInfo.totalSize / 1024 / 1024
      that.$message.info(`准备下载, 文件大小: ${size.toFixed(2)}M`)


      return new Promise(async (resolve, reject) => {
        let filename = downloadInfo.filename
        let totalChunks = downloadInfo.totalChunks
        const chunkSize = downloadInfo.chunkSize
        // 遍历列表
        let fileList = that.fileMap.get(id)
        if (fileList === undefined) {
          fileList = []
          for (let i = 0; i < totalChunks; i++) {
            fileList.push(null)
          }
          that.fileMap.set(id, fileList)
        }


        // 成功的数量
        let success = 0
        for (let item of fileList) {
          if (item !== null) success++
        }
        if (success === totalChunks) {
          that.progress = 100
          that.mergeFile(fileList, filename)
          download(id, totalChunks, true)
          that.progressVisible = false
          return
        }


        // TODO 定义 - 并发和断点续传
        const downloadFileChunks = async (list) => {
          // 并发池
          const pool = []
          // 最大并发量
          const max = 2
          // 可接受最大连续失败数
          const failureMax = 50
          // 最大连续失败数
          let consecutiveFailure = 0
          // TODO 遍历文件列表
          for (let i = 0; i < list.length; i++) {
            // TODO 连续失败次数 >= failureMax, 停止请求
            if (consecutiveFailure >= failureMax) {
              that.progressVisible = false
              that.fileMap.set(id, fileList) // 更新map - 可以进行断点下载
              this.$message.error('文件下载失败，请稍后再试！')
              reject(new Error('文件下载失败，请稍后再试！'))
              return
            }
            const item = list[i]
            if (item !== null) continue

            // TODO 定义异步处理
            const promise = download(id, i + 1)
            promise.then((file) => {
              const poolIndex = pool.findIndex((item) => item === promise)
              pool.splice(poolIndex, 1)
              if (!file || file.code === 500 || (file.size !== chunkSize && i + 1 !== totalChunks)) {
                if (file.code === 500) that.$message.error('下载失败, 请重试')
                consecutiveFailure++
                return
              }
              consecutiveFailure = 0
              fileList[i] = file
              that.progress = (Number) (100 * ++success / totalChunks).toFixed(2)
              // TODO 如果成功数量 === 总分片数量 => 合并文件
              if (success === totalChunks) {
                that.mergeFile(fileList, filename)
                download(id, i + 1, true)
              }
            })
            .catch(() => {
              consecutiveFailure++
            })
            .finally(() => {
              if (!success === totalChunks && consecutiveFailure !== 0) {
                downloadFileChunks(fileList)
              }
            })

            // TODO 添加任务到并发池中
            pool.push(promise)
            // TODO 任务池满则阻塞
            if (pool.length === max) {
              // 每当并发池跑完一个任务，就再塞入一个任务，避免内存泄漏
              await Promise.race(pool)
            }
          }
        }

        // TODO 开始传文件
        downloadFileChunks(fileList)
      })
    },

    mergeFile (fileList, filename) {
      this.progressVisible = false
      const blob = new Blob(fileList, {
        type: "application/octet-stream",
      })
      // 获取heads中的filename文件名
      const downloadElement = document.createElement('a')
      // 创建下载的链接
      const href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      // 下载后文件名
      downloadElement.download = filename
      document.body.appendChild(downloadElement)
      // 点击下载
      downloadElement.click();
      // 下载完成移除元素
      document.body.removeChild(downloadElement)
      // 释放掉blob对象
      window.URL.revokeObjectURL(href)
    },

    async checkStatus (payId) {
      const promise = checkPayStatus(payId)

      let success = false
      await promise.then((res) => {
        success = res.code === 200
        this.$message.info(res.msg)
      })
      if (!success) {
        this.getOrders()
      }
      return success
    },

    // TODO 补充支付（可能当时没支付起）
    async gotoPay (payId) {
      let success = await this.checkStatus(payId)
      if (!success) {
        return
      }

      const promise = payRequest(payId)
      promise.then((res) => {
        if (res.code !== 200) return
        // 获取返回的数据
        const data = res.data
        // 创建一个div元素，并将返回的数据设置为div的innerHTML
        const div = document.createElement('div')
        div.innerHTML = data

        // 将div元素添加到页面的body中
        document.body.appendChild(div);

        // 设置表单的target属性为_blank，以在新窗口中打开提交的表单
        document.forms[0].setAttribute('target','_blank')

        // 提交表单
        document.forms[0].submit()
      })
    },

    openDetail(id, title) {
      let routeData = this.$router.resolve({
        path: `/detail?id=${id}&title=${title}`
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style scoped>

</style>
