import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Vue from 'vue'
import store from './store'
import axios from 'axios'
import VueCropper from 'vue-cropper'
import App from './App.vue'
import router from './router'

Vue.use(VueCropper)


// main.js
// 将 router 设置为全局属性
// Vue.prototype.$router = router
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

axios.defaults.withCredentials = true
Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
