<template>
  <div>
    <el-dialog :title="order.model.title" :visible.sync="dialogVisible" width="640px">

      <div>
        <h4>订单编号: {{order.id}}</h4>
        <div style="display: flex; justify-content: left; width: 100%; align-items: center">
          卖方:
          <el-avatar :size="36" style="margin-left: 10px; margin-right: 20px">
            <img v-if="order.sellerIcon" :src="order.sellerIcon"/>
            <div v-else> user </div>
          </el-avatar>
          {{ order.sellerUsername }}
        </div>
        <div style="display: flex; margin-top: 16px;">
          <img v-if="order.model" :src="order.model.cover" style="height: auto; width: 240px" @click="$router.push(`/detail?id=${order.modelId}`)">
          <el-avatar v-else :size="80">商品已下架</el-avatar>
          <div v-if="order.model" style="margin-left: 33px">
<!--            <div style="font-size: 16px; font-weight: bold">{{order.model.title}}</div>-->
            <div style="font-size: 16px">{{order.model.description}}</div>
            <div></div>
          </div>
        </div>
        <div style="margin-top: 16px">
          <div>成交价格: {{ order.price }}</div>
          <div>下单时间: {{ order.createTime}}</div>
          <div v-if="order.status === 1">支付时间: {{ order.payTime }}</div>

          <div v-if="order.status === 0">订单状态: 待付款</div>
          <div v-else-if="order.status === 1">订单状态: 待下载</div>
          <div v-else-if="order.status === 2">订单状态: 超时未支付</div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-popconfirm icon="el-icon-info" icon-color="red"
            confirm-button-text='确定' cancel-button-text='取消'
            title="删除后永远无法再查看(无法再下载该模型), 确定删除吗"
            @confirm="confirmDelete">
          <el-button slot="reference" type="danger" >删除订单</el-button>
        </el-popconfirm>
        <el-button @click="download" style="margin-left: 5px; margin-right: 5px" :disabled="order.status === 0 || order.status === 2">下载</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { refund } from '@/api/pay'

export default {
  name: 'OrderDetailBuyer',

  data () {
    return {
      dialogVisible: false,
      order: {
        model: {
          title: ''
        }
      },
      apply: {
        show: false,
        modelId: null,
        content: ''
      }
    }
  },
  methods: {
    open(item) {
      this.order = item
      this.apply.modelId = item.modelId
      this.dialogVisible = true
    },
    confirmDelete () {
      console.log('删除 - 订单id: ' + this.order.id)
    },
    download () {
      console.log('下载 - 订单id: ' + this.order.id)
    },
    commitApply () {
      console.log('退款 - 订单id: ' + this.order.id)
      console.log('发送请求到后端, 发起申请')
      var promise = refund(this.order.payId, this.order.id);
      promise.then((res) => {
        if (res.code !== 200) this.$message.error(res.msg)
        else this.$message.success(res.msg)
        console.log(res)
      })
      this.order.status = 3
    },
    cancelApply () {
      console.log('取消退款 - 订单id: ' + this.order.id)
      console.log('发送请求到后端, 取消申请')

      this.order.status = 1
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
