import request from '@/utils/request'

// TODO 上架/下架
export function numberOfMyModel(category, status) {
    return request({
        url: `/app/model?category=${category}&status=${status}`,
        method: 'get'
    })
}


// TODO id查询模型
export function queryById (id, update) {
    let url = update == null ? `/app/model/${id}` : `/app/model/${id}?update=${update}`
    return request({
        url: url,
        method: 'get'
    })
}

// TODO 发布模型
export function publishModel (uploadForm) {
    return request({
        url: '/app/model',
        method: 'post',
        data: uploadForm
    })
}

// TODO 更新模型
export function updateModel(updateForm) {
    return request({
        url: '/app/model',
        method: 'put',
        data: updateForm
    })
}

// TODO 根据 用户id查询/关键词查询
export function searchByUserId (keyword, page, pageSize, desc, orderBy, category, status, userId) {
    let url = `/app/model/${page}/${pageSize}/${desc}/${orderBy}/${category}?zwf=1`
    if (keyword !== null && keyword !== '' && keyword !== undefined) {
        url += `&keyword=${keyword}`
    }
    if (userId !== null && userId !== undefined) {
        url += `&userId=${userId}`
    }
    if (status !== null && status !== undefined) {
        url += `&status=${status}`
    }
    return request({
        url: url,
        method: 'get'
    })
}


// TODO 上架/下架
export function setStatus(id) {
    return request({
        url: `/app/model/${id}`,
        method: 'put'
    })
}

// TODO 删除作品
export function deleteModel(id) {
    return request({
        url: `/app/model/${id}`,
        method: 'delete'
    })
}

// TODO 获取轮播图
export function slide() {
    return request({
        url: '/app/public/slide',
        method: 'get'
    })
}
