<template>
  <div class="root-box">
    <div class="main-box">
      <span style="display: flex; justify-content: center; font-size: 20px; font-weight: bold">反馈表</span>
      <el-form ref="form" :model="feedback" label-width="80px"
               style="margin-top: 36px;  width: 600px; ">

        <el-form-item label="*反馈内容">
          <el-input type="textarea" placeholder="您的反馈" v-model.trim="feedback.content"></el-input>
        </el-form-item>
        <el-form-item label="*联系方式">
          <el-input v-model.trim="feedback.contact" placeholder="您的电话或邮箱"></el-input>
        </el-form-item>
        <el-form-item label="图片说明">
          <div style="display: flex; align-items: center">
            <el-avatar :size="80" shape="square">
              <i v-if="feedback.photo === '' || feedback.photo === null" class="el-icon-plus avatar-uploader-icon"></i>
              <el-image v-if="feedback.photo"
                        style="width: 100px; height: 100px"
                        :src="feedback.photo"
                        :preview-src-list="[feedback.photo]"/>
            </el-avatar>
            <label class="custom-file-upload">
              <span>选择图片</span>
              <input type="file" id="fileInput" accept="image/*" @change="upload" />
            </label>
          </div>
        </el-form-item>
      </el-form>
      <div style="display: flex; justify-content: space-around; align-items: center; margin-top: 20px; margin-bottom: 20px">
        <el-button type="primary" @click="submit">提交反馈</el-button>
      </div>
  </div>
  </div>
</template>

<script>
import { tempFile } from '@/api/file'
import { submitFeedback } from '@/api/feedback'

export default {
  name: 'ToFeedback',
  data() {
    return {
      feedback: {
        content: '',
        contact: '',
        photo: null
      },
      photo: []
    }
  },
  methods: {
    upload(e) {
      let file = e.target.files[0]
      const check = this.beforeAvatarUpload(file)
      if (!check) {
        document.getElementById('fileInput').value = ''
        return
      }

      // TODO 上传文件
      const formData = new FormData();
      formData.append('file', file)
      tempFile(formData).then((res) => {
        if (res.code !== 200) return
        this.feedback.photo = res.data
      })
    },

    // 上传图片之前检查类型
    beforeAvatarUpload (file) {
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!')
      }
      return isLt5M
    },

    submit() {
      if (this.feedback.content.length === 0) {
        this.$message.error('请填写反馈内容')
        return
      }
      if (this.feedback.contact.length === 0) {
        this.$message.error('请填写联系方式')
        return
      }

      submitFeedback(this.feedback).then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
        this.feedback.contact = ''
        this.feedback.content = ''
        this.feedback.photo = null
      })

    }
  }
}
</script>

<style scoped>


.main-box {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  border: 1px silver solid;
  border-radius: 8px;
  width: 600px;
  height: 480px;
  padding: 20px;
}

.root-box {
  /*border: 1px black solid;*/
  display: flex;
  justify-content: center;
  align-items: center;
}

#fileInput {
  display: none;
}
.custom-file-upload span {
  margin-right: 8px;
}
.custom-file-upload {
  color: #fff;
  display: inline-block;
  cursor: pointer;
  /*padding: 6px 10px;*/
  padding: 5px 5px 10px 10px;
  border: 1px solid #1E9FFF;
  border-radius: 6px;

  background: #1E9FFF;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  text-align: center;
  font-size: 12px;
  margin: 5px;
  height: 30px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

:deep(.avatar-uploader .el-upload) {
  /*border: 1px dashed #d9d9d9;*/
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
:deep(.avatar-uploader .el-upload:hover) {
  border-color: #409EFF;
}
</style>
