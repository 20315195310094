<template>
  <div style="display: grid; justify-content: center; align-items: center;">
    <el-form style="width: 300px;">
      <el-form-item label="旧密码">
        <el-input placeholder="Original Password" type="password" v-model.trim="updatePswForm.oldPassword" show-password></el-input>
      </el-form-item>

      <el-form-item label="新密码">
        <el-input placeholder="New Password" type="password" v-model.trim="updatePswForm.newPassword" show-password></el-input>
      </el-form-item>
    </el-form>
    <el-button :disabled="applyDisable" type="primary" @click="setPassword">应用变更 | Apply</el-button>
  </div>
</template>

<script>
import {setPassword} from "@/api/user";

export default {
  name: "SetPsw",
  data () {
    return {
      updatePswForm: {
        newPassword: '',
        oldPassword: ''
      },
    }
  },
  computed: {
    applyDisable() {
      return this.updatePswForm.newPassword === '' || this.updatePswForm.oldPassword === ''
    }
  },
  methods: {
    // TODO 修改密码
    setPassword () {
      // TODO 参数校验
      const newPassword = this.updatePswForm.newPassword;
      if (newPassword.length < 6) {
        this.$message.error("密码长度不能少于6位")
        return
      }
      // TODO 发送请求
      const promise = setPassword(this.updatePswForm.oldPassword, newPassword);
      promise.then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
        this.updatePswForm.newPassword = ''
        this.updatePswForm.oldPassword = ''
      })
    }
  }
}
</script>

<style scoped>

</style>
