import Vue from 'vue'
import VueRouter from 'vue-router'
import homePage from '@/views/home/header'
import mainPage from '@/views/home'
import loginPage from '@/views/login'
import searchPage from '@/views/home/search'
import selfPage from '@/views/self/leftGuide'
import itemDetail from '@/views/home/itemDetail'
import uploadPage from '@/views/self/upload'
import modelSet from '@/views/self/set'
import userInfo from '@/views/self/userInfo'
import dataAnalysis from '@/views/self/analysis'
import NotFound from '@/views/404'
import orderPage from '@/views/self/order'
import safetyPage from '@/views/self/safe'
import cartPage from '@/views/home/cart'
import updatePage from '@/views/self/set/updateModel'
import starPage from '@/views/self/star'
import StartContentPage from '@/views/self/star/StarContentPage'
import FeedBack from '@/views/self/feedback'
import MessagePage from '@/views/self/message'
import Visitor from '@/views/self-tourist'
Vue.use(VueRouter)

const routes = [
    {
      //  商城页面
      path: '/',
      name: 'home',
      component: homePage,
      children: [
        { path: '/main', name: 'main', component: mainPage },
        { path: '/search', name: 'search', component: searchPage },
        { path: '/detail', name: 'detail', component: itemDetail },
        { path: '/notFound', name: 'notFound', component: NotFound },
        { path: '/cart', name: 'cart', component: cartPage },
        { path: '/visitor/:userId', name: 'visitor', component: Visitor},
        //  个人页面
        {
          path: '/self',
          name: 'self',
          component: selfPage,
          redirect: '/userInfo',
          children: [
            { path: '/upload', name: 'upload', component: uploadPage },
            { path: '/modelSet', name: 'modelSet', component: modelSet },
            { path: '/userInfo', name: 'userInfo', component:  userInfo },
            { path: '/analysis', name: 'analysis', component:  dataAnalysis },
            { path: '/orders', name: 'orders', component: orderPage },
            { path: '/safety', name: 'safety', component: safetyPage },
            { path: '/update', name: 'update', component: updatePage },
            { path: '/star', name: 'star', component: starPage },
            { path: '/starContent', name: 'starContent', component: StartContentPage },
            { path: '/feedback', name: 'feedback', component: FeedBack },
            { path: '/message', name: 'message', component: MessagePage }
          ]
        },

      ],
      redirect: '/main'
    },
    { path: '/login', name: 'login', component: loginPage }
]

const router = new VueRouter({
  routes
})

export default router
