<template>
  <div>
    <el-dialog
        title="新建收藏夹"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose">

      <div style="display: grid; justify-content: center; align-items: center;">
        <!--  头像  -->
        <el-form ref="form" :model="favorListInfo" label-width="80px" style="width: 420px">

          <el-form-item label="封面" style="display: flex; align-items: center;">
            <el-avatar :size="80" shape="square">
              <img @click="openCropper" v-if="favorListInfo.cover" :src="favorListInfo.cover"/>
              <i @click="openCropper" v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-avatar>
            <ImageCropper :check="illegal" ref="myCropper" @handleCheck="beforeAvatarUpload" @handleChange="handleChange"></ImageCropper>
          </el-form-item>

          <el-form-item label="名称">
            <el-input v-model="favorListInfo.name" placeholder="收藏夹名称"></el-input>
          </el-form-item>
          <el-form-item label="简介">
            <el-input type="textarea" placeholder="收藏夹简介" v-model="favorListInfo.remark"></el-input>
          </el-form-item>
          <el-form-item>
            <el-radio-group v-model="favorListInfo.isPublic">
              <el-radio :label="true" :value="true">公开</el-radio>
              <el-radio :label="false" :value="false">私密</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: space-around; align-items: center">
        <el-button type="primary" @click="updateStarList">应用变更</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ImageCropper from '@/components/ImageCropper'
import { editList } from '@/api/favourite'

export default {
  name: 'NewStarDialog',
  components: {
    ImageCropper
  },
  data() {
    return {
      illegal: false,
      dialogVisible: false,
      favorListInfo: {}
    }
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    // TODO 上传图片之前检查类型
    beforeAvatarUpload (file) {
      const type = file.type;
      console.log(type)
      const isPhoto = type === 'image/jpeg' || type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isPhoto) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!')
      }
      this.illegal = isPhoto && isLt5M
    },
    // TODO 上传文件变更
    handleChange(param) {
      this.favorListInfo.cover = param
    },

    // TODO 打开裁剪弹窗
    openCropper() {
      this.$refs.myCropper.dialogVisible = true
    },
    // TODO 更新收藏夹
    updateStarList() {
      const promise = editList(this.favorListInfo)
      promise.then((res) => {
        if (res.code !== 200) return
        this.$message.success(res.msg)
        this.$emit('handleChange', this.favorListInfo)
      })
      this.dialogVisible = false
    },
    // TODO 打开本弹窗
    open(item) {
      this.favorListInfo = JSON.parse(JSON.stringify(item))
      this.$nextTick(() => {
        this.dialogVisible = true
      })
    }
  }
}
</script>

<style scoped>
:deep(.avatar-uploader .el-upload) {
  /*border: 1px dashed #d9d9d9;*/
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
:deep(.avatar-uploader .el-upload:hover) {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
</style>
