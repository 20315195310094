<template>
  <div v-title :data-title="title">
    <el-row>
      <VisitorHeader :user-info="userInfo"></VisitorHeader>
    </el-row>
    <el-divider content-position="right">ta的主页</el-divider>
    <el-tabs type="border-card" v-model="activeName">
      <el-tab-pane name="first">
        <span slot="label"><i class="el-icon-document-copy"></i> ta的作品</span>
        <MasterpieceSet  ref="masterpiece" :user-id="$route.params.userId"></MasterpieceSet>
      </el-tab-pane>
      <el-tab-pane name="second" @opened="">
        <span slot="label"><i class="el-icon-collection"></i> ta的收藏</span>
        <FavorList @handleChange="changeList" v-show="step === 1" :user-id="$route.params.userId"></FavorList>
        <ListContent :favor-list="favorList" v-show="step === 2" ref="content" @handleChange="step = 1"></ListContent>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import VisitorHeader from '@/views/self-tourist/VisitorHeader'
import { getUserInfo } from '@/api/visit'
import MasterpieceSet from '@/views/self-tourist/MasterpieceSet'
import FavorList from '@/views/self-tourist/FavorList'
import ListContent from '@/views/self-tourist/ListContent'

export default {
  name: 'Visitor',
  components: {
    VisitorHeader,
    MasterpieceSet,
    FavorList,
    ListContent
  },
  created() {
    this.title = this.$route.query.username + ' 的主页'
  },
  mounted() {
    this.userInfo.id = this.$route.params.userId
    this.getInfo()
  },
  data () {
    return {
      userInfo: { id: '' },
      step: 1,
      activeName: 'first',
      favorList: {
        name: '',
        cover: '',
        id: '',
        remark: '',
        createTime: ''
      },
      title: '标题'
    }
  },
  methods: {
    getInfo () {
      const promise = getUserInfo(this.userInfo.id)
      promise.then((res) => {
        if (res.code !== 200) return
        this.userInfo = res.data
      })
    },

    changeList (favorList) {
      this.favorList = favorList
      this.$nextTick(() => {
        this.step = 2
        const content = this.$refs.content
        if (content) {
          content.getFavourites()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
