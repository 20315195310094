<template>
  <!--            background-color="#3A3DE8"-->
<!--  class="el-menu-vertical-demo"-->
  <div v-title data-title="个人中心" style="height: 90vh; display: flex">
        <div style="width: 16%">
          <el-menu
              :default-active="activePath"
              @select="handleSelect"
              background-color="#3A3DE8"
              text-color="#fff"
              style="height: 100%"
              active-text-color="#ffd04b">
            <el-submenu  index="1">
              <template slot="title">
                <span><i class="el-icon-user"></i>我的</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/userInfo">
                  <i class="el-icon-info"></i>
                  个人信息
                </el-menu-item>
                <el-menu-item index="/safety">
                  <!--                <i class="el-icon-bell"></i>-->
                  <i class="el-icon-lock"></i>
                  账号与安全
                </el-menu-item>
                <el-menu-item index="0-0" @click.native="logout">
                  <i class="el-icon-switch-button"></i>
                  退出登录
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>

            <el-submenu index="2">
              <template slot="title">
              <span>
                <i class="el-icon-collection-tag"></i>
                <!--                <i class="el-icon-collection"></i>-->
                作品管理
              </span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/upload">
                  <!--                <i class="el-icon-upload"></i>-->
                  <i class="el-icon-upload2"></i>
                  上传作品
                </el-menu-item>
                <el-menu-item index="/modelSet">
                  <!--                <i class="el-icon-files"></i>-->
                  <!--                <i class="el-icon-folder-opened"></i>-->
                  <i class="el-icon-collection"></i>
                  作品集
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>

            <el-menu-item index="/analysis">
              <!--            <i class="el-icon-s-data"></i>-->
              <!--            <i class="el-icon-s-marketing"></i>-->
              <!--            <i class="el-icon-data-analysis"></i>-->
              <!--            <i class="el-icon-data-line"></i>-->
              <i class="el-icon-pie-chart"></i>
              作品数据统计
            </el-menu-item>

            <el-menu-item index="/star">

              <!--              <i class="el-icon-folder-opened"></i>-->
              <!--              <i class="el-icon-circle-plus-outline"></i>-->
              <i class="el-icon-star-off"></i>我的收藏

            </el-menu-item>

            <el-menu-item index="/orders">
              <!--            <i class="el-icon-tickets"></i>-->
              <!--            <i class="el-icon-document"></i>-->
              <i class="el-icon-s-order"></i>
              我的订单
            </el-menu-item>

            <el-menu-item index="/feedback">
              <!--            <i class="el-icon-tickets"></i>-->
              <i class="el-icon-s-opportunity"></i>
              意见反馈
            </el-menu-item>

            <el-menu-item index="/message">
              <!--            <i class="el-icon-chat-line-round"/>-->
              <i class="el-icon-chat-round"/>
              我的消息
            </el-menu-item>
          </el-menu>
        </div>



      <div style="width: 84%">
        <router-view></router-view>
      </div>
  </div>
</template>

<script>

import NewStarDialog from '@/views/self/star/NewStarDialog'
import { logout } from '@/api/login'
import { removeToken, removeUser } from '@/utils/storage'
export default {
  name: 'selfPage',
  components: {
    NewStarDialog
  },
  data () {
    return {
    }
  },

  methods: {
    handleSelect (key, keyPath) {
      if (key.startsWith('/')) {
        this.$router.push({
          path: key
        }).catch((err) => {
          console.log(typeof (err))
        })
      }
    },
    logout() {
      logout().then((res) => {
        if (res.code !== 200) return
        removeUser()
        removeToken()
        location.reload()
      })
    }
  },
  computed: {
    activePath() {
      return this.$route.path
    }
  }

}
</script>

<style scoped>
/*.el-menu-vertical-demo {*/
/*  height: 100%;*/
/*}*/

/*:deep(.el-submenu .el-menu-item){*/
/*  min-width: auto !important;*/
/*}*/
/*:deep (.el-menu) {*/
/*  border-bottom-right-radius: 10px;*/
/*  height: 100%;*/
/*}*/
</style>
