<template>
  <div style="display: flex; justify-content: flex-start; align-items: center">
    <div style="display: flex; justify-content: center; margin: 16px">
      <el-avatar :size="100">
        <el-image v-if="userInfo.icon" :src="userInfo.icon"></el-image>
        <div v-else> 头像 </div>
      </el-avatar>
      <div>
        <div style="font-size: 30px; margin-left: 33px">
          {{ userInfo.username }}
        </div>
        <div style="margin-left: 33px; display: flex; justify-content: flex-start;">
          <div style="font-size: 15px; margin-right: 10px; font-weight: bold">
            留言:
          </div>
          <div style="font-size: 15px; width: 200px">
            {{ userInfo.message }}
          </div>
        </div>

      </div>
      <div style="font-size:16px; margin-left: 33px">
        <div v-if="userInfo.gender === 1">性别: <i class="el-icon-male"></i>男</div>
        <div v-else-if="userInfo.gender === 0">性别: <i class="el-icon-female"></i>女</div>
        <div v-else>性别: <i class="el-icon-question"></i>保密</div>
        <div style="margin-top: 10px">生日: {{ userInfo.birthday }}</div>
        <div style="margin-top: 10px">注册日期: {{ userInfo.createTime }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VisitorHeader',
  props: {
    userInfo: {
      type: Object
    }
  },
  data () {
    return {

    }
  }
}
</script>

<style scoped>

</style>
